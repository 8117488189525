import React, { Component } from "react"
import style from "./style.css"

const activeStyle = {
  borderBottom: "solid 2px #2f3842",
  color: "#2f3842",
  fontWeight: "600",
}

export default class MultiElementContainer extends Component {
  state = {
    activeElementIndex: 0,
  }

  switchElement ( index ) {
    this.setState( { activeElementIndex: index } )
  }

  renderActiveElement ( elements ) {
    const { activeElementIndex } = this.state
    const activeElement = elements[ activeElementIndex ]

    const { render } = activeElement
    if ( typeof render !== "function" ) {
      console.error( "Expecting render function!" )
    }

    return (
      <div className={style.activeElement}>
        {render()}
      </div>
    )

  }

  renderHeaders ( elements ) {
    return (
      <div className={style.headers}>
        {elements.map( this.renderHeader.bind( this ) )}
      </div>
    )
  }

  renderHeader ( element, key ) {
    const isActive = key === this.state.activeElementIndex

    return (
      <div
        key={key}
        className={style.elementHeader}
        style={isActive ? activeStyle : {}}
        onClick={() => this.switchElement( key )}
      >
        {element.label}
      </div>
    )
  }

  render () {
    const { elements = [] } = this.props

    return (
      <div className={style.main}>
        {this.renderHeaders( elements )}
        {this.renderActiveElement( elements )}
      </div>
    )
  }
}
