import { defaultSearchByFieldType } from "./search"

export const filterSearchFields = data => data.fields.filter( field => field.isSearchable )


const complementFieldsIfNecessary = field => {
  const { type } = field
  if ( !type ) throw "Field type required!"

  let { search } = field
  if ( !search ) {
    search = defaultSearchByFieldType[ type ]
  }


  let { render } = field
  if ( !render ) {
    render = ( rawValue, field, row ) => rawValue
  }


  return {
    ...field,
    search,
    render,
  }
}

export const buildDataForTable = ( fields, rows, itemsPerPage = 10, pagination = true ) => {
  const completeFields = fields.map( complementFieldsIfNecessary )

  return {
    fields: completeFields,
    rows,
    itemsPerPage,
    pagination,
  }
}
