import _ from "lodash"

export const diffBetween = ( original, updated ) => {
  let diffObject = {};

  [ ...Object.keys( original ), ...Object.keys( updated ) ]
    .map( field => {
      const originalValue = original[ field ]
      const updatedValue = updated[ field ]

      if ( updatedValue !== originalValue || !original ) {
        diffObject[ field ] = updatedValue
      }
    } )

  return diffObject
}

const errorCodeMap = {
  "macaddr_in": "mac_address",
  "network_in": "ip_address",
}

const selectionData = (data, addNotification) => {
  let fieldsToOmit = ['confirmPassword']

  if(data.confirmPassword || data.password){
    if(data.confirmPassword !== data.password){
      addNotification("error", "Password didn't match")
      return null
    }
  }

  if(data.password === "") fieldsToOmit.push('password')

  const dataToSend = _.omit(data, fieldsToOmit)

  return dataToSend
}

export const buildErrorHandle = (props, schema = []) => error => {
  const { addNotification } = props
  const { graphQLErrors } = error

  console.log('graphQLErrors', graphQLErrors)
  
  let fieldError = null
  const fields = nestedAggregator( schema )
  
  if ( graphQLErrors ) {
    const errors = graphQLErrors.map( ({ routine, dataType, message }) => {
      if ( dataType ) return dataType
      
      if(routine === "_bt_check_unique"){
        const errorPath = message.substring(message.lastIndexOf(" ")+1);
        const formattedErrorPath = errorPath.replace(/_/g , ' ');

        const fieldErrorObj = fields.find(field => {
          if(field.label)
            return formattedErrorPath.toLowerCase().includes(field.label.toLowerCase())
        })

        fieldError = fieldErrorObj ? fieldErrorObj.label : formattedErrorPath
      }
      
      return errorCodeMap[ routine ] || false
    } )
    
    if(fieldError) {
      addNotification( "error", `The ${fieldError} value already exists, please choose another one.`)
      return []
    }

    const gotUnexpectedOccurrence = errors.some( error => error === false || error === undefined )
    if( gotUnexpectedOccurrence )  addNotification( "error", "An unexpected error occurred" )

    const filteredErrors = errors.filter( e => e )
    filteredErrors.forEach( dataType => addNotification( "error", `Invalid ${ dataType.split( "_" ).join( " " ) }`) )

    return filteredErrors
  }

  return []
}

export const buildActions = ( isCreating, clickFunction ) => save => {
  const styles = {
    save: { color: "white", backgroundColor: "#2f3842" },
    cancel: { color: "#2f3842" },
  }

  const label = isCreating ? "Add" : "Save"

  return [
    { name: label, label, style: styles.save, onClick: save, withData: true },
    { name: "Cancel", label: "Cancel", style: styles.cancel, onClick: clickFunction },
  ]
}

export const update = ( props, notification, schema, isImutable, callback ) => mutationFunction => data => {
  const { addNotification } = props
  const { row } = props.location.state
  let variables = {}

  const dataToSend = selectionData(data, props.addNotification)

  if(!dataToSend) return
  
  const diff = diffBetween( row, dataToSend )

  const missingFields = missingRequired(schema, dataToSend)

  if( missingFields.length ) {
    missingFields.forEach(field => {
      addNotification( "error", `Please fill "${field.label}" field` )
    })
    return
  }

  if(isImutable){
    variables = {
      updateObject: {id: dataToSend.id, ...diff},
    }
  } else {
    variables = {
      id: dataToSend.id,
      updateObject: diff,
    }
  }

  const notify = () => addNotification( "info", notification )
  const defaultCallback = () => {
    notify()
    props.history.goBack(-1)
  }

  // TODO optionally receive callback
  mutationFunction( { variables } )
    .then( () => callback ? callback( { dataToSend, row, diff }, notify ) : defaultCallback() )
}

export const create = ( props, notification ) => mutationFunction => ( data, schema ) => {
  const { addNotification } = props
  const dataToSend = selectionData(data, addNotification)

  if(!dataToSend) return

  const variables = {
    createObject: dataToSend,
  }

  const missingFields = missingRequired(schema, dataToSend)

  if( missingFields.length ) {
    missingFields.forEach(field => {
      addNotification( "error", `Please fill "${field.label}" field` )
    })
    return
  }

  // TODO optionally receive callback
  mutationFunction( { variables } ).then( () => {
    addNotification( "info", notification )
    props.history.goBack(-1)
  } )
}

const missingRequired = ( schema, data ) => {
  if( !elementHasChildren( schema ) ) {
    return false
  }

  const requiredFields = nestedAggregator( schema, true )

  return requiredFields.filter( fieldName => !_.get( data, fieldName.name ))
}

const nestedAggregator = ( { children }, requiredFields = false ) => _.reduce( children, ( agg, element ) => {
  const hasChildren = elementHasChildren( element )
  const hasSchemaChildren = elementHasSchemaChildren( element )

  let requiredChildren = []
  let requiredSchemaChildren = []

  if( hasChildren ) {
    requiredChildren = nestedAggregator( element, requiredFields )
  }

  if( hasSchemaChildren ) {
    requiredSchemaChildren = nestedAggregator( element.props.schema, requiredFields )
  }

  const isRequired = elementIsRequired( element )
  const label = element.props.schema.label
  const name = element.props.schema.name

  return requiredFields ? 
    isRequired ? [ ...requiredChildren, ...requiredSchemaChildren, ...agg, {label, name} ] : [ ...requiredChildren, ...requiredSchemaChildren, ...agg ]
    : [ ...requiredChildren, ...requiredSchemaChildren, ...agg, {label, name} ]
}, [] )

const elementHasSchemaChildren = element => _.get( element, "props.schema.children" )
const elementHasChildren = element => _.get( element, "children" )
const elementIsRequired = element => _.get( element, "props.required" )