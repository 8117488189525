import React, { Component } from "react"
import moment from 'moment'
import { wrapInQuery } from "views/EasyView"
import { GET_USERS, GET_USERS_LOGINS } from "graphql/queries"
import MoreContainer, { createNewItem, showLogs } from "components/Header/MoreContainer"

// import { EDIT_USER } from "graphql/mutations"
// import { ActiveSwitch } from "components/Header"

const buildHeader = ( operations = [] ) => ( {
  title: "Users",
  operations,
} )

// Might be useful
// const isActiveRender = ( _, value ) => {
//   const { id, isActive } = value

//   return <ActiveSwitch mutation={EDIT_USER} value={{ id, isActive }} />
// }

const fetchPolicy = "cache-and-network"

const editRoute = "/users/" // TODO

export default class Users extends Component {

  constructor ( props, instance ) {
    super( props )

    this.state = { 
      showLogins: false,
    }
  }

  getViewProperties = () => {
    let query, nodesKey, fields, label;

    if(this.state.showLogins){
      label = 'Check users',
      query = GET_USERS_LOGINS,
      nodesKey = 'allUserLogs',
      fields = [
        { name: "toId", displayName: "First Name", isSearchable: true, type: "string", transform: item => item.firstName},
        { name: "toId", displayName: "Last Name", isSearchable: true, type: "string", transform: item => item.lastName },
        { name: "logTime", displayName: "Log Time", isSearchable: true, type: "string", transform: item => moment(item).format('YYYY-MM-DD h:mm:ss a') },
        { name: "logTime", displayName: "Description", isSearchable: true, type: "string", transform: _ => "Login" },
      ]
    } else {
      label = "Check users logins"
      query = GET_USERS,
      nodesKey = 'allUsers',
      fields = [
        { name: "firstName", displayName: "First Name", isSearchable: true, type: "string" },
        { name: "lastName", displayName: "Last Name", isSearchable: true, type: "string" },
        { name: "username", displayName: "Username", isSearchable: true, type: "string" },
        { name: "organizationRole", displayName: "Role", isSearchable: true, type: "string" },
      ]
    }

    return {query, nodesKey, fields, label}
  }

  render () {
    const {query, nodesKey, fields, label} = this.getViewProperties()

    const headerOperations = [
      //createNewItem( this.props.location.pathname, {} ),
      createNewItem( window.location.pathname, {} ),
      <MoreContainer items={[ showLogs({label: label, onClick: () => this.setState(state => ({showLogins: !state.showLogins}))}) ]} />,
    ]

    const header = buildHeader( headerOperations )
    const viewProps = {
      header,
      editRoute,
    }

    return wrapInQuery( {
      query,
      fetchPolicy,
      nodesKey,
      fields,
      viewProps,
    } )
  }
}
