import React from "react"

import { Component } from "react"
import { withStore } from "store"
import { withRouter } from "utils/router"
import { wrapInMutation } from "views/EditView"
import buildSchema from "./schema"
import { create, buildErrorHandle, buildActions } from "utils/submit"
import { CREATE_DEVICE } from "graphql/mutations"

const header = {
  title: "Create Structure",
}

const fetchPolicy = "cache-and-network"

class CreateStructure extends Component {
  render () {
    //const { goBack } = this.props.history
    const { history } = this.props.router

    const viewProps = {
      header,
      schema: buildSchema( this.props.store, {}, true ),
      value: {},
    }

    return wrapInMutation( {
      mutation: CREATE_DEVICE,
      viewProps,
      buildSubmit: create( this.props, "Structure created!" ).bind( this ),
      handleError: buildErrorHandle( this.props, viewProps.schema ).bind( this ),
      buildActions: buildActions( true, history.goBack ),
    } )
  }
}

export default withRouter( withStore ( CreateStructure ) )
