import React, { Component } from "react"
import style from "./style.css"
import _ from "lodash"
import Graph from "../Graph"
import Svg from "components/Svg"
import Spacer from "components/Spacer"
import Carousel from "components/Carousel"
import { GraphButton, ListButton, Checkbox } from "components/ToggleButtons"
import { buildDataForTable } from "components/DataTable/DataBuilder"
import Popup from "components/Popup"
import Img from "components/Img"
import ImageDropzone from "components/DynamicForm/Fields/ImageDropzone"
import placeholder from "images/placeholders/device.png"
import DataTable from "components/DataTable"
import Spinner from "components/Spinner"
import pictureImage from "images/buttons/picture.svg"
import closeImage from "images/buttons/close.svg"
import moment from "moment"
import { withStore } from "../../../store"
import { withLoader } from "components/Spinner"
import sharedStyle from "shared/style.css"
import infoStyle from "../DeviceInfo/style.css"

const ViewTypeEnum = Object.freeze( {
  "Graph": "Graph",
  "Table": "Table",
} )

class DeviceTableView extends Component {
  render () {
    const styles = {
      row: style.row,
      rowContainer: style.rowContainer,
      columnRowsContainer: style.columnRowsContainer
    }

    return (
      <DataTable
        styles={styles}
        value={this.props.data}
      />
    )
  }
}



class SensorGraphView extends Component {
  constructor ( props ) {
    super( props )

    this.state = {
      merge: true,
    }
  }

  renderGraphs = ( merge, channels, data ) => {
    if ( _.isEmpty( data ) ) {
      return (
        <div className={style.noDataFound}>No Data Available</div>
      )
    }

    if ( merge ) {
      return (
        <Graph
          data={data}
          channels={channels}
        />
      )
    } else {
      return channels.map( channel => {
        const channelData = data.filter( m => m.channelId === channel.id )
        return (
          <Graph
            key={channel.id}
            data={channelData}
            channels={[ channel ]}
          />
        )
      } )
    }
  }

  renderDescription = ( title, value ) => {
    if ( !value || value === "" ) {
      return ""
    }

    return (
      <div>
        <b>{ title }:</b> { value }
      </div>
    )
  }

  renderMergeChannels = () => (
    <div
      onClick={() => this.setState( {
        merge: !this.state.merge,
      } )}
      className={style.mergeChannels}
    >
      <Checkbox disabled={!this.state.merge} style={{ marginRight: "5px" }} />
      <div style={{ color: this.state.merge ? "#231f20" : "#a9a9a9" }} >
        Merge Channels
      </div>
    </div>
  )

  render () {
    const { merge } = this.state
    const { data, sensor, channelIds } = this.props
    const channels = _.get( sensor, "channelsBySensorId.nodes", [] )
      .filter( channel => _.includes( channelIds, channel.id ) )

    return (
      <div className={style.sgvRoot}>
        <div className={style.sgvHeader}>
          <div className={style.sgvTitle}>{ sensor.name }</div>
          <div>
            { this.renderDescription( "Sensor Description", sensor.description ) }
            { this.renderDescription( "Location Description", sensor.locationDescription ) }
          </div>
          { channels.length > 1 ? this.renderMergeChannels() : undefined }
        </div>
        <Spacer size={6}/>
        {this.renderGraphs( merge, channels, data )}
        <Spacer size={6}/>
      </div>
    )
  }
}

class DeviceDataView extends Component {
  constructor ( props ) {
    super( props )

    const tableData = this.buildTableViewData( props.data, props.device )

    const defaultSelectedSensorId = _.get( props.device, "sensorsByDeviceId.nodes[0].id" )
    const selectedSensorIds = defaultSelectedSensorId
      ? [ defaultSelectedSensorId ]
      : []

    this.state = {
      infoPopup: false,
      selectedSensorIds,
      tableData,
      viewType: props.viewType || ViewTypeEnum.Graph,
    }
    this.spinnerRef = React.createRef()
    this.dataViewRef = React.createRef()
  }

  viewType () {
    return this.state.viewType || ViewTypeEnum.Graph
  }

  buildTableViewData ( data, device ) {
    const transformAlertType = alertId => {
      alertId = _.parseInt( alertId )

      const alertTypes = _.get( this.props.store, "alertTypes" )
      if ( !alertTypes ) return alertId

      const type = _.find( alertTypes, alertType => alertType.id === alertId )

      return _.get( type, "name", "Unknown" )
    }

    const fields = [
      {
        name: "deviceTimestamp",
        displayName: "Date/Time",
        type: "string",
        transform: t => moment( t ).format( "MM/DD/YYYY h:mm a" ),
      },
      {
        name: "alertTypeName",
        displayName: "Alert Type",
        isSearchable: true,
        type: "string",
        transform: transformAlertType.bind( this ),
      },
      { name: "sensorName", displayName: "Sensor", isSearchable: true, type: "string" },
      { name: "channelType", displayName: "Channel", isSearchable: true, type: "string" },
      { name: "value", displayName: "Value", type: "string" },
      { name: "unitName", displayName: "Unit", type: "string" },
    ]

    const measurements = _.get( data, "allMeasurements.nodes", [] )

    const tableData = measurements.map( measurement => {
      const sensor = _.chain( device )
        .get( "sensorsByDeviceId.nodes", [] )
        .find( sensor => _.get( sensor, "channelsBySensorId.nodes", [] )
          .find( channel => channel.id === measurement.channelId ) )
        .value()

      const channel = _.get( sensor, "channelsBySensorId.nodes", [] )
        .find( channel => channel.id === measurement.channelId )

      return {
        deviceTimestamp: measurement.deviceTimestamp,
        alertTypeName: measurement.alertTypeId, // TODO: Lookup unit name
        sensorName: sensor.name,
        channelType: channel.channelTypeByChannelTypeId.name,
        value: measurement.value,
        unitName: channel.unitByUnitId.name,
      }
    } )

    const dataForTable = buildDataForTable( fields, tableData, 50 )
    this.props.setDownloadData( dataForTable )

    return dataForTable
  }

  shouldComponentUpdate ( nextProps, nextState ) {
    return !_.isEqual( this.state.selectedSensorIds, nextState.selectedSensorIds ) ||
      !_.isEqual( this.state.viewType, nextState.viewType ) ||
      !_.isEqual( this.state.infoPopup, nextState.infoPopup )
  }

  onViewTypeChange = newViewType => {
    this.spinnerRef.current.style.display = "flex"
    this.dataViewRef.current.style.display = "none"

    setTimeout( () => {
      this.setState( {
        viewType: newViewType,
      }, () => this.props.setViewType( newViewType ) )
    }, 10 )
  }


  toggleSensor ( sensorId ) {
    const { selectedSensorIds } = this.state

    const position = selectedSensorIds.indexOf( sensorId )
    if ( position > -1 ) {
      let filtered = selectedSensorIds.slice()
      filtered.splice( position, 1 )

      this.setState( {
        selectedSensorIds: filtered,
      } )
    }
    else {
      this.setState( {
        selectedSensorIds: [
          ...selectedSensorIds,
          sensorId,
        ],
      } )
    }
  }

  openPopup = () => this.setState( { infoPopup: true } )
  closePopup = () => this.setState( { infoPopup: false } )

  renderPopup () {
    const { device = {} } = this.props
    const { image, description } = device

    const displayName = _.get( device, "deviceName", "—" )

    const popupStyle = { maxHeight: "75vh", padding: "20px" }
    const containerStyle = { padding: "0" }
    const textContainerStyle = { flexDirection: "column" }

    return (
      <Popup shouldRender={this.state.infoPopup} onOutsideClick={this.closePopup}>
        <div className={style.popup} style={popupStyle}>
          <div className={infoStyle.container} style={containerStyle}>
            <div className={infoStyle.textContainer} style={textContainerStyle}>
              <div className={infoStyle.title}>{ displayName }</div>
              <div className={infoStyle.description}>{ description }</div>
            </div>
            <div className={style.popupImageContainer}>
              {
                image
                  ? <Img className={style.popupImage} src={image} placeholder={placeholder}/>
                  : <ImageDropzone readOnly value="" multiple={false} />
              }
            </div>
          </div>
        <Svg onClick={this.closePopup} className={style.close} html={closeImage} />
        </div>
    </Popup>
    )
  }

  renderHeader () {
    const { device } = this.props

    const displayName = _.get( device, "deviceName", "—" )
    const sensors = _.get( this.props.device, "sensorsByDeviceId.nodes", [] )

    const headerBottomRow = (
      <div className={style.headerBottomRow}>
        <Carousel
          toggle={this.toggleSensor.bind( this )}
          value={sensors.map( sensor => ( {
            value: sensor.id,
            label: sensor.name,
            selected: this.state.selectedSensorIds.indexOf( sensor.id ) > -1,
          } ) )}
        />
      </div>
    )

    return (
      <div className={style.header}>
        <div className={style.headerTitleRow}>
          <div className={style.headerStructureName}>
            { displayName }
            <Spacer size={2} />
            <Svg onClick={this.openPopup} className={style.pictureButton} html={pictureImage} />
            { this.renderPopup() }
          </div>
          <div className={style.headerViewSelection}>
            <GraphButton
              readOnly={true}
              onClick={() => this.viewType() !== ViewTypeEnum.Graph && this.onViewTypeChange( ViewTypeEnum.Graph )}
              disabled={this.viewType() !== ViewTypeEnum.Graph}
            />
            <Spacer size={1} />
            <ListButton
              onClick={() => this.viewType() !== ViewTypeEnum.Table && this.onViewTypeChange( ViewTypeEnum.Table )}
              disabled={this.viewType() !== ViewTypeEnum.Table}
            />
          </div>
        </div>
        <Spacer size={2} />
        { this.viewType() !== ViewTypeEnum.Table && headerBottomRow /* conditionally render header bottom row*/ }
      </div>
    )
  }

  renderTable () {
    // Table View
    return (
      <DeviceTableView
        data={this.state.tableData}
      />
    )
  }

  renderGraph () {
    const { data } = this.props
    const { selectedSensorIds } = this.state

    const sensors = _.get( this.props.device, "sensorsByDeviceId.nodes", [] )
    const filteredSensors = selectedSensorIds.length > 0 ? sensors.filter(
      sensor => selectedSensorIds.indexOf( sensor.id ) > -1
    ) : sensors

    const measurementNodes = _.get( data, "allMeasurements.nodes", [] )

    return (
      <div className={[ style.column, style.sgvContainer ].join( " " )}>
        {filteredSensors.map( sensor => {
          const channels = _.get( sensor, "channelsBySensorId.nodes", [] )

          const channelIds = channels.map( channel => channel.id )
          const measurements = measurementNodes
            .filter( meas => _.includes( channelIds, meas.channelId ) )

          return (
            <SensorGraphView
              channelIds={this.props.channelIds}
              key={sensor.id}
              sensor={sensor}
              data={measurements.reverse()}
            />
          )
        } )}
      </div>
    )
  }

  renderData () {
    return (
      <div
        className={style.column}
        ref={this.dataViewRef}
      >
        {this.viewType() === ViewTypeEnum.Table ?
          this.renderTable() :
          this.renderGraph()}
      </div>
    )
  }

  renderSpinner () {
    const nextViewType = this.viewType() === ViewTypeEnum.Table ?
      ViewTypeEnum.Graph :
      ViewTypeEnum.Table

    return (
      <div
        className={style.spinnerWrapper}
        ref={this.spinnerRef}
      >
        <Spinner loading info={`Preparing ${ nextViewType.toLowerCase() }...`} />
      </div>
    )
  }

  componentDidUpdate () {
    if ( this.spinnerRef.current !== null ) {
      this.spinnerRef.current.style.display = "none"
    }

    if ( this.dataViewRef.current !== null ) {
      this.dataViewRef.current.style.display = "flex"
    }
  }

  render () {
    return (
      <div className={style.root}>
        {this.renderHeader()}
        {this.renderSpinner()}
        {this.renderData()}
      </div>
    )
  }
}

export default withStore( withLoader( DeviceDataView ) )
