import React, { Component } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Mutation } from "react-apollo"
import { LOGIN } from "graphql/mutations"
import Svg from "components/Svg"
import style from "./style.css"
import logo from "images/login/logo.png"
import backgroundImage from "images/login/background.png"
import usernameIcon from "images/login/user.svg"
import passwordIcon from "images/login/lock.svg"

const usernamePlaceholder = "Email Address / Username"
const passwordPlaceholder = "Password"

const HookedLogin = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();

  return <Login {...props} router={{location, navigate, params}}/>
}


class Login extends Component {
    state = {
      error: false,
      username: "",
      password: "",
    }
   
    afterSubmit ( response ) {
      const { login, router } = this.props

      const data = response.data.login
      const { jwtToken, clientMutationId } = data

      if ( jwtToken ) {
        localStorage.setItem( "token", jwtToken )
        login( router.navigate, clientMutationId )
      }
      else {
        const error = "Invalid username/password combination!"
        this.setState( { error } )
      }

    }

    renderError ( error ) {
      return (
        <div className={style.error}>
          { error }
        </div>
      )
    }

    handleKeyPress = ( event ) => {
      if ( event.key === "Enter" ) {
        this.state.submitButton && this.state.submitButton.click()
      }
    }

    renderSubmitButton () {
      const { username, password } = this.state
      const validInputs = username.length > 0 && password.length > 0

      const backgroundStyle = validInputs ? { backgroundColor: "#7dcb27" } : {}

      return (
        <div className={style.submitWrapper}>
          <div className={style.submitContainer} style={backgroundStyle}>
            <input
              type="submit"
              value="Login"
              className={style.submitButton}
              ref={ submitButton => !this.state.submitButton && this.setState( { submitButton } )}
            />
          </div>
        </div>
      )
    }

    renderInputs () {
      const { error } = this.state

      return (
        <div className={style.inputBox}>
          <div className={style.inputContainer}>
            <Svg className={style.icon} html={usernameIcon} />
            <input
              className={style.input}
              placeholder={usernamePlaceholder}
              name={"username"}
              type={"text"}
              onKeyPress={this.handleKeyPress.bind( this )}
              onChange={ e => this.setState( { username: e.target.value } ) }
            />
          </div>
          <div className={style.inputContainer}>
            <Svg className={style.icon} html={passwordIcon}/>
            <input
              className={style.input}
              placeholder={passwordPlaceholder}
              name={"password"}
              type={"password"}
              onKeyPress={this.handleKeyPress.bind( this )}
              onChange={ e => this.setState( { password: e.target.value } ) }
            />
          </div>
          { error ? this.renderError( error ) : "" }
          { this.renderSubmitButton() }
        </div>
      )
    }

    renderLogo () {
      return (
        <div className={style.logoContainer}>
          <img className={style.logo} src={logo} />
        </div>
      )
    }

    submit ( login ) {
      return function ( e ) {
        const { username, password } = this.state
        e.preventDefault()

        login( { variables: { identifier: username, password } } )
          .then( response => this.afterSubmit( response ) )
      }
    }

    render () {
      return (
        <Mutation mutation={LOGIN}>
          {( login ) => {
            const onSubmit = this.submit( login )

            return (
              <div className={style.main}>
                <div className={style.background}>
                  <img src={backgroundImage} />
                </div>
                <div className={style.column}>
                  { this.renderLogo() }
                  <div className={style.formContainer}>
                    <form className={style.form} onSubmit={onSubmit.bind( this )}>
                      { this.renderInputs() }
                    </form>
                  </div>
                  <div className={style.passwordReset}>
                    Forgot your password?
                    <Link
                      to="/recoverPassword"
                      className={style.recoverPassword}
                    >Recover your password</Link>
                  </div>
                </div>
              </div>
            )
          }}
        </Mutation>
      )
    }
}

export default HookedLogin
