import React, { Component } from "react"
import { Mutation } from "react-apollo"
import _ from "lodash"
import Header from "components/Header"
import DynamicForm from "components/DynamicForm"
import MultiElementContainer from "components/MultiElementContainer"
import Spinner, { loadingStyle } from "components/Spinner"
import style from "./style.css"

export const DataContext = React.createContext( { value: {} } )

class EditView extends Component {
  constructor ( props ) {
    super( props )

    this.state = { value: props.value || {} }
  }

  renderAction ( action, index ) {
    const { label, onClick, withData } = action
    const actionStyle = action.style

    const { value } = this.state

    let clickAction
    if (label === "Cancel") {
      clickAction = () => onClick(-1)
    } else {
    clickAction = () => onClick( withData ? value : undefined, this.props.schema )
    }

    return (
      <div key={index} className={style.actionContainer} style={actionStyle} onClick={clickAction}>
        <div className={style.action}>{ label }</div>
      </div>
    )
  }

  renderActions ( actions ) {
    return actions.map( this.renderAction.bind( this ) )
  }

  contextOnChange ( newValue ) {
    const { value } = this.state

    this.setState( {
      value: {
        ...value,
        ...newValue,
      },
    } )
  }

  render () {
    const { header, schema, actions, lists = [], errors = [], loading = true } = this.props
    const { value } = this.state

    const Form = new DynamicForm( {
      schema,
      errors,
      value,
      onChange: this.contextOnChange.bind( this ),
    } )

    return (
      <div>
        <Spinner loading={loading} />
        <div className={style.main} style={loading ? loadingStyle : {}}>
          <div className={style.headerContainer}>
            { header &&
              <Header
                { ...header }
                value={value}
                onChange={this.contextOnChange.bind( this )}
              />
            }
          </div>
          <div className={style.tableContainer}>
            { Form }
          </div>
          <div className={style.bottomContainer}>
            <div className={style.listsContainer}>
              { !_.isEmpty( lists ) ? <MultiElementContainer elements={lists} /> : "" }
            </div>
            <div className={style.actionsContainer}>
              { this.renderActions( actions ) }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const wrapInMutation = ( { mutation, fetchPolicy, buildSubmit, handleError, buildActions, viewProps } ) => (
  <Mutation mutation={mutation} fetchPolicy={fetchPolicy}>
    {( update, { loading, error } ) => {
      let viewErrors = []

      const submit = buildSubmit( update )
      if ( error ) {
        viewErrors = handleError( error )
      }

      const viewActions = buildActions( submit )
      return ( <EditView { ...viewProps } errors={viewErrors} actions={viewActions} loading={loading} /> )
    }}
  </Mutation>
)

export default EditView
