import _ from "lodash"

import { Route } from "react-router-dom"
import React from "react"
import Organizations from "views/Organizations"
import OrganizationEdit from "./views/Organizations/edit"
import OrganizationCreate from "./views/Organizations/create"
import Login from "views/Login"
import RecoverPassword from "views/RecoverPassword"
import SetupPassword from "views/SetupPassword"
import Locations from "views/Locations"
import LocationCreate from "views/Locations/create"
import LocationEdit from "views/Locations/edit"
import Users from "views/Users"
import UserEdit from "views/Users/edit"
import UserAssignAlerts from "views/Users/alerts"
import UserCreate from "views/Users/create"
import DataView from "views/DataView"
import Structures from "views/Structures"
import StructureCreate from "views/Structures/create"
import StructureRead from "views/Structures/read"
import DataPoints from "views/Structures/sensors/dataPoints"
import StructureEdit from "views/Structures/edit"
import StructureSensors from "views/Structures/sensors/index"
// import StructureSensorCreate from "views/Structures/sensors/create"
import StructureSensorEdit from "views/Structures/sensors/edit"
import organizationsIcon from "images/sidebar/organizations.svg"
import dataViewIcon from "images/sidebar/dataview.svg"
import structuresIcon from "images/sidebar/structures.svg"
import usersIcon from "images/sidebar/users.svg"
import locationsIcon from "images/sidebar/locations.svg"

export const routeIconMap = {
  organizations: organizationsIcon,
  dataView: dataViewIcon,
  structures: structuresIcon,
  users: usersIcon,
  locations: locationsIcon,
}

const route2 = (path, Component, name, exact = true) => { return ( <Route
  path={path}
  element={<Component/>}
  exact={exact}
  name={name}
  />)
}
const route = ( path, element, name, exact = true ) => ( {
  path,
  element,
  exact,
  name,
} )

export const sidebarRoutes = [
  route( "/organizations", Organizations, "organizations" ),
  route( "/locations", Locations, "locations" ),
  route( "/users", Users, "users" ),
  route( "/structures", Structures, "structures" ),
  route( "/data", DataView, "dataView" ),
]

export const appRoutes = [
  ...sidebarRoutes,
  route( "/login", Login, "login" ),
  route( "/recoverPassword", RecoverPassword, "recoverPassword" ),
  route( "/setupPassword/:token", SetupPassword, "setupPassword" ),
  route( "/organizations/new", OrganizationCreate, "createOrganization" ),
  route( "/organizations/:id", OrganizationEdit, "editOrganization" ),
  route( "/users/new", UserCreate, "createUser" ),
  route( "/users/:id", UserEdit, "editUser" ),
  route( "/users/:id/alerts", UserAssignAlerts, "userAssignAlerts" ),
  route( "/structures/new", StructureCreate, "createStructure" ),
  route( "/structures/:id", StructureRead, "readStructure" ),
  route( "/structures/:id/edit", StructureEdit, "editStructure" ),
  route( "/structures/:id/sensors", StructureSensors, "readStructureSensors" ),
  route( "/structures/:id/sensors/datapoints", DataPoints, "structureSensorsDataPoints" ),
  route( "/structures/:id/sensors/:id", StructureSensorEdit, "editStructureSensors" ),
  // route( "/structures/:id/sensors/new", StructureSensorCreate, "" ),
  route( "/locations/new", LocationCreate, "createLocation" ),
  route( "/locations/:id", LocationEdit, "editLocation" ),
]

const READ_ONLY_ROUTE_MAP = {
  LIFE_SPAN_OWNER: {
    ADMINISTRATOR: [],
    USER: [],
  },
  LIFE_SPAN_CLIENT: {
    ADMINISTRATOR: [
      "/locations/:id",
      "/structures/:id",
      "/organizations/:id",
      "/structures/:id/sensors/:id",
    ],
    USER: [],
  },
}

const ALLOWED_ROUTE_MAP = {
  LIFE_SPAN_OWNER: {
    ADMINISTRATOR: [ "*" ],
    USER: [ "dataView" ],
  },
  LIFE_SPAN_CLIENT: {
    ADMINISTRATOR: [
      "users",
      "dataView",
      "createUser",
      "editUser",
      "userAssignAlerts",
    ],
    USER: [ "dataView" ],
  },
}

const ALLOWED_ROUTE_MUTATION_MAP = {
  LIFE_SPAN_OWNER: {
    ADMINISTRATOR: [ "*" ],
    USER: [],
  },
  LIFE_SPAN_CLIENT: {
    ADMINISTRATOR: [
      "/users/new",
      "/users/:id",
      "/users/:id/alerts",
      "/structures/:id/sensors",
    ],
    USER: [ "dataView" ],
  },
}

const routeFromPathName = pathName => {
  const exp = /\d+/g
  const rawRoute = pathName.replace( exp, ":id" )

  return rawRoute
}


export const isOwner = user => user && user.type === "LIFE_SPAN_OWNER"
export const isClient = user => user && user.type === "LIFE_SPAN_CLIENT"

export const isUser = user => user && user.organizationRole === "USER"
export const isAdmin = user => user && user.organizationRole === "ADMINISTRATOR"

export const isOwnerAdmin = user => user && isOwner( user ) && isAdmin( user )
export const isClientAdmin = user => user && isClient( user ) && isAdmin( user )

export const setReadOnly = scope => {
  //const { location, store } = scope.props
  const { store } = scope.props
  const { location } = scope.props.router
  const { user } = store

  const readOnly = isReadOnlyRoute( location.pathname, user )
  if ( readOnly ) {
    scope.setState( { readOnly } )
  }
}

export const isReadOnlyRoute = ( pathName, user = {} ) => {
  const { organizationRole, type } = user

  const targetReadOnlyList = _.get( READ_ONLY_ROUTE_MAP, `${ type }.${ organizationRole }`, [] )

  const rawRoute = routeFromPathName( pathName )
  const isReadOnly = _.includes( targetReadOnlyList, rawRoute )

  return isReadOnly
}

export const canAccessItem = ( pathName, user = {} ) => {
  if ( !pathName ) { // Assuming this is a custom button and not a link to a view, might need to change later
    return true
  }

  const { organizationRole, type } = user

  const targetRouteMutationList = _.get( ALLOWED_ROUTE_MUTATION_MAP, `${ type }.${ organizationRole }`, [] )
  const fullAccess = _.includes( targetRouteMutationList, "*" )

  if ( fullAccess ) {
    return true
  }

  const rawRoute = routeFromPathName( pathName )
  const canAccess = _.includes( targetRouteMutationList, rawRoute )

  return canAccess
}

export const filterRoutesByUserAccess = ( routes, user = {} ) => {
  const { organizationRole, type } = user

  const targetRouteMap = _.get( ALLOWED_ROUTE_MAP, `${ type }.${ organizationRole }`, [] )

  const fullAccess = _.includes( targetRouteMap, "*" )

  if ( fullAccess ) {
    return routes
  }

  const filtered = routes.filter( route => {
    return _.includes( targetRouteMap, route.name )
  } )

  return filtered
}
