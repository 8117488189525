// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KC2zrKZtYLszwV3atizV {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  flex: 1;\n}\n\n.KYhHrUe3R6Nl3yrEMv0H {\n  padding-bottom: 10px;\n}\n\n.KYhHrUe3R6Nl3yrEMv0H > svg {\n  width: auto;\n  max-height: 128px;\n  height: 128px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/DataView/DeviceDataWrapper/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,OAAO;AACT;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,aAAa;AACf","sourcesContent":[".error {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  flex: 1;\n}\n\n.alertIcon {\n  padding-bottom: 10px;\n}\n\n.alertIcon > svg {\n  width: auto;\n  max-height: 128px;\n  height: 128px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "KC2zrKZtYLszwV3atizV",
	"alertIcon": "KYhHrUe3R6Nl3yrEMv0H"
};
export default ___CSS_LOADER_EXPORT___;
