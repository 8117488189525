// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UUyWa1lhfRpjrVZC9_TC {\n  display: flex;\n  cursor: pointer;\n  flex-direction: row;\n  align-items: center;\n}\n\n._SIPZZv0hTEWzpwiUXSB {\n  display: flex;\n  border-radius: 4px;\n  border: 1px solid;\n  margin: 10px;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 20px;\n  height: 20px;\n}\n\n.EWDo1SmI65u3T5AKmaBw {\n  font-size: 14px;\n  line-height: 2.56;\n  color: #231f20;\n}", "",{"version":3,"sources":["webpack://./src/components/DynamicForm/MultiSelect/Option/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".main {\n  display: flex;\n  cursor: pointer;\n  flex-direction: row;\n  align-items: center;\n}\n\n.check {\n  display: flex;\n  border-radius: 4px;\n  border: 1px solid;\n  margin: 10px;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 20px;\n  height: 20px;\n}\n\n.label {\n  font-size: 14px;\n  line-height: 2.56;\n  color: #231f20;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "UUyWa1lhfRpjrVZC9_TC",
	"check": "_SIPZZv0hTEWzpwiUXSB",
	"label": "EWDo1SmI65u3T5AKmaBw"
};
export default ___CSS_LOADER_EXPORT___;
