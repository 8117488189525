import ReactHighcharts from "react-highcharts"
import HighchartsMap from "highcharts-map"

const Highcharts = ReactHighcharts.Highcharts

// Initialize HighchartsMap
HighchartsMap( Highcharts )


function reflowBeforePrinting () {
  Highcharts.charts.forEach( function ( chart ) {
    if ( !chart ) {
      return
    }

    if ( !chart.printSizeMode ) {
      chart.oldhasUserSize = chart.hasUserSize
      chart.resetParams = [ chart.chartWidth, chart.chartHeight, false ]
      chart.printSizeMode = true
    }

    const printWidth = 620
    const printHeight = 350

    chart.setSize( printWidth, printHeight, false )
    chart.reflow()
    chart.redraw()
  } )
}

function reflowAfterPrinting () {
  Highcharts.charts.forEach( function ( chart ) {
    if ( !chart ) {
      return
    }

    if ( chart.printSizeMode ) {
      chart.setSize.apply( chart, chart.resetParams )
      chart.hasUserSize = chart.oldhasUserSize
      chart.reflow()
      chart.redraw()
      chart.printSizeMode = false
    }
  } )
}

if ( window.matchMedia ) {
  // chrome & safari (ff supports it but doesn't implement it the way we need)
  const mediaQueryList = window.matchMedia( "print" )

  mediaQueryList.addListener( function ( mql ) {
    if ( mql.matches ) {
      reflowBeforePrinting()
    } else {
      reflowAfterPrinting()
    }
  } )
}

window.addEventListener( "beforeprint", reflowBeforePrinting )
window.addEventListener( "afterprint", reflowAfterPrinting )
