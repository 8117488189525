// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B1f_0IR4q_5O9J4oQP1J {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 15px 20px;\n}\n\n.cfu5Vcz5qoOV3WTmhz15 {\n  font-size: 30px;\n  font-weight: 600;\n  color: #231f20;\n  text-align: center;\n}\n\n.Xs0F_A273lrfvttdOgio {\n  font-size: 20px;\n  font-weight: 600;\n  text-align: center;\n  color: #231f20;\n  white-space: pre-line;\n}\n\n.jq0nTqU6gWmNBVHmYnO9 {\n  position: relative;\n  display: flex;\n}\n\n.jq0nTqU6gWmNBVHmYnO9 > div > img {\n  width: 54px;\n  height: 54px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/GetReadingPopup/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".contentRoot {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 15px 20px;\n}\n\n.bigMessage {\n  font-size: 30px;\n  font-weight: 600;\n  color: #231f20;\n  text-align: center;\n}\n\n.readStatus {\n  font-size: 20px;\n  font-weight: 600;\n  text-align: center;\n  color: #231f20;\n  white-space: pre-line;\n}\n\n.spinnerWrapper {\n  position: relative;\n  display: flex;\n}\n\n.spinnerWrapper > div > img {\n  width: 54px;\n  height: 54px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentRoot": "B1f_0IR4q_5O9J4oQP1J",
	"bigMessage": "cfu5Vcz5qoOV3WTmhz15",
	"readStatus": "Xs0F_A273lrfvttdOgio",
	"spinnerWrapper": "jq0nTqU6gWmNBVHmYnO9"
};
export default ___CSS_LOADER_EXPORT___;
