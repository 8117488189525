import React from "react"
import { Component } from "react"
import { withRouter } from "utils/router"
import { withApolloClient } from "apollo"
import { setReadOnly } from "routes"
import { withStore } from "store"
import { wrapInMutation } from "views/EditView"
import { update, buildErrorHandle, buildActions } from "utils/submit"
import { EDIT_LOCATION } from "graphql/mutations"
import buildSchema from "./schema"

const buildHeader = ( operations = [], readOnly = false ) => ( {
  title: "Edit Location",
  activeSwitch: !readOnly,
  mutation: EDIT_LOCATION,
  tooltip: "Inactive users won't be able to\nsign-in to the system",
  operations,
} )

const fetchPolicy = "cache-and-network"

class EditLocation extends Component {
  state = {
    readOnly: false,
  }

  componentDidMount () {
    this.props.store.fetch( "organizations" )
    setReadOnly( this )
  }

  render () {
    const { readOnly } = this.state
    const { row } = this.props.router.location.state
    //const { goBack } = this.props.history
    const { history } = this.props.router

    const viewProps = {
      header: buildHeader( [], readOnly ),
      schema: buildSchema( this.props.store, row, readOnly ),
      value: row,
    }

    return wrapInMutation( {
      mutation: EDIT_LOCATION,
      row,
      viewProps,
      buildSubmit: update( this.props, "Location updated!", viewProps.schema ).bind( this ),
      handleError: buildErrorHandle( this.props, viewProps.schema ).bind( this ),
      buildActions: readOnly ? () => [] : buildActions( false, history.goBack ),
    } )
  }
}

export default withApolloClient( withRouter( withStore( EditLocation ) ) )
