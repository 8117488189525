// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ncqjlC8T7o7EHB_S_TAQ {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  cursor: pointer;\n}\n\n.Z5K5lAPvf6JYmpMaVPqQ {\n  height: 5px;\n  transform: rotate( 180deg );\n}\n\n.VPEgmVTK_sRpKi2zTxAI {\n  height: 5px;\n}\n\n.AJE8PMtma0K3NinjSbmp path {\n  fill: black\n}", "",{"version":3,"sources":["webpack://./src/components/DataTable/Sort/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  cursor: pointer;\n}\n\n.up {\n  height: 5px;\n  transform: rotate( 180deg );\n}\n\n.down {\n  height: 5px;\n}\n\n.activeSort path {\n  fill: black\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ncqjlC8T7o7EHB_S_TAQ",
	"up": "Z5K5lAPvf6JYmpMaVPqQ",
	"down": "VPEgmVTK_sRpKi2zTxAI",
	"activeSort": "AJE8PMtma0K3NinjSbmp"
};
export default ___CSS_LOADER_EXPORT___;
