import React from "react"
import DynamicFormChild from "../DynamicFormChild"
import DatePicker from "react-datepicker"
import TimePicker from "../TimePicker"
import Svg from "components/Svg"
import calendarIcon from "images/dropdown/calendar.svg"
import moment from "moment"

import "react-datepicker/dist/react-datepicker-cssmodules.css";
import style from "./style.css"
import "./override.css"

const defaultProps = { value: moment() }
const rawInputFormat = "MM/DD/YYYY HH:mm"
const dateFormat = "YYYY-MM-DD HH:mm"
const formatIn = val => val ? moment( val ) : null
const formatOut = val => val ? moment( val, dateFormat ).unix() * 1000 : null

class CustomDatePicker extends DatePicker {
  constructor ( props ) {
    super( props )
  }

  deferFocusInput = () => {
    this.cancelFocusInput()
  }
}

export default class DateTimePicker extends DynamicFormChild {
  constructor ( props, instance ) {
    super( props )

    this.state = { hidden: true }

    if ( instance === undefined ) {
      return React.createElement( DateTimePicker, props )
    }
  }

  fetchValue = valueType => {
    let { value } = this.props
    value = formatIn( value )

    if ( value ) {
      if ( valueType === "date" ) {
        const date = value.format( "YYYY-MM-DD" )
        const time = this.fetchValue( "time" )

        return moment( `${ date } ${ time }` )
      } else {
        return value.format( "HH:mm" )
      }
    } else {
      return null
    }
  };

  handleChange = ( x, type, extraMutation = false ) => {
    let { value, onChange } = this.props
    value = formatIn( value )
    let result = value ? value : defaultProps.value
    let date, time

    if ( type === "date" ) {
      date = moment( x ).format( "YYYY-MM-DD" )
      time = extraMutation ? extraMutation( result ) : time
      time = result.format( "HH:mm" )
    } else if ( type === "raw" ) {
      const momentObject = moment( x.target.value, rawInputFormat )
      date = momentObject.format( "YYYY-MM-DD" )
      time = momentObject.format( "HH:mm" )
    } else {
      date = result.format( "YYYY-MM-DD" )
      time = moment( x, "HH:mm" ).format( "HH:mm" )
    }

    result = date + " " + time

    if ( x || value ) onChange( formatOut( result ) )
    else onChange( null )
  }

  openCalendarOnIconClick () {
    !this.props.disabled && this.refs.calendar && this.refs.calendar.setOpen( true )
  }

  render () {
    const {
      label,
      className,
      container,
      extraChangeMutation,
    } = this.props && this.props.schema || {}

    return (
      <div className={container && container.className} title={this.props.title}>
        <CustomDatePicker
          showYearDropdown
          scrollableYearDropdown
          disabledKeyboardNavigation
          yearDropdownItemNumber={new Date().getFullYear() - 2009}
          ref={"calendar"}
          disabled={this.props.disabled}
          placeholderText={label}
          className={className}
          minDate={moment( "01/01/2009", "DD/MM/YYYY" )}
          maxDate={moment().add( 1, "month" )}
          selected={this.fetchValue( "date" )}
          onChange={val => this.handleChange( val, "date", extraChangeMutation )}
          onChangeRaw={val => this.handleChange( val, "raw" )}
          shouldCloseOnSelect={false}
          dateFormat={rawInputFormat}
        >
          <div className={style.textFieldContainer}>
            <TimePicker
              label="Time"
              name="time"
              defaultValue={this.props.schema.defaultValue}
              value={this.fetchValue( "time" )}
              onChange={val => this.handleChange( val, "time" )}
            />
          </div>
        </CustomDatePicker>
        <div className={style.calendar} onClick={this.openCalendarOnIconClick.bind( this )}>
          <Svg html={calendarIcon} />
        </div>
      </div>
    )
  }
}
