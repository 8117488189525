export const filterSearchFields = data => data.fields.filter( field => field.isSearchable )
export const calculatePaginationFromData = data => data.rows.length / data.itemsPerPage
export const applyPagination = ( page, data ) => {
  const { rows, itemsPerPage } = data

  const pointerLocation = --page * ( itemsPerPage || 1 )
  return rows.slice( pointerLocation, pointerLocation + itemsPerPage )
}

/* Data Structure
  #Field 
    name: str,
    displayName: str,
    isSearchable: bool,
    render: function/component,
    transform: function, transforms rawValue and passes it to render
    search: function,
    options: arr,
    type: string | int | float | date | datetime | time | enum (dropdown) | bool

  #Rows
    [] with each object having keys matching #Field names

  #Pagination
    bool
  
  #ItemsPerPage
    int

  #Offset, #Limit
    int
*/

const countryTransform = value => {
  const map = {
    pt: "Portugal",
    usa: "United States of America",
  }

  return map[ value ] || value
}

const fields = [
  { name: "title", displayName: "Organization Name", isSearchable: true, type: "string" },
  { name: "description", displayName: "Organization Description", isSearchable: true, type: "string" },
  { name: "country", displayName: "Country", isSearchable: false, type: "string", transform: countryTransform },
]

const sampleRow = i => ( { id: i, title: `Customer ${ i }`, description: `Lifespan customer ${ i }`, country: "pt" } )

const rows = [
  ...[ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20 ]
    .map( sampleRow ),
  { title: "Customer", description: "lmanata" },
]

export const sampleData = {
  fields,
  rows,
  pagination: true,
  itemsPerPage: 10,
}
