import React, { Component } from "react"
import spinImage from "images/spinners/dots.png"
import style from "./style.css"

// https://projects.lukehaas.me/css-loaders/

export const loadingStyle = { opacity: 0.3, pointerEvents: "none" }
class Spinner extends Component {
  render () {
    const { loading, info = "" } = this.props

    if ( !loading ) {
      return null
    }

    return (
      <div className={style.spinnerContainer}>
        <img className={style.spin} src={spinImage} />
        <div className={style.info}>{ info }</div>
      </div>
    )
  }
}


// Not sure if this is something worth having
// Once we have a query/mutation wrapper with a loader included, this might just become overhead
export const withLoader = ToWrap => {
  const className = `${ ToWrap.name }WithLoader`

  const wrappedComponent = class extends Component {
    render () {
      const { loaderMessage = "" } = this.props
      return this.props.loading ? <Spinner info={loaderMessage} loading /> : <ToWrap {...this.props} />
    }
  }

  Object.defineProperty( wrappedComponent, "name", { value: className } )

  return wrappedComponent
}

export default Spinner
