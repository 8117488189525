import React from "react"
import { Component } from "react"
import { withRouter } from "utils/router"
import { withApolloClient } from "apollo"
import { withStore } from "store"
import { wrapInMutation } from "views/EditView"
import { update, buildErrorHandle, buildActions } from "utils/submit"
import { EDIT_DEVICE } from "graphql/mutations"
import buildSchema from "./schema"

const buildHeader = ( operations = [] ) => ( {
  title: "Edit Structure",
  activeSwitch: true,
  mutation: EDIT_DEVICE,
  tooltip: "Inactive users won't be able to\nsign-in to the system",
  operations,
} )

const fetchPolicy = "cache-and-network"

class EditStructure extends Component {
  render () {
    const { row } = this.props.router.location.state
    //const { goBack } = this.props.history
    const { history } = this.props.router

    const viewProps = {
      header: buildHeader( [] ),
      schema: buildSchema( this.props.store, row ),
      value: row,
    }

    const callback = ( { data, row, diff }, triggerNotification ) => {
      const nextLocation = this.props.router.location.state.previousPathname

      const newState = {
        row: {
          ...row,
          ...diff,
        },
      }

      triggerNotification()
      this.props.router.history.push( nextLocation, newState ) // This will pop on browser, probably.
    }

    return wrapInMutation( {
      mutation: EDIT_DEVICE,
      row,
      viewProps,
      buildSubmit: update( this.props, "Structure updated!", viewProps.schema, false, callback.bind( this ) ).bind( this ),
      handleError: buildErrorHandle( this.props, viewProps.schema ).bind( this ),
      buildActions: buildActions( false, history.goBack ),
    } )
  }
}

export default withApolloClient( withRouter( withStore( EditStructure ) ) )
