import React from "react"
import DynamicFormChild from "../DynamicFormChild"
import FileDropzone from "../Fields/FileDropzone"

class FileField extends DynamicFormChild {
  constructor ( props, instance ) {
    super( props )

    if ( instance === undefined ) {
      return React.createElement( FileField, props )
    }
  }

  render () {
    const { value, schema, onLoad, renderValue } = this.props
    const { name, placeholder, options, style, className, multiple, accept, readOnly = false } = schema

    return (
      <div className={className} style={style}>
        <FileDropzone
          accept={accept}
          renderValue={renderValue}
          placeholder={placeholder}
          name={name}
          onLoad={onLoad}
          readOnly={readOnly}
          value={value || ""}
          multiple={multiple}
          onChange={e => this.props.onChange( e.target.value )}
          options={options}
        />
      </div>
    )
  }
}

export default FileField
