import React, { Component } from "react"

const enforceProps = props => {
  if ( !props.schema ) {
    throw new Error( "Dynamic Form Child Fields or Presentators must receive a \"schema\" prop!" )
  }

  // TODO: Ideally we would enforce that DynamicFormChild's have both
  // a value and onChange props. However these are injected later.
  //
  // Not when the component is created
}

class DynamicFormChild extends Component {
  constructor ( props ) {
    super( props )

    enforceProps( props )
  }
}

export default DynamicFormChild
