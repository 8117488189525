// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WhaN05a3apBCoP2ApgtG {\n  display: flex;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ToggleButtons/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".toggle {\n  display: flex;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": "WhaN05a3apBCoP2ApgtG"
};
export default ___CSS_LOADER_EXPORT___;
