import React, { Component } from "react"
import { Mutation } from "react-apollo"
import { RECOVER_PASSWORD } from "graphql/mutations"
import Svg from "components/Svg"
import style from "../Login/style.css"
import logo from "images/login/logo.png"
import backgroundImage from "images/login/background.png"
import usernameIcon from "images/login/user.svg"

const usernamePlaceholder = "Email Address / Username"

class RecoverPassword extends Component {
    state = {
      submitted: false,
      username: "",
    }

    renderError ( error ) {
      return (
        <div className={style.error}>
          { error }
        </div>
      )
    }

    handleKeyPress = ( event ) => {
      if ( event.key === "Enter" ) {
        this.state.submitButton && this.state.submitButton.click()
      }
    }

    renderSubmitButton () {
      const { username } = this.state
      const validInputs = username.length > 0

      const backgroundStyle = validInputs ? { backgroundColor: "#7dcb27" } : {}

      return (
        <div className={style.submitWrapper}>
          <div className={style.submitContainer} style={backgroundStyle}>
            <input
              type="submit"
              value="Recover Password"
              className={style.submitButton}
              ref={ submitButton => !this.state.submitButton && this.setState( { submitButton } )}
            />
          </div>
        </div>
      )
    }

    renderAfterSubmitMessage () {
      const messageStyle = {
        color: "#2f3842",
        fontSize: "22px",
        fontWeight: "600",
        padding: "25px",
      }

      return (
        <div className={style.inputBox} style={messageStyle}>
          If the provided email/username account exists, you will receive an email with a link to reset your password briefly.
        </div>
      )
    }

    renderInputs () {
      const { error } = this.state

      return (
        <div className={style.inputBox}>
          <div className={style.inputContainer}>
            <Svg className={style.icon} html={usernameIcon} />
            <input
              className={style.input}
              placeholder={usernamePlaceholder}
              name={"email"}
              type={"text"}
              onKeyPress={this.handleKeyPress.bind( this )}
              onChange={ e => this.setState( { username: e.target.value } ) }
            />
          </div>
          { error ? this.renderError( error ) : "" }
          { this.renderSubmitButton() }
        </div>
      )
    }

    renderLogo () {
      return (
        <div className={style.logoContainer} style={{ marginBottom: "auto" }}>
          <img className={style.logo} src={logo} />
        </div>
      )
    }

    submit ( recover ) {
      return function ( e ) {
        const { addNotification } = this.props
        const { username } = this.state
        e.preventDefault()

        recover( { variables: { identifier: username } } )
          .then( () => {
            addNotification( "info", "Success" )
            this.setState( { submitted: true } )
          } )
          .catch( () => {
            addNotification( "error", "An error occurred" )
          } )
      }
    }

    render () {
      const { submitted = false } = this.state
      return (
        <Mutation mutation={RECOVER_PASSWORD}>
          {( recover ) => {
            const onSubmit = this.submit( recover )

            return (
              <div className={style.main}>
                <div className={style.background}>
                  <img src={backgroundImage} />
                </div>
                <div className={style.column} style={{ height: "50%" }}>
                  { this.renderLogo() }
                  <div className={style.formContainer}>
                    <form className={style.form} onSubmit={onSubmit.bind( this )}>
                      { !submitted ? this.renderInputs() : this.renderAfterSubmitMessage() }
                    </form>
                  </div>
                </div>
              </div>
            )
          }}
        </Mutation>
      )
    }
}

export default RecoverPassword
