// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ff9NiB3bZsA29QLfA0cA {\n  width: 100%;\n  border-radius: .3rem;\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n  text-align: center;\n}\n\n.Ff9NiB3bZsA29QLfA0cA > div {\n  border-top: solid 1px #ededed;\n}\n\n.U2xut4QqdQdRwbY6d73d {\n  cursor: pointer;\n  width: fit-content !important;\n  display: flex;\n  justify-content: flex-end;\n}", "",{"version":3,"sources":["webpack://./src/components/DynamicForm/DateTimePicker/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,oBAAoB;EACpB,yBAAyB;EACzB,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,6BAA6B;EAC7B,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".textFieldContainer {\n  width: 100%;\n  border-radius: .3rem;\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n  text-align: center;\n}\n\n.textFieldContainer > div {\n  border-top: solid 1px #ededed;\n}\n\n.calendar {\n  cursor: pointer;\n  width: fit-content !important;\n  display: flex;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textFieldContainer": "Ff9NiB3bZsA29QLfA0cA",
	"calendar": "U2xut4QqdQdRwbY6d73d"
};
export default ___CSS_LOADER_EXPORT___;
