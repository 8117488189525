import _ from "lodash"
import { GET_STRUCTURE_SENSORS } from "graphql/queries"


export function fetchSensors ( replaceState = false ) {
  const query = GET_STRUCTURE_SENSORS
  const variables = {
    id: this.props.router.location.state.row.id,
  }

  const callback = result => {
    const sensors = _.get( result, "data.deviceById.sensorsByDeviceId.nodes", [] )

    if ( replaceState ) {
      const { state } = this.props.router.location

      this.props.history.replace( this.props.router.location.pathname, { ...state, sensors } )
    } else {
      this.setState( {
        sensors,
      } )
    }
  }

  this.props.client.query( {
    query,
    variables,
    fetchPolicy: "no-cache",
  } ).then( callback.bind( this ) )
}
