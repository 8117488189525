import React, { Component } from "react"
import NumberFormat from "react-number-format"
import moment from "moment"
import styles from "./style.css"

const getMinMaxCondition = ( min, max ) => ( { floatValue } ) => !floatValue || floatValue >= min && floatValue <= max
const formatInput = inputString => {
  return inputString.length > 2
    ? `${ inputString.slice( 1, 2 ) }${ inputString.slice( 2 ) }`
    : inputString.padStart( 2, "0" )
}

export default class TimePicker extends Component {
  constructor ( props ) {
    super( props )

    this.state = { period: props.defaultValue ? props.defaultValue.period : "am" }
  }

  parseTimeVal = val => {
    return val.toString().length === 1 ? "0" + val.toString() : val.toString()
  };

  timeToString = ( { hour, minute, period } ) => {
    let result = hour + ":" + minute + period
    return moment( result, "HH:mma" ).format( "HH:mm" )
  };

  stringToTime = () => {
    const { value, defaultValue } = this.props

    let result = { hour: null, minute: null, period: this.state.period }

    if ( value ) {
      let _value = moment( value, "H:m" )

      result.hour = parseInt( _value.format( "hh" ) )
      result.minute = parseInt( _value.format( "mm" ) )
      result.period = _value.format( "a" )
    }

    return value ? result : defaultValue
  };

  handleState = ( val, field ) => {
    const { onChange, value, defaultValue } = this.props
    let result = value ? this.stringToTime( value ) : defaultValue

    result[ field ] = val

    if ( field === "period" ) {
      this.setState( { period: val } )
    }

    onChange( this.timeToString( result ) )
  };

  render () {
    const { hour, minute, period } = this.stringToTime()

    const activePeriodClass = value => period === value
      ? [ styles.activePeriod, styles.period ].join( " " )
      : styles.period

    return (
      <div className={styles.container} style={this.props.style}>
        <div className={styles.label}> { this.props.label } </div>
        <div className={styles.inputContainer}>
          <div className={styles.input}>
            <NumberFormat
              value={hour}
              format={formatInput.bind( this )}
              allowNegative={false}
              isAllowed={ getMinMaxCondition( 1, 12 ) }
              onValueChange={( { floatValue } ) => this.handleState( floatValue, "hour" )}
            />
          </div>
          <div className={styles.timeSeparator}>:</div>
          <div className={styles.input}>
            <NumberFormat
              format={formatInput.bind( this )}
              value={minute}
              allowNegative={false}
              isAllowed={ getMinMaxCondition( 0, 60 ) }
              onValueChange={( { floatValue } ) => this.handleState( floatValue, "minute" )}
            />
          </div>
        </div>
        <div className={styles.periodContainer}>
          <div className={ activePeriodClass( "am" ) } onClick={() => this.handleState( "am", "period" ) }>AM</div>
          <div className={ activePeriodClass( "pm" ) } onClick={() => this.handleState( "pm", "period" ) }>PM</div>
        </div>
      </div>
    )
  }
}
