// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IEEPn4HYfFgs7ThNfKkh {\n  display: flex;\n  flex-direction: column;\n  padding: 50px;\n}\n\n.J6iSeuZeUnsee63NKheI {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.HaMWA1VmJSKlD9ipVHZ7 {\n  display: flex;\n  cursor: pointer;\n  padding: 15px;\n  justify-content: center;\n  align-items: center;\n  font-weight: 500;\n  color: #a9a9a9;\n}", "",{"version":3,"sources":["webpack://./src/components/MultiElementContainer/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".main {\n  display: flex;\n  flex-direction: column;\n  padding: 50px;\n}\n\n.headers {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.elementHeader {\n  display: flex;\n  cursor: pointer;\n  padding: 15px;\n  justify-content: center;\n  align-items: center;\n  font-weight: 500;\n  color: #a9a9a9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "IEEPn4HYfFgs7ThNfKkh",
	"headers": "J6iSeuZeUnsee63NKheI",
	"elementHeader": "HaMWA1VmJSKlD9ipVHZ7"
};
export default ___CSS_LOADER_EXPORT___;
