import React, { Component } from "react"
import style from "./style.css"
import { importDeviceTimestamp } from "../utils"
import ReactHighcharts from "react-highcharts"

import _ from "lodash"
import { withStore } from "store.js"

const DECIMAL_PRECISION = 6

const TOOLTIP_HTML = `
  <span style="font-size: 10px">{point.key}</span><br/>
`

const POINT_FORMAT_HTML = `
  <b>{point.y}</b><br/>
`

const AXIS_LABEL_STYLE = {
  color: "#231f20",
  fontSize: "12px",
  fontWeight: "bold",
  fontFamily: "'Source Sans Pro', sans-serif",
}

class Graph extends Component {
  constructor ( props ) {
    super( props )

    console.assert( Array.isArray( props.data ) )
    console.assert( Array.isArray( props.channels ) )

    this.rootRef = React.createRef()
  }

  shouldComponentUpdate ( nextProps ) {
    return !_.isEqual( this.props.channels, nextProps.channels ) ||
      !_.isEqual( this.props.data, nextProps.data )
  }

  buildConfig = ( data, channels ) => {
    const measurementSeries = _.chain( data )
      .groupBy( "channelId" )
      .map( ( data, stringChannelId ) => {
        const channelId = _.parseInt( stringChannelId )
        const channel = _.find( channels, { id: channelId } )
        const formattedData = data.map( measurement => ( [
          importDeviceTimestamp( measurement.deviceTimestamp ).getTime(),
          measurement.value,
        ] ) )

        return {
          type: "line",
          name: _.get( channel, "channelTypeByChannelTypeId.name" ),
          data: formattedData,
          lineWidth: 1,
          tooltip: {
            valueSuffix: " " + _.get( channel, "unitByUnitId.symbol" ),
          },
        }
      } )
      .value()

    const unit = _.get( channels, "[0].unitByUnitId" )

    return {
      chart: {
        animation: false,
        zoomType: "x",
      },
      colors: [ "#dd5e71", "#17abc5" ],

      mapNavigation: {
        enableMouseWheelZoom: true,
      },

      legend: {
        enabled: channels.length > 1,
      },

      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
        line: {
          tooltip: {
            dateTimeLabelFormats: {
              millisecond: "%b %e %Y, %H:%M:%S.%L",
              second: "%b %e %Y, %H:%M:%S",
              minute: "%b %e %Y, %H:%M",
              hour: "%b %e %Y, %H:%M",
              day: "%b %e, %Y",
              week: "Week from %b %e, %Y",
            },
            followPointer: true,
            followTouchMove: true,
            headerFormat: TOOLTIP_HTML,
            pointFormat: POINT_FORMAT_HTML,
          },
        },
      },

      title: {
        style: {
          fontFamily: "'Source Sans Pro', sans-serif",
          fontSize: "16px",
          fontWeight: "bold",
          color: "#2f3842",
        },
        text: measurementSeries
          .map( ms => ms.name )
          .join( " and " ),
      },

      tooltip: {
        valueDecimals: DECIMAL_PRECISION,
        positioner: function ( labelWidth, labelHeight, point ) {
          const invert = ( point.plotX + labelWidth + 20 ) > this.chart.plotWidth

          return {
            x: point.plotX + ( invert ? 0 : labelWidth ) + ( invert ? -20 : 20 ),
            y: point.plotY + labelHeight + 15,
          }
        },
      },

      xAxis: {
        type: "datetime",
        gridLineWidth: 1,
        crosshair: {
          width: 3,
          color: "#CCC",
        },
        labels: {
          style: AXIS_LABEL_STYLE,
        },
        dateTimeLabelFormats: {
          millisecond: "%l %p:%S.%L",
          second: "%l %p:%S",
          minute: "%l %p<br/>%m.%d.%y",
          hour: "%l %p<br/>%m.%d.%y",
          day: "%m/%d/%y",
          week: "%m/%d/%y",
          month: "%m '%y",
          year: "%Y",
        },
        lineWidth: 2,
        lineColor: "#636363",
        title: {
          align: "high",
          text: `<span class="${ style.axisTitleWrapper }">Time</span>`,
          useHTML: true,
          margin: 5,
        },
      },

      yAxis: {
        labels: {
          style: AXIS_LABEL_STYLE,
          formatter: function () { // Use a regular function to prevent binding `this`
            return this.value.toString()
          },
        },
        title: {
          align: "high",
          offset: 0,
          text: `<span class="${ style.axisTitleWrapper }">${ unit.name }</span>`,
          useHTML: true,
          rotation: 0,
          y: -20,
        },
        lineWidth: 2,
        lineColor: "#636363",
      },

      series: measurementSeries,
    }
  }

  componentDidMount () {
    this.rootRef.current.addEventListener( "wheel", this.handleWheel, true )
  }

  componentWillUnmount () {
    this.rootRef.current.removeEventListener( "wheel", this.handleWheel, true )
  }

  handleWheel = event => {
    if ( !event.ctrlKey ) {
      let scrollAmount = event.deltaY

      if ( event.deltaMode === WheelEvent.DOM_DELTA_LINE ) {
        scrollAmount *= 15
      }

      window.scrollBy( {
        top: scrollAmount,
        behaviour: "smooth",
      } )

      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
    }
  }

  render () {
    const { className, data, channels } = this.props

    return (
      <div
        className={[ style.root, className ].join( " " )}
        ref={this.rootRef}
      >
        <ReactHighcharts isPureConfig config={this.buildConfig( data, channels )} />
      </div>
    )

  }
}

export default withStore( Graph )
