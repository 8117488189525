import React, { Component } from "react"
import { Link } from "react-router-dom"
import Pagination from "./Pagination"
import Sort from "./Sort"
import style from "./style.css"
import { applyPagination, calculatePaginationFromData } from "./util"
import { filterDataBySearchTerms } from "./DataBuilder/search"
import { sortData } from "./Sort/util"

const mergeClassNames = classes => classes.filter( name => name ).join( " " )

class FieldRow extends Component {
  componentDidCatch ( error ) {
    console.error ( error )
  }
  render () {
    const { render, value, row, editRoute, onClick, className, parentRow = {}, ignoreLink = false } = this.props
    const { id, _id, toId } = row

    const to = editRoute
      ? toId && toId.id ?  { pathname: `${ editRoute }${ toId.id }`, state: { row, ...parentRow } } :
      id ? { pathname: `${ editRoute }${ id }`, state: { row, ...parentRow } } : { pathname: editRoute }
      : undefined

    if ( ignoreLink ) {
      return ( <div className={style.row}> { render( value, row ) } </div> )
    }

    const mergedClassName = mergeClassNames([ style.row, className ])

    return to && !onClick ? (
      <Link className={style.link} to={to} state={to.state}>
        <div className={mergedClassName}> { render( value, row ) } </div>
      </Link>
    ) : (
      <div onClick={e => onClick( id || _id, value, e )} className={mergedClassName}> { render( value, row ) } </div>
    )
  }
}

class DataTable extends Component {
  state = {
    page: 1,
    sortFields: [],
    searchTerms: [],
  }

  applySort ( field, order ) {
    const { multipleColumnSort } = this.props
    const { sortFields } = this.state
    const { name } = field

    const isResettingOrder = sortFields.some(
      sortField => sortField.field.name === name && sortField.order === order
    )

    const clearedSortFields = sortFields.filter(
      sortedField => sortedField.field.name !== name
    )

    const sortField = { field, order }

    const updatedSort = !isResettingOrder
      ? multipleColumnSort ? [ ...clearedSortFields, sortField ] : [ sortField ]
      : [ ...clearedSortFields ]

    this.setState( { sortFields: updatedSort } )
  }

  applySearchTerm ( field, searchTerm ) {
    const { searchTerms } = this.state
    const { name } = field

    const isValidSearchTerm = searchTerm.length > 0
    const clearedSearchTerms = searchTerms.filter( searchObject => {
      const searchObjectName = searchObject.field.name

      if ( searchObjectName === name && !isValidSearchTerm ) {
        return false
      }

      return searchObjectName !== name
    } )

    const updatedSearchTerms = isValidSearchTerm
      ? [ ...clearedSearchTerms, { field, term: searchTerm } ]
      : [ ...clearedSearchTerms ]

    this.setState( { searchTerms: updatedSearchTerms } )
  }

  renderVertically ( value, page ) {
    const { styles = {} } = this.props
    const { fields, rows } = value

    const columnRowsContainerClassName = mergeClassNames([
      style.columnRowsContainer,
      styles.columnRowsContainer,
    ])

    return fields.map( ( field, index ) => {
      return (
        <div key={index} className={style.columnContainer} style={field.style}>
          <div className={style.columnHeaderContainer}>
            { this.renderHeader( field, rows ) }
          </div>
          <div className={style.spacer} />
          <div className={columnRowsContainerClassName}>
            { this.renderFieldRows( applyPagination( page, value ), field, page ) }
          </div>
        </div>
      )
    } )
  }

  renderHeader ( field, rows ) {
    const { displayName, isSearchable } = field

    const { header = {} } = field
    const { render, onClick } = header

    const search = (
      <div className={style.searchContainer} style={ !isSearchable ? { opacity: "0.2" } : {} }>
        <input
          style={ !isSearchable ? { backgroundColor: "#fcfcfc" } : {} }
          className={style.searchInput}
          type="text"
          name={field.name}
          placeholder={ isSearchable ? "Search" : "" }
          disabled={!isSearchable}
          onChange={ event => { this.applySearchTerm( field, event.target.value ) } }
        />
      </div>
    )

    if ( render ) {
      return (
        <div className={style.headerFieldContainer} onClick={() => onClick && onClick( field, rows ) } >
          { render( field, rows ) }
        </div>
      )
    }

    return (
      <div className={style.headerFieldContainer} onClick={() => onClick && onClick( field, rows ) } >
        <div className={style.headerFieldRow}>
          <div className={style.header}>
            { displayName }
          </div>
          { this.renderHeaderSort( field ) }
        </div>
        { displayName ? search : "" }
      </div>
    )
  }

  renderHeaderSort ( field ) {
    const { name, isSortable = true } = field
    const { sortFields } = this.state

    const onClick = ( field ) => order => this.applySort( field, order )

    const sortField = sortFields.filter( sorted => sorted.field.name === name )
    const order = sortField.length > 0 ? sortField[ 0 ].order : ""

    return isSortable ? (
      <div className={style.sort}>
        <Sort order={order} onClick={ onClick( field ) } />
      </div>
    ) : null
  }

  renderFieldRows ( rows = [], field = "", page ) {
    const { styles = {} } = this.props

    const className = mergeClassNames([style.rowContainer, styles.rowContainer])
    return rows.map( ( row, index ) => (
      <div key={index} className={className}>
        { this.renderFieldRow( row, field, index, page ) }
      </div>
    ) )
  }

  renderFieldRow ( row, field, index, page ) {
    const { editRoute, parentRow = {}, styles = {} } = this.props

    // console.log('field', field)

    const { name, transform, render, onClick, ignoreLink } = field
    if ( !name ) {
      throw new Error( "Field name required!" )
    }

    const rawValue = row[ name ]
    const value = transform ? transform( rawValue ) : rawValue
    return (
      <FieldRow
        className={styles.row}
        index={index}
        onClick={onClick}
        render={render}
        value={value}
        row={row}
        editRoute={editRoute}
        parentRow={parentRow}
        ignoreLink={ignoreLink}
      />
    )
  }

  render () {
    const { value } = this.props
    const { page, searchTerms, sortFields } = this.state

    const filteredData = filterDataBySearchTerms( value, searchTerms )
    const sortedData = sortData( filteredData, sortFields )

    const pages = Math.ceil( calculatePaginationFromData( sortedData ) )

    const pagination = {
      pages,
      current: page,
      next: () => page < pages ? this.setState( { page: page + 1 } ) : null,
      back: () => page > 1 ? this.setState( { page: page - 1 } ) : null,
      change: page => this.setState( { page } ),
    }

    return (
      <div className={style.dataTableContainer}>
        <div className={style.columnsContainer}>
          { this.renderVertically( sortedData, page ) }
        </div>
        <div className={style.pagination}>
          <Pagination pagination={pagination} />
        </div>
      </div>
    )
  }

}

export default DataTable
