const fieldContainer = className => direction => ( {
  className,
  style: {
    display: "flex",
    flexDirection: direction,
  },
} )

const imageField = style => ( name, label ) => ( {
  name,
  label,
  fieldType: "image",
  className: style.imageInput,
  container: fieldContainer( style.imageInputContainer )( "column" ),
} )

const stringField = style => ( name, label ) => ( {
  name,
  label,
  fieldType: "string",
  className: style.textInput,
  container: fieldContainer( style.textInputContainer )( "column" ),
} )

const enumField = style => ( name, label, options ) => ( {
  name,
  label,
  options,
  fieldType: "enum",
  container: fieldContainer( style.textInputContainer )( "column" ),
  className: style.textInput,
} )

const presentator = style => ( direction, children ) => ( {
  direction,
  children,
  style: { justifyContent: "space-between" },
} )

export const styledHelpers = style => ( {
  imageField: imageField( style ),
  stringField: stringField( style ),
  enumField: enumField( style ),
  presentator: presentator( style ),
} )

const countryPriorities = [
  "United States",
  "Canada",
]
const isPriority = label => countryPriorities.indexOf( label ) !== -1 ? countryPriorities.indexOf( label ) : false
export const orderCountryOptions = options => options.sort( ( a, b ) => {
  const aPriority = isPriority( a.label )
  const bPriority = isPriority( b.label )

  if ( aPriority !== false || bPriority !== false ) {
    if ( aPriority === false ) return 1
    if ( bPriority === false ) return -1

    return aPriority > bPriority ? 1 : -1
  }

  return 0
} )
