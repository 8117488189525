import style from "shared/style.css"
import Presentator from "components/DynamicForm/Presentator"
import Field from "components/DynamicForm/Field"
import { orderCountryOptions } from "utils/schema"
import { triggerLoadIfNeeded } from "store"
import _ from "lodash"

export default ( store, row, readOnly = false ) => {
  const {
    countries = [],
    organizations = [],
  } = store

  triggerLoadIfNeeded( { countries }, store.fetch, row )

  const commonStringDefinition = {
    fieldType: "string",
    className: style.field,
    container: { className: style.fieldContainer },
  }

  const locationName = new Field( {
    disabled: readOnly,
    required: true,
    schema: {
      name: "name",
      label: "Location Name",
      ...commonStringDefinition,
    },
  } )

  const description = new Field( {
    disabled: readOnly,
    schema: {
      name: "description",
      label: "Location Description",
      ...commonStringDefinition,
    },
  } )

  const address1 = new Field( {
    disabled: readOnly,
    schema: {
      name: "address1",
      label: "Address 1",
      ...commonStringDefinition,
    },
  } )

  const address2 = new Field( {
    disabled: readOnly,
    schema: {
      name: "address2",
      label: "Address 2",
      ...commonStringDefinition,
    },
  } )

  const address3 = new Field( {
    disabled: readOnly,
    schema: {
      name: "address3",
      label: "Address 3",
      ...commonStringDefinition,
    },
  } )

  const state = new Field( {
    disabled: readOnly,
    schema: {
      name: "state",
      label: "State",
      ...commonStringDefinition,
    },
  } )

  const city = new Field( {
    disabled: readOnly,
    schema: {
      name: "city",
      label: "City",
      ...commonStringDefinition,
    },
  } )

  const zipCode = new Field( {
    disabled: readOnly,
    schema: {
      name: "zipCode",
      label: "ZIP Code",
      ...commonStringDefinition,
    },
  } )

  const organization = new Field( {
    disabled: readOnly,
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "organizationId",
      label: "Organization Name",
      ...commonStringDefinition,
      fieldType: "enum",
      options: organizations.map( org => ( { value: org.id, label: org.name } ) ),
    },
  } )

  const countryOptions = countries.map( country => ( { value: country.id, label: country.name } ) )
  const country = new Field( {
    disabled: readOnly,
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "countryId",
      label: "Country",
      ...commonStringDefinition,
      fieldType: "enum",
      options: countryOptions,
      sortFn: orderCountryOptions,
    },
  } )

  const middlePresentator = new Presentator( {
    direction: "column",
    schema: {
      children: [ description, address1, address2, address3 ],
    },
  } )

  const firstPresentator = new Presentator( {
    direction: "row",
    schema: {
      className: style.main,
      children: [ organization, locationName ],
    },
  } )

  const lastPresentator = new Presentator( {
    direction: "row",
    schema: {
      className: style.main,
      children: [ state, city, zipCode, country ],
    },
  } )

  const mainPresentator = new Presentator( {
    passThrough: true,
    schema: {
      children: [
        firstPresentator,
        middlePresentator,
        lastPresentator,
      ],
    },
  } )

  const schema = {
    name: "location",
    passThrough: true,
    direction: "column",
    children: [ mainPresentator ],
  }

  return schema
}
