import React, { Component } from "react"
import Svg from "components/Svg"
import style from "./style.css"
import failedIcon from "images/getReading/failure.svg"
import successIcon from "images/getReading/success.svg"

export default class Popup extends Component {
  render () {
    const {
      shouldRender,
      children,
      onOutsideClick,
    } = this.props

    if ( !shouldRender ) {
      return null
    }

    return (
      <div className={style.root} onClick={onOutsideClick}>
        <div className={style.island}>
          {children}
        </div>
      </div>
    )
  }
}

export class DeletePopup extends Component {
  render () {
    const { confirm, deny, dialog = "Are you sure you want to delete this item?" } = this.props

    return (
      <Popup shouldRender onOutsideClick={deny}>
        <div className={ style.delete }>
          <div className={ style.dialog }>{ dialog }</div>
          <div className={ style.buttons }>
            <Svg className={ style.button } html={successIcon} onClick={confirm} />
            <Svg className={ style.button } html={failedIcon} onClick={deny} />
          </div>
        </div>
      </Popup>
    )
  }
}
