import React, { Component } from "react"
import _ from "lodash"
import Switch from "react-switch"
import { withRouter } from "utils/router"
import { Mutation } from "react-apollo"
import Svg from "components/Svg"
import style from "./style.css"
import questionMark from "images/views/question.svg"
import arrow from "images/sidebar/arrow.svg"

export const dataTableSwitch = mutation => ( _, value ) => {
  const { id, isActive } = value

  return <ActiveSwitch
    readOnly
    tooltip={false}
    mutation={mutation}
    value={{ id, isActive }}
  />
}

export class ActiveSwitch extends Component {
  render () {
    const { value, mutation, onChange, tooltip, isImutable, readOnly = false } = this.props

    return (
      <Mutation mutation={mutation}>
        {( update, { error } ) => {
          if ( error ) {
            onChange( { isActive: value.isActive } )
          }

          let variables;

          if (isImutable) {
            variables = {
              updateObject: { id: value.id, isActive: !value.isActive },
            }
          } else {
            variables = {
              id: value.id,
              updateObject: { isActive: !value.isActive },
            }
          }

          const tooltipItem = (
            <div title={tooltip} className={style.switchTooltip}>
              <Svg html={questionMark} />
            </div>
          )

          const events = readOnly ? { pointerEvents: "none" } : {}

          return (
            <div className={[ style.operation, style.switch ].join( " " )} style={events} >
              <Switch
                handleDiameter={20}
                onColor="#6ec90a"
                uncheckedIcon={false}
                checkedIcon={false}
                className={style.operation}
                checked={value.isActive}
                onChange={() => {
                  update( { variables } )
                  onChange( { isActive: !value.isActive } )
                }}
              />
              { tooltip ? tooltipItem : null }
            </div>
          )
        }}
      </Mutation>
    )
  }
}

class Header extends Component {
  renderBack () {
    const { goBack } = this.props.history

    return (
      <div className={style.backContainer} onClick={() => goBack() } >
        <Svg html={arrow} className={style.arrow} />
      </div>
    )
  }

  renderOperation ( operation, key ) {
    return {
      ...operation,
      key,
    }
  }

  render () {
    const { title, value, operations = [], hideBack, activeSwitch, displayCount, isImutable } = this.props

    const count = _.get( displayCount, "count" )

    const countDisplay = count ? (
      <div className={style.countDisplay}> { count( value ) }</div>
    ) : ""

    return (
      <div className={style.headerContainer}>
        { !hideBack ? this.renderBack() : null}
        <div className={style.titleContainer}>
          <h1>{ title }</h1>
        </div>
        { countDisplay }
        <div className={style.operationsContainer}>
          { activeSwitch
            ? <ActiveSwitch
              tooltip={this.props.tooltip}
              onChange={this.props.onChange}
              value={{ id: value.id, isActive: value.isActive }}
              mutation={this.props.mutation}
              isImutable={isImutable}
            />
            : ""
          }
          { operations.map( this.renderOperation.bind( this ) ) }
        </div>
      </div>
    )

  }

}

export default withRouter( Header )
