import React from "react"


import { Component } from "react"
import _ from "lodash"
import { withRouter } from "utils/router"
import { setReadOnly } from "routes"
import { withStore } from "store"
import { withApolloClient } from "apollo"
import { wrapInMutation } from "views/EditView"
import { buildSchema } from "./schema"
import { update, buildErrorHandle, buildActions } from "utils/submit"
import { EDIT_ORGANIZATION } from "graphql/mutations"
import { GET_ORGANIZATION_USERS } from "graphql/queries"
import { buildRenderList, usersFields, locationsFields } from "shared/lists"
import { GET_ORGANIZATION_LOCATIONS } from "../../graphql/queries"

const header = {
  title: "Edit Organization",
}

const fetchPolicy = "cache-and-network"

class EditOrganization extends Component {
  state = {
    users: [],
    locations: [],
  }

  componentDidMount () {
    this.fetchUsers()
    this.fetchLocations()
    setReadOnly( this )
  }

  fetchUsers () {
    const query = GET_ORGANIZATION_USERS
    const variables = {
      id: this.props.router.location.state.row.id,
    }

    const callback = result => { this.setState( {
      users: _.get( result, "data.organizationById.usersByOrganizationId.nodes", [] ),
    } ) }

    this.props.client.query( {
      query,
      variables,
      fetchPolicy: "network-only",
    } ).then( callback.bind( this ) )
  }

  fetchLocations () {
    const query = GET_ORGANIZATION_LOCATIONS
    const variables = {
      id: this.props.router.location.state.row.id,
    }

    const callback = result => { this.setState( {
      locations: _.get( result, "data.organizationById.locationsByOrganizationId.nodes", [] ),
    } ) }

    this.props.client.query( {
      query,
      variables,
      fetchPolicy: "network-only",
    } ).then( callback.bind( this ) )
  }

  render () {
    const { row } = this.props.router.location.state
    //const { goBack } = this.props.history
    const { history } = this.props.router

    const { store } = this.props
    const { users, locations, readOnly = false } = this.state

    const locationsList = {
      label: "Assigned Locations",
      render: buildRenderList( locations, locationsFields, 10 ),
    }

    const usersList = {
      label: "Assigned Users",
      render: buildRenderList( users, usersFields, 10 ),
    }

    const lists = [
      locationsList,
      usersList,
    ]

    const viewProps = {
      header,
      schema: buildSchema( store, readOnly ),
      value: row,
      lists,
    }

    return wrapInMutation( {
      mutation: EDIT_ORGANIZATION,
      row,
      viewProps,
      buildSubmit: update( this.props, "Organization updated!", viewProps.schema ).bind( this ),
      handleError: buildErrorHandle( this.props, viewProps.schema ).bind( this ),
      buildActions: readOnly ? () => [] : buildActions( false, history.goBack ),
    } )
  }
}

export default withStore( withRouter( withApolloClient( EditOrganization ) ) )
