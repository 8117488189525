import React, { Component } from "react"
import _ from "lodash"
import { Link } from "react-router-dom"
import { withRouter } from "utils/router"
import { withStore } from "store"
import { MoreButton } from "components/ToggleButtons"
import { canAccessItem } from "routes"
import Svg from "components/Svg"
import style from "./style.css"
import structuresIcon from "images/views/assign-structures.svg"
import alertsIcon from "images/views/setup-alerts.svg"
import addIcon from "images/buttons/add.svg"
import sensorsIcon from "images/views/manage-sensors.svg"
import downloadIcon from "images/views/download-data.svg"
import editIcon from "images/buttons/edit.svg"
import dataPointsIcon from "images/views/data-points.svg"
import removeIcon from "images/buttons/delete.svg"
import getReadingIcon from "images/getReading/icon.svg"

class LinkWrapper extends Component {
  render () {
    const { pathname, state, icon, label, className, store } = this.props
    const { user } = store

    const hasAccess = canAccessItem( pathname, user )
    if ( !hasAccess ) {
      return ""
    }

    return (
      <Link to={{ pathname, state }} state = {state} className={[ style.operation, className ].join( " " )}>
        <Svg html={icon} />
        <div className={style.operationLabel}>{ label }</div>
      </Link>
    )
  }
}

const LinkAccess = withStore( LinkWrapper )
const containerItem = ( linkProps ) => <LinkAccess { ...linkProps } />

export const assignStructuresItem = ( pathName, state = {} ) => containerItem( {
  pathname: `${ pathName }/structures`,
  state,
  icon: structuresIcon,
  label: "Assign Structures",
} )

export const getReadingItem = onClick => (
  <div className={[ style.operation, style.getReadingItem ].join( " " )} onClick={onClick}>
    <Svg html={getReadingIcon} />
    <div className={[ style.operationLabel, style.getReadingLabel ].join( " " )}>Get Reading</div>
  </div>
)

export const deleteCurrentItem = onClick => (
  <div className={[ style.operation, style.deleteItem ].join( " " )} onClick={onClick}>
    <Svg html={removeIcon} />
    <div className={style.operationLabel} />
  </div>
)

export const downloadItem = ( { label, className, onClick } ) => (
  <div className={[ style.operation, className ].join( " " )} onClick={onClick}>
    <Svg html={downloadIcon} />
    <div className={style.operationLabel}>{ label }</div>
  </div>
)

export const showLogs = ( {label, onClick} ) => (
  <div className={[ style.operation ].join( " " )} onClick={onClick}>
    <div className={style.operationLabel}>{label}</div>
  </div>
)

export const setupAlertsItem = ( pathName, state = {} ) => containerItem( {
  pathname: `${ pathName }/alerts`,
  state,
  icon: alertsIcon,
  label: "Setup Alerts",
} )

export const sensorsItem = ( pathName, state = {} ) => containerItem( {
  pathname: `${ pathName }/sensors`,
  state,
  icon: sensorsIcon,
  label: "Manage Sensors",
} )

export const editCurrentItem = ( pathName, state = {} ) => containerItem( {
  pathname: `${ pathName }/edit`,
  state,
  icon: editIcon,
  label: "",
} )

export const createNewItem = ( pathName, state = {} ) => containerItem( {
  pathname: `${ pathName }/new`,
  state,
  icon: addIcon,
  label: "",
  className: style.addIcon,
} )

export const searchDataPointsItem = ( pathName, state = {} ) => containerItem( {
  pathname: `${ pathName }/datapoints`,
  state,
  icon: dataPointsIcon,
  label: "Search Data Points",
} )

class MoreContainer extends Component {
  state = {
    disabled: true,
  }

  renderItem ( item, key ) {
    return (
      <div key={key} className={style.item}>
        { item }
      </div>
    )
  }

  renderItems ( filteredItems = [] ) {
    return (
      <div className={style.items}>
        { filteredItems.map( this.renderItem.bind( this ) ) }
      </div>
    )
  }

  render () {
    const { disabled } = this.state
    const { items, store } = this.props
    const { user } = store

    const onClick = () => this.setState( { disabled: !disabled } )

    const boxOverlay = !disabled ? <div className={style.itemsContainer} /> : ""
    const filteredItems = items.filter(
      item => canAccessItem( _.get( item, "props.pathname" ), user )
    )

    return filteredItems.length > 0 ? (
      <div className={style.container} onClick={() => onClick()} >
        { boxOverlay }
        <MoreButton style={{ alignSelf: "flex-end" }} disabled={this.state.disabled} />
        { !disabled ? this.renderItems( filteredItems ) : "" }
      </div>
    ) : ""
  }
}

export default withRouter( withStore( MoreContainer ) )
