import React, { Component } from "react"
import _ from "lodash"
import { withRouter } from "utils/router"
import { withApolloClient } from "apollo"
import { withStore } from "store"
import { DeletePopup } from "components/Popup"
import { isAdmin } from "routes"
import { wrapInMutation } from "views/EditView"
import { update, buildErrorHandle, buildActions } from "utils/submit"
import { buildRenderList } from "shared/lists"
import { GET_USER_ALERTS } from "graphql/queries"
import { EDIT_USER, DELETE_USER } from "graphql/mutations"
import MoreContainer, { deleteCurrentItem } from "components/Header/MoreContainer"
import buildSchema from "./schema"

import {
  moreContainerItems,
  buildSubscribedUserAlerts,
  buildUserAlertFields,
  buildUserAlerts,
} from "./util"

const buildHeader = ( operations = [] ) => ( {
  title: "Edit User",
  activeSwitch: true,
  mutation: EDIT_USER,
  tooltip: "Inactive users won't be able to\nsign-in to the system",
  isImutable: true,
  operations,
} )

const fetchPolicy = "cache-and-network"

class User extends Component {
  state = {
    alerts: [],
  }

  delete () {
    const { row } = this.props.router.location.state
    const { id } = row
    const { client } = this.props

    client.mutate( { mutation: DELETE_USER, variables: { id } } )
      .then( () => {
        this.props.addNotification( "info", "User successfully deleted." )
        this.props.router.history.push( "/users" ) //This will throw on browser, probably 
      } )
      .catch( () => this.props.addNotification( "error", "User could not be deleted." ) )

  }

  componentDidMount () {
    const { store } = this.props

    store.fetch( "sensors" )
    this.fetchUserAlerts()
  }

  fetchUserAlerts () {
    const query = GET_USER_ALERTS
    const variables = {
      userId: this.props.router.location.state.row.id,
    }

    const callback = result => this.setState( {
      alerts: _.get( result, "data.userAlertsByUserId.nodes", [] ),
    } )

    this.props.client.query( {
      query,
      variables,
      fetchPolicy: "network-only",
    } ).then( callback.bind( this ) )
  }

  render () {
    const { isDeleting = false } = this.state
    const { row } = this.props.router.location.state
    //const { goBack } = this.props.history
    const { history } = this.props.router

    const userAlerts = buildSubscribedUserAlerts( this.state.alerts, this.props.store.sensors || [] )
    const alertsFields = buildUserAlertFields( this.props.store )
    const alertsList = {
      label: "Assigned Alerts",
      render: buildRenderList( userAlerts, alertsFields, 10 ),
    }

    const lists = [
      alertsList,
    ]

    const assignAlertsState = {
      row,
      options: buildUserAlerts( this.state.alerts, this.props.store.sensors || [] ),
    }

    const deleteClick = () => {
      this.setState( { isDeleting: true } )
    }
    const deleteItem = isAdmin( this.props.store.user ) ? deleteCurrentItem( deleteClick ) : false

    const headerOperations = [
      //deleteItem,
      <MoreContainer disabled items={moreContainerItems( this.props.router.location.pathname, assignAlertsState )} />,
    ].filter( operation => operation )

    const viewProps = {
      header: buildHeader( headerOperations ),
      schema: buildSchema( this.props.store, row ),
      value: row,
      lists,
    }

    const mutationElement = wrapInMutation( {
      mutation: EDIT_USER,
      row,
      viewProps,
      buildSubmit: update( this.props, "User updated!", viewProps.schema, true ).bind( this ),
      handleError: buildErrorHandle( this.props, viewProps.schema ).bind( this ),
      buildActions: buildActions( false, history.goBack ),
    } )

    const deleteElement =
      <DeletePopup
        confirm={() => this.delete() }
        deny={() => this.setState( { isDeleting: false } ) }
      />

    return !isDeleting ? mutationElement : <div> { mutationElement } { deleteElement } </div>
  }
}

export default withApolloClient( withRouter( withStore( User ) ) )
