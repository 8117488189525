import React, { Component } from "react"
import { withRouter } from "utils/router"
import { DeletePopup } from "components/Popup"
import { withApolloClient } from "apollo"
import { withStore } from "store"
import { isOwnerAdmin } from "routes"
import { wrapInMutation } from "views/EditView"
import { update, buildErrorHandle } from "utils/submit"
import { EDIT_DEVICE, DELETE_DEVICE } from "graphql/mutations"
import buildSchema from "./readSchema"
import { buildRenderList, sensorsFields } from "shared/lists"
import { fetchSensors } from "./utils"
import MoreContainer, { sensorsItem, editCurrentItem, deleteCurrentItem } from "components/Header/MoreContainer"

const buildHeader = ( title, operations = [] ) => ( {
  title,
  activeSwitch: true,
  mutation: EDIT_DEVICE,
  tooltip: "Inactive users won't be able to\nsign-in to the system",
  operations,
} )

const fetchPolicy = "cache-and-network"

class ReadStructure extends Component {
  constructor ( props ) {
    super( props )

    this.fetchSensors = fetchSensors.bind( this )
  }

  state = {
    sensors: [],
  }

  delete () {
    const { row } = this.props.router.location.state
    const { id } = row
    const { client } = this.props

    client.mutate( { mutation: DELETE_DEVICE, variables: { id } } )
      .then( () => {
        this.props.addNotification( "info", "Structure successfully deleted." )
        this.props.router.history.push( "/structures" ) // This will throw on browser, probably.
      } )
      .catch( () => this.props.addNotification( "error", "Structure could not be deleted." ) )

  }

  componentDidMount () {
    this.fetchSensors()
  }

  render () {
    const { row } = this.props.router.location.state
    const { isDeleting = false } = this.state

    const inProduction = row.environment === "PRODUCTION"

    const sensorsList = {
      label: "Assigned Sensors",
      render: buildRenderList( this.state.sensors, sensorsFields, 10 ),
    }

    const headerOperationsItems = [
      sensorsItem( this.props.router.location.pathname, { row, sensors: this.state.sensors, dontFetch: true } ),
    ]

    const deleteClick = () => {
      this.setState( { isDeleting: true } )
    }
    const deleteItem = isOwnerAdmin( this.props.store.user ) && !inProduction ? deleteCurrentItem( deleteClick ) : false

    const editItem = editCurrentItem(
      this.props.router.location.pathname,
      { row, previousPathname: this.props.router.location.pathname }
    )

    const headerOperations = [
      deleteItem,
      editItem,
      <MoreContainer disabled items={headerOperationsItems} />,
    ].filter( operation => operation )

    const viewProps = {
      header: buildHeader( row.deviceName, headerOperations ),
      schema: buildSchema( this.props.store, row ),
      value: row,
      previousPathname: this.props.router.location.pathname,
      lists: [ sensorsList ],
    }

    const mutationElement = wrapInMutation( {
      mutation: EDIT_DEVICE,
      viewProps,
      buildSubmit: update( this.props, "Structure updated!" ).bind( this ),
      handleError: buildErrorHandle( this.props ).bind( this ),
      buildActions: () => [],
    } )

    const deleteElement =
      <DeletePopup
        confirm={() => this.delete() }
        deny={() => this.setState( { isDeleting: false } ) }
      />

    return !isDeleting ? mutationElement : <div> { mutationElement } { deleteElement } </div>
  }
}

export default withApolloClient( withRouter( withStore( ReadStructure ) ) )
