import React from "react"
import DynamicFormChild from "../DynamicFormChild"
import Select from "react-select"


export default class MultiSelect extends DynamicFormChild {
  constructor ( props, instance ) {
    super( props )

    if ( instance === undefined ) {
      return React.createElement( MultiSelect, props )
    }
  }

  addSelectAll = options => ( [
    { value: 0, label: "Select All" },
    ...options,
  ] )

  filterValuesFromOptions = ( value, options ) => {
    const filteredValue = value && value.filter(
      selected => options.some( option => option.value === selected.value )
    )

    if ( filteredValue && filteredValue.length !== value.length ) {
      this.props.onChange( filteredValue )
    }
  }

  componentDidUpdate () {
    const { filterValueFromOptions = true } = this.props

    filterValueFromOptions && this.filterValuesFromOptions( this.props.value, this.props.options )
  }

  render () {
    const options = this.props.selectAll ? this.addSelectAll( this.props.options ) : this.props.options

    const placeholderStyle = this.props.disabled ? { fontWeight: "normal", color: "#cfcfcf" } : {}

    return (
      <div
        className={this.props.container.className}
        title={this.props.title}
        style={this.props.disabled ? { cursor: "not-allowed" } : {}}
      >
        <Select
          placeholder={this.props.schema.placeholder}
          components={this.props.components}
          closeMenuOnSelect={this.props.closeOnSelect}
          hideSelectedOptions={this.props.hideSelected}
          value={this.props.value}
          onChange={this.props.onChange}
          options={options}
          isMulti
          isClearable={false}
          isDisabled={this.props.disabled}
          styles={{
            valueContainer: obj => ( {
              ...obj,
              padding: "0px 5px",
            } ),
            indicatorSeparator: obj => ( {
              ...obj,
              width: "0px",
            } ),
            dropdownIndicator: obj => ( {
              ...obj,
              padding: "0px",
            } ),
            control: obj => ( {
              ...obj,
              border: "none",
              boxShadow: "none",
              backgroundColor: "transparent",
              minHeight: "initial",
              ...placeholderStyle,
            } ),
            container: obj => ( {
              ...obj,
              width: "100%",
            } ),
            multiValueRemove: obj => ( {
              ...obj,
              display: "none",
            } ),
          }}
        />
      </div>
    )
  }
}
