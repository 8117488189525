// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZfOWcMNnD4GO2TEz74yX {\n  width: 100%;\n}\n\n.ZfOWcMNnD4GO2TEz74yX > div {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.p6Xtt3UXvMDi_zlivRkp {\n  background-color: #2f3842;\n  padding: 1px 5px;\n  font-size: 12px;\n  font-weight: bold;\n  color: #FFFFFF;\n}\n\n", "",{"version":3,"sources":["webpack://./src/views/DataView/Graph/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".root {\n  width: 100%;\n}\n\n.root > div {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.axisTitleWrapper {\n  background-color: #2f3842;\n  padding: 1px 5px;\n  font-size: 12px;\n  font-weight: bold;\n  color: #FFFFFF;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ZfOWcMNnD4GO2TEz74yX",
	"axisTitleWrapper": "p6Xtt3UXvMDi_zlivRkp"
};
export default ___CSS_LOADER_EXPORT___;
