import React, { Component } from "react"

export default class DataView extends Component {
  render () {
    const size = this.props.size || 1

    return (
      <div
        style={{
          width: `${ ( size * 8 ) }px`,
          height: `${ ( size * 8 ) }px`,
        }}
      />
    )
  }
}

