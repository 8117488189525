// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qNSJcms_8sMd5DiZ93K4 {\n  width: 100%;\n  padding-left: 30px;\n  padding-right: 30px;\n}\n\n.ySQzlwMokErV2rD_Oi_P {\n  padding-bottom: 40px;\n}\n\n.vj3Rwug0suTFtkFnCYmI {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/views/EasyView/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".main {\n  width: 100%;\n  padding-left: 30px;\n  padding-right: 30px;\n}\n\n.headerContainer {\n  padding-bottom: 40px;\n}\n\n.tableContainer {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "qNSJcms_8sMd5DiZ93K4",
	"headerContainer": "ySQzlwMokErV2rD_Oi_P",
	"tableContainer": "vj3Rwug0suTFtkFnCYmI"
};
export default ___CSS_LOADER_EXPORT___;
