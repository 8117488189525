import { orderBy } from "lodash"

export const sortData = ( data, sortFields ) => {
  const { rows } = data

  if ( sortFields.length <= 0 ) {
    return data
  }

  const sortedRows = orderBy(
    rows,
    sortFields.map( sortField => sortField.field.name ),
    sortFields.map( sortField => sortField.order ),
  )

  return {
    ...data,
    rows: sortedRows,
  }
}
