import React from "react"
//import ReactDOM from "react-dom"
import ReactDOM from "react-dom/client"
import { ApolloProvider } from "react-apollo"
import { client } from "./apollo"
import Router from "./router"
import Store from "./store"
import "./initializers"


const root = ReactDOM.createRoot(document.getElementById( "index"))
root.render(
  <ApolloProvider client={client}>
      <Store>
        <Router />
      </Store>
    </ApolloProvider>
)

/*ReactDOM.render(
  (
    <ApolloProvider client={client}>
      <Store>
        <Router />
      </Store>
  ),
  document.getElementById( "index" )
) */
