import gql from "graphql-tag"

export const LOGIN = gql`
mutation logIn($identifier: String!, $password: String!) {
  login (input: {identifier: $identifier password: $password}){
    clientMutationId
    jwtToken
  }
}`

export const CURRENT_USER = gql`
mutation currentUser ($userInput: CurrentUserInput!){
  currentUser(input: $userInput){
    user{
      firstName
      lastName
      type
      organizationRole
      image
    }
  }
}`

export const CREATE_ORGANIZATION = gql`
mutation createOrganization ($createObject: OrganizationInput!){
  createOrganization(input: {organization: $createObject}) {
    organization {
      id
      name
    }
  }
}`

export const CREATE_LOCATION = gql`
mutation createLocation ($createObject: LocationInput!){
  createLocation(input: {location: $createObject}) {
    location {
      id
      name
    }
  }
}`

export const EDIT_ORGANIZATION = gql`
mutation editOrganization ($updateObject: OrganizationPatch! $id: Int!){
  updateOrganizationById(input: {id: $id, organizationPatch: $updateObject}) {
    organization {
      id
      name
    }
  }
}`

export const EDIT_LOCATION = gql`
mutation editLocation ($updateObject: LocationPatch! $id: Int!){
  updateLocationById(input: {id: $id, locationPatch: $updateObject}) {
    location {
      id
      name
    }
  }
}`

export const EDIT_USER = gql`
mutation editUser ($updateObject: UpdateUserInput!){
  updateUser(input: $updateObject) {
    user {
      id
      isActive
      firstName
      lastName
      username
      email
      phone
      type
      organizationRole
      organizationId
      image
    }
  }
}`

export const CREATE_USER = gql`
mutation createUser ($createObject: CreateUserInput!){
  createUser(input: $createObject) {
    user {
      id
    }
  }
}`

export const EDIT_USER_ALERTS = gql`
mutation updateAlerts ($updateObject: [UserAlertEntryInput]!){
  batchUpdateUserAlerts(input: {inputs: $updateObject}){
    userAlertEntries{
      userId
      channelId
      alertTypeId
      isSubscribed
    }
  }
}`

export const CREATE_DEVICE = gql`
mutation createDevice ($createObject: CreateDeviceInput!){
  createDevice(input: $createObject) {
    device {
      id
    }
  }
}`

export const EDIT_DEVICE = gql`
mutation editDevice ($updateObject: DevicePatch! $id: Int!){
  updateDeviceById(input: {id: $id, devicePatch: $updateObject}) {
    device {
      id
      environment
      deviceName
      description
      image
      macAddress
      isActive
      ipAddress
      macAddress
      phoneNumber
      imageDescription
      serialNo
      sid
      softwareVersion
      firmwareVersion
      modemTypeId
      locationId
      communicationsCarrierId
      deviceTypeId
    }
  }
}`

export const EDIT_SENSOR = gql`
mutation editSensor ($updateObject: SensorPatch! $id: Int!){
  updateSensorById(input: {id: $id, sensorPatch: $updateObject}) {
    sensor {
      id
      name
    }
  }
}`

export const REQUEST_DEVICE_READING = gql`
mutation requestDeviceReading ($deviceId: Int!) {
  requestDeviceReading(input: {deviceId: $deviceId}){
    reading {
      id
      deviceId
      createdAt
    }
  }
}`

export const EDIT_CHANNEL = gql`
mutation editChannel ($updateObject: ChannelPatch! $id: Int!){
  updateChannelById(input: {id: $id, channelPatch: $updateObject}) {
    channel {
      id
    }
  }
}`

export const DELETE_DEVICE = gql`
mutation deleteDevice($id: Int!) {
  deleteDeviceById(input: {id: $id}){
    deletedDeviceId
  }
}`

export const DELETE_USER = gql`
mutation deleteUser($id: Int!) {
  deleteUserById(input: {id: $id}){
    deletedApplicationUserId
  }
}`

export const UPDATE_CALIBRATION_ENTRIES = gql`
mutation replaceCalibrationEntries($updateObject: UpdateSensorCalibrationTableInput!) {
  updateSensorCalibrationTable(input: $updateObject) {
    boolean
  }
}`

export const RECOVER_PASSWORD = gql`
mutation recoverPassword($identifier: String!){
  recoverPassword(input: {identifier: $identifier}) {
    boolean
  }
}`

export const DELETE_DATA_POINTS = gql`
mutation removeDataPoints($inputObject: RemoveDataPointsInput!) {
  removeDataPoints(input: $inputObject){
    bigInt
  }
}`

export const SETUP_PASSWORD = gql`
mutation setupPassword($token: UUID!, $newPassword: String!){
  setupPassword(input: {
    token: $token,
    newPassword: $newPassword
  }) {
    boolean
  }
}`
