import React from "react"
import DynamicFormChild from "../DynamicFormChild"
import _ from "lodash"

class Presentator extends DynamicFormChild {
  constructor ( props, instance ) {
    super( props )

    this.validateProps( props )

    if ( instance === undefined ) {
      return React.createElement( Presentator, props )
    }
  }

  validateProps ( props ) {
    if ( !props.schema ) {
      throw new Error( "Presentator must have a schema" )
    }

    if ( !props.passThrough && !_.every( props.schema.children, child => child.props.israwjsx || !!child.props.schema.name ) ) {
      throw new Error(
        "Presentator is not passThrough. Every child must either have a name or be a simple jsx element having a \"israwjsx\" prop."
      )
    }
  }

  getChildOnChange = childName => {
    const { onChange, passThrough, value } = this.props

    if ( passThrough ) {
      return onChange
    }

    const childOnChangeFn = newValue => onChange( {
      ...value,
      [ childName ]: newValue,
    } )

    return childOnChangeFn.bind( this )
  }

  getChildValue = childName => {
    const { passThrough } = this.props
    const value = this.props.value || {}

    if ( passThrough ) {
      return value
    }

    return value[ childName ]
  }

  render () {
    const { schema, errors } = this.props

    const { direction, children, style, className } = schema
    const flex = direction ? { display: "flex", flexDirection: direction, width: "100%" } : false

    const presentatorStyle = {
      ... flex ? flex : {},
      ...style,
    }

    return (
      <div className={className} style={presentatorStyle}>
        { children.map( ( Child, index ) => {
          const { israwjsx } = Child.props
          if ( israwjsx ) {
            return { ...Child, key: index }
          }

          const { name } = Child.props.schema

          return {
            ...Child,
            key: name || index,
            props: {
              ...Child.props,
              onChange: this.getChildOnChange( name ),
              value: this.getChildValue( name ),
              errors,
            },
          }
        } ) }
      </div>
    )
  }
}

export default Presentator
