import style from "shared/style.css"
import Presentator from "components/DynamicForm/Presentator"
import Field from "components/DynamicForm/Field"
import ImageField from "components/DynamicForm/ImageField"
import { triggerLoadIfNeeded } from "store"

export default ( store, row ) => {
  const {
    organizations = [],
    types = [],
    roles = [],
    alertTypes = [],
    channels = [],
  } = store

  triggerLoadIfNeeded( { organizations, alertTypes, channels }, store.fetch, row )

  const commonStringDefinition = {
    fieldType: "string",
    className: style.field,
    container: { className: style.fieldContainer },
  }

  const image = new ImageField( {
    schema: {
      name: "image",
      multiple: false,
      className: style.imageContainer,
    },
  } )

  const firstName = new Field( {
    required: true,
    schema: {
      name: "firstName",
      label: "First Name",
      ...commonStringDefinition,
    },
  } )

  const lastName = new Field( {
    required: true,
    schema: {
      name: "lastName",
      label: "Last Name",
      ...commonStringDefinition,
    },
  } )

  const username = new Field( {
    required: true,
    schema: {
      name: "username",
      label: "Username",
      autocomplete: "new-password",
      ...commonStringDefinition,
    },
  } )

  const phone = new Field( {
    schema: {
      name: "phone",
      label: "Phone",
      ...commonStringDefinition,
    },
  } )

  const password = new Field( {
    schema: {
      name: "password",
      label: "Password",
      autocomplete: "new-password",
      ...commonStringDefinition,
      fieldType: "password",
    },
  } )

  const repeatPassword = new Field( {
    schema: {
      name: "confirmPassword",
      label: "Confirm Password",
      ...commonStringDefinition,
      fieldType: "password",
    },
  } )

  const email = new Field( {
    required: true,
    schema: {
      name: "email",
      label: "Email",
      dataType: "email_address",
      ...commonStringDefinition,
    },
  } )

  const secondColumn = new Presentator( {
    schema: {
      className: style.presentator,
      children: [ firstName, lastName, username, password ],
    },
  } )

  const clientType = { value: "LIFE_SPAN_CLIENT", label: "Lifespan Client" }
  const ownerType = { value: "LIFE_SPAN_OWNER", label: "Lifespan Owner" }
  const typeOptions = store.user.type === "LIFE_SPAN_CLIENT"
    ? [ clientType ]
    : [ clientType, ownerType ]

  const type = new Field( {
    required: true,
    schema: {
      name: "type",
      label: "Type",
      ...commonStringDefinition,
      fieldType: "enum",
      // options: types.map( type => ( { value: type.id, label: type.name } ) ),
      options: typeOptions,
    },
  } )

  const thirdColumn = new Presentator( {
    schema: {
      className: style.presentator,
      children: [ email, phone, type, repeatPassword ],
    },
  } )

  const imagePresentator = new Presentator( {
    schema: {
      children: [ image ],
      className: style.presentator,
    } }
  )

  const organization = new Field( {
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "organizationId",
      label: "Organization",
      ...commonStringDefinition,
      fieldType: "enum",
      options: organizations.map( org => ( { value: org.id, label: org.name } ) ),
    },
  } )

  const role = new Field( {
    required: true,
    schema: {
      name: "organizationRole",
      label: "Organization Role",
      ...commonStringDefinition,
      fieldType: "enum",
      // options: roles.map( role => ( { value: role.id, label: role.name } ) ),
      options: [
        { value: "USER", label: "User" },
        { value: "ADMINISTRATOR", label: "Administrator" },
      ],
    },
  } )

  const enumsPresentator = new Presentator( {
    direction: "row",
    schema: {
      className: style.main,
      children: [ organization, role ],
    },
  } )

  const mainPresentator = new Presentator( {
    passThrough: true,
    schema: {
      className: style.main,
      children: [
        imagePresentator,
        secondColumn,
        thirdColumn,
      ],
    },
  } )

  const schema = {
    name: "user",
    passThrough: true,
    children: [ mainPresentator, enumsPresentator ],
  }

  return schema
}
