import React from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

export const renderWithProps = ( Component, props ) => (
  <Component { ...props } />
)

export const withRouter = (BaseComponent) => {
  const Component = (props) => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams()
  
    const history = { // to be backwards compatible
      push: (path, state) => navigate(path, { state }),
      replace: (path, state) => navigate(path, { replace: true, state }),
      goBack: () => navigate(-1)
    }

    return (
      <BaseComponent
        router={{ location, history, params }}
        location={location}
        history={history}
        {...props}
      />
    );
  }

  return Component
}