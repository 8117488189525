import _ from "lodash"
import style from "shared/style.css"
import Presentator from "components/DynamicForm/Presentator"
import Field from "components/DynamicForm/Field"
import ImageField from "components/DynamicForm/ImageField"
import { triggerLoadIfNeeded } from "store"

export const unitOptions = ( units, channelTypeName ) => _.chain( units )
  .pick( channelTypeName )
  .values()
  .flatten()
  .uniqBy( "id" )
  .map( unit => ( {
    value: unit.id,
    label: `${ unit.name } (${ unit.symbol })`,
  } ) )
  .value()

export default ( store, row, isCreating = false ) => {
  const {
    carriers = [],
    deviceTypes = [],
    locations = [],
    modemTypes = [],
    units = {},
    timezones = [],
  } = store

  triggerLoadIfNeeded( {
    carriers,
    modemTypes,
    deviceTypes,
    locations,
    units,
    timezones,
  }, store.fetch, row )

  const commonStringDefinition = {
    fieldType: "string",
    className: style.field,
    container: { className: style.fieldContainer },
  }

  const image = new ImageField( {
    schema: {
      name: "image",
      multiple: false,
      className: style.imageContainer,
    },
  } )

  const structureName = new Field( {
    required: true,
    schema: {
      name: "deviceName",
      label: "Structure Name",
      ...commonStringDefinition,
    },
  } )

  const description = new Field( {
    schema: {
      name: "description",
      label: "Structure Description",
      ...commonStringDefinition,
    },
  } )

  const imageDescription = new Field( {
    schema: {
      name: "imageDescription",
      label: "Image Description",
      ...commonStringDefinition,
    },
  } )

  const serialNumber = new Field( {
    required: true,
    schema: {
      name: "serialNo",
      label: "Serial Number",
      ...commonStringDefinition,
    },
  } )

  const macAddress = new Field( {
    required: true,
    schema: {
      name: "macAddress",
      label: "Mac Address",
      dataType: "mac_address",
      ...commonStringDefinition,
    },
  } )

  const ipAddress = new Field( {
    required: true,
    schema: {
      name: "ipAddress",
      label: "IP Address",
      dataType: "ip_address",
      ...commonStringDefinition,
    },
  } )

  const timezone = new Field( {
    required: true,
    schema: {
      name: "timezone",
      label: "Timezone",
      ...commonStringDefinition,
      fieldType: "enum",
      options: timezones.map( tz => ( { value: tz.name, label: tz.name } ) ),
    },
  } )

  const phone = new Field( {
    required: true,
    schema: {
      name: "phoneNumber",
      label: "Phone",
      ...commonStringDefinition,
    },
  } )

  const firmware = new Field( {
    required: true,
    schema: {
      name: "firmwareVersion",
      label: "Firmware Version",
      ...commonStringDefinition,
    },
  } )

  const software = new Field( {
    required: true,
    schema: {
      name: "softwareVersion",
      label: "Software Version",
      ...commonStringDefinition,
    },
  } )

  const sid = new Field( {
    required: true,
    schema: {
      name: "sid",
      label: "SID",
      ...commonStringDefinition,
    },
  } )

  const imagePresentator = new Presentator( {
    schema: {
      children: [ image ],
      className: style.presentator,
    } }
  )

  const location = new Field( {
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "locationId",
      label: "Location",
      ...commonStringDefinition,
      fieldType: "enum",
      options: locations.map( loc => ( { value: loc.id, label: loc.name } ) ),
    },
  } )

  const carrier = new Field( {
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "communicationsCarrierId",
      label: "Communication Carrier",
      ...commonStringDefinition,
      fieldType: "enum",
      options: carriers.map( carrier => ( { value: carrier.id, label: carrier.name } ) ),
    },
  } )

  const environmentLabelWarning = row.environment !== "PRODUCTION"
    ? " (Setting a Structure to \"Production\" is an irreversible action!)"
    : ""

  const environment = new Field( {
    disabled: row.environment === "PRODUCTION",
    schema: {
      name: "environment",
      label: `Structure Environment ${ environmentLabelWarning }`,
      ...commonStringDefinition,
      fieldType: "enum",
      options: [
        { label: "Testing", value: "TESTING" },
        { label: "Production", value: "PRODUCTION" },
      ],
    },
  } )

  const deviceType = new Field( {
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "deviceTypeId",
      label: "Structure Type",
      ...commonStringDefinition,
      fieldType: "enum",
      options: deviceTypes.map( deviceType => ( { value: deviceType.id, label: deviceType.name } ) ),
    },
  } )

  const modemType = new Field( {
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "modemTypeId",
      label: "Modem Type",
      ...commonStringDefinition,
      fieldType: "enum",
      options: modemTypes.map( modemType => ( { value: modemType.id, label: modemType.name } ) ),
    },
  } )

  const strainSensorUnit = new Field( {
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "defaultStrainSensorUnitId",
      label: "Strain Sensor Unit",
      ...commonStringDefinition,
      fieldType: "enum",
      options: unitOptions( units, [ "Active", "Peak" ] ),
    },
  } )

  const tiltSensorUnit = new Field( {
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "defaultTiltmeterUnitId",
      label: "Tiltmeter Sensor Unit",
      ...commonStringDefinition,
      fieldType: "enum",
      options: unitOptions( units, "Tiltmeter" ),
    },
  } )

  const temperatureSensorUnit = new Field( {
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "defaultTemperatureUnitId",
      label: "Temperature Sensors Unit",
      ...commonStringDefinition,
      fieldType: "enum",
      options: unitOptions( units, "Temperature" ),
    },
  } )

  const batterySensorUnit = new Field( {
    required: true,
    schema: {
      formatOut: _.parseInt,
      name: "defaultBatteryUnitId",
      label: "Battery Sensor Unit",
      ...commonStringDefinition,
      fieldType: "enum",
      options: unitOptions( units, "Battery" ),
    },
  } )

  const secondColumn = new Presentator( {
    schema: {
      className: style.presentator,
      children: [ location, structureName, description, imageDescription, timezone ],
    },
  } )

  const thirdColumn = new Presentator( {
    schema: {
      className: style.presentator,
      children: [ serialNumber, ipAddress, macAddress, phone ],
    },
  } )

  const strainSensorCount = new Field( {
    schema: {
      name: "strainSensorCount",
      label: "Number of Strain Sensors",
      ...commonStringDefinition,
      fieldType: "number",
      defaultValue: 25,
    },
  } )

  const tiltmeterSensorCount = new Field( {
    schema: {
      name: "tiltmeterSensorCount",
      label: "Number of Tiltmeter Sensors",
      ...commonStringDefinition,
      fieldType: "number",
      defaultValue: 4,
    },
  } )

  const temperatureSensorCount = new Field( {
    schema: {
      name: "temperatureSensorCount",
      label: "Number of Temperature Sensors",
      ...commonStringDefinition,
      fieldType: "number",
      defaultValue: 4,
    },
  } )

  const batterySensorCount = new Field( {
    schema: {
      name: "batterySensorCount",
      label: "Number of Battery Sensors",
      ...commonStringDefinition,
      fieldType: "number",
      defaultValue: 1,
    },
  } )

  const versionsPresentator = new Presentator( {
    direction: "row",
    schema: {
      className: style.main,
      children: [ sid, firmware, software, deviceType ],
    },
  } )

  const enumsPresentator = new Presentator( {
    direction: "row",
    schema: {
      className: style.main,
      children: [ carrier, modemType ],
    },
  } )

  const sensorsUnitsPresentator = new Presentator( {
    direction: "row",
    schema: {
      className: style.main,
      children: [ strainSensorUnit, tiltSensorUnit, temperatureSensorUnit, batterySensorUnit ],
    },
  } )

  const sensorsCountPresentator = new Presentator( {
    direction: "row",
    schema: {
      className: style.main,
      children: [ strainSensorCount, tiltmeterSensorCount, temperatureSensorCount, batterySensorCount ],
    },
  } )

  const environmentPresentator = new Presentator( {
    direction: "row",
    schema: {
      className: style.main,
      children: [ environment ],
    },
  } )

  const mainPresentator = new Presentator( {
    passThrough: true,
    schema: {
      className: style.main,
      children: [
        imagePresentator,
        secondColumn,
        thirdColumn,
      ],
    },
  } )

  const schema = {
    name: "user",
    passThrough: true,
    children: [
      mainPresentator,
      enumsPresentator,
      versionsPresentator,
      ... isCreating ? [ sensorsUnitsPresentator ] : [],
      ... isCreating ? [ sensorsCountPresentator ] : [],
      ... !isCreating ? [ environmentPresentator ] : [],
    ],
  }

  return schema
}
