import React, { Component } from "react"
import _ from "lodash"
import moment from "moment"
import style from "./style.css"
import QueryBar from "./QueryBar"
import DeviceDataWrapper from "./DeviceDataWrapper"
import Download from "components/DataTable/download"
import Header from "components/Header"
import MoreContainer, { downloadItem, getReadingItem } from "components/Header/MoreContainer"
import { withStore } from "store"
import DeviceInfo from "./DeviceInfo"

const loaderMessages = {
  1: "Loading Graph Data",
  2: "Building Table",
}

class DataView extends Component {
  state = {
    popup: {
      open: false,
    },
    queryBar: {},
    timeFilters: undefined,
    queryBarResult: undefined,
    dataViewType: undefined,
    downloadData: undefined,
  }

  componentDidMount () {
    const { store } = this.props

    const alertTypes = _.get( store, "alertTypes" )
    if ( !alertTypes ) {
      store.fetch( "alertTypes" )
    }
  }

  onQuery = ( result, timeFilters ) => {
    const { data } = result
    if ( data ) {

      this.setState( {
        timeFilters,
        queryBarResult: data.deviceById,
      } )
    }
  }

  renderDeviceData = () => (
    <DeviceDataWrapper
      viewType={this.state.dataViewType}
      setViewType={dataViewType => this.setState( { dataViewType } )}
      timeFilters={this.state.timeFilters}
      channelTypes={this.state.queryBar.channelType}
      device={this.state.queryBarResult}
      setDownloadData={downloadData => this.setState( { downloadData } )}
    />
  )
  renderInfoZone () {
    const { structure } = this.state.queryBar

    if ( structure ) {
      return <DeviceInfo deviceId={_.parseInt( structure )} />
    }

    return (
      <div className={style.help}>
        <div className={style.helpText}>
          Please submit your search criteria to generate your results.
        </div>
      </div>
    )
  }

  downloadItemElement = downloadItem( {
    label: "Download Data",
  } )

  filenameFromFilters () {
    const { queryBar } = this.state
    const { structures = [] } = this.props.store

    const { structure = 0 } = queryBar

    let filename = "DATA"

    let sid = "Unknown"
    let datetime = ""

    structures.forEach( ( struct ) => {
      if ( struct.id === _.parseInt( structure ) ) {
        sid = struct.sid
      }
    } )

    filename += `-${ sid }`

    const toMurican = timestamp => moment( timestamp ).format( "M-D-Y h mm" )
    datetime = `${ toMurican( new Date().getTime() ) }`

    filename += `-${ datetime }`

    return filename
  }

  downloadData = () => ( {
    filename: this.filenameFromFilters(),
    name: "Data",
    tableData: this.state.downloadData,
    element: this.downloadItemElement,
  } )

  isNewDeviceSelected = () =>
    _.parseInt( this.state.queryBar.structure ) !== _.get( this.state.queryBarResult, "id" )

  onGetReading = () => {
    const { structure } = this.state.queryBar

    this.setState( {
      popup: {
        open: true,
        deviceId: _.parseInt( structure ),
      },
    } )
  }

  render () {
    const download = <Download { ...this.downloadData() } />
    const getReading = this.state.queryBar.structure ? getReadingItem( this.onGetReading.bind( this ) ) : false

    const headerOperations = [
      getReading,
      <MoreContainer items={[ download ]} />,
    ].filter( operation => operation )

    return (
      <div className={style.root} >
        <div className={style.queryBarWrapper}>
          <Header title="Data View" operations={headerOperations} />
          <QueryBar
            onChange={newValue => this.setState( {
              queryBar: newValue,
              queryBarResult: undefined,
            } )}
            value={this.state.queryBar}
            onQuery={this.onQuery}
            onGetReading={this.onGetReading.bind( this )}
            onPopupQuit={() => this.setState( {
              popup: {
                open: false,
              },
            } )}
            popup={this.state.popup}
          />
        </div>
        <div className={[ style.resultsView, style.innerMargin ].join( " " )}>
          {this.state.queryBarResult && !this.isNewDeviceSelected() ?
            this.renderDeviceData() :
            this.renderInfoZone()}
        </div>
      </div>
    )
  }
}

export default withStore( DataView )
