import style from "./style.css"
import Presentator from "components/DynamicForm/Presentator"
import Field from "components/DynamicForm/Field"
import Option from "components/DynamicForm/MultiSelect/Option"
import MultiValueContainer from "components/DynamicForm/MultiSelect/MultiValueContainer"
import MultiSelect from "components/DynamicForm/MultiSelect"
import DateTimePicker from "components/DynamicForm/DateTimePicker"
import { styledHelpers } from "utils/schema"

const { presentator } = styledHelpers( style )

const multiSelectComponents = {
  Option,
  MultiValueContainer,
}

const commonContainer = {
  container: { className: style.fieldContainer },
  className: style.field,
}

const commonMultiSelectContainer = {
  container: { className: [ style.fieldContainer, style.multiSelectContainer ].join( " " ) },
  className: style.field,
}

const commonMultiSelect = {
  components: multiSelectComponents,
  selectAll: true,
  hideSelected: false,
  closeOnSelect: false,
  ...commonMultiSelectContainer,
}

const topRow = ( {
  structures,
  channelTypes,
  channelTypesDisabled,
  sensors,
  sensorsDisabled,
} ) => [
  new Field( {
    schema: {
      name: "structure",
      placeholder: "Structures",
      fieldType: "enum",
      options: structures,
      ...commonContainer,
    },
  } ),
  new MultiSelect( {
    options: channelTypes,
    ...commonMultiSelect,
    schema: {
      name: "channelType",
      placeholder: "Measurement Type",
    },
    disabled: channelTypesDisabled,
    title: channelTypesDisabled ? "Must Select a Structure First" : "",
  } ),
  new MultiSelect( {
    options: sensors,
    ...commonMultiSelect,
    schema: {
      name: "sensor",
      placeholder: "Sensors",
    },
    disabled: sensorsDisabled,
    title: sensorsDisabled ? "Must Select Measurement Type First" : "",
  } ),
]

const dateRangeOptions = [
  { value: 1, label: "Last 24 hours" },
  { value: 2, label: "Last Week" },
  { value: 3, label: "Last Month" },
  { value: 4, label: "Date Range" },
]

const bottomRow = ( { dateRangeFieldsDisabled, dateRangeDisabled }, actions ) => [
  new Field( {
    schema: {
      fieldType: "enum",
      placeholder: "Date Range",
      options: dateRangeOptions,
      name: "dateRangeType",
      ...commonContainer,
    },
    disabled: dateRangeDisabled,
    title: dateRangeDisabled ? "Must Select Sensors First" : "",
  } ),
  new DateTimePicker( {
    schema: {
      name: "startDate",
      defaultValue: { hour: 12, minute: 0, period: "am" },
      extraChangeMutation: momentValue => momentValue.startOf( "day" ),
      label: "Start Date / Time",
      ...commonContainer,
    },
    disabled: dateRangeFieldsDisabled,
    title: dateRangeFieldsDisabled ? "Must Select \"Date Range\" First" : "",
  } ),
  new DateTimePicker( {
    schema: {
      name: "endDate",
      defaultValue: { hour: 11, minute: 59, period: "pm" },
      extraChangeMutation: momentValue => momentValue.endOf( "day" ),
      label: "End Date / Time",
      ...commonContainer,
    },
    disabled: dateRangeFieldsDisabled,
    title: dateRangeFieldsDisabled ? "Must Select \"Date Range\" First" : "",
  } ),
  actions,
]

const schema = ( queryData, actions ) => ( {
  name: "query-bar",
  children: [
    new Presentator( {
      passThrough: true,
      schema: presentator( "column", [
        new Presentator( {
          schema: presentator( "row", topRow( queryData ) ),
        } ),
        new Presentator( {
          schema: presentator( "row", bottomRow( queryData, actions ) ),
        } ),
      ] ),
    } ),
  ],
} )

export default schema
