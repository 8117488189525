import React, { Component } from "react"
import Svg from "components/Svg"
import style from "./style.css"
import checkIcon from "images/symbols/check.svg"

class Option extends Component {
  render () {
    const { data, options, isSelected, innerProps, selectProps, setValue } = this.props
    const { value, label } = data

    const { onClick } = innerProps

    const isSelectAll = value === 0
    const selectedValues = selectProps.value
    const isDeselecting = selectedValues && selectedValues.length === options.length - 1

    const selectAllOnClick = () => setValue( isDeselecting ? [] : options )

    const checkStyle = {
      backgroundColor: isSelected || ( isSelectAll && isDeselecting ) ? "#2f3842" : "#ffffff",
    }

    return (
      <div className={style.main} onClick={ isSelectAll ? selectAllOnClick : onClick }>
        <div className={style.check} style={checkStyle} >
          <Svg html={checkIcon} />
        </div>
        <div className={style.label}>
          {label}
        </div>
      </div>
    )
  }
}

export default Option
