import React, { Component } from "react"
import componentStyle from "./style.css"
import Svg from "components/Svg"

import GraphSel from "images/buttons/graph_sel.svg"
import GraphUns from "images/buttons/graph_uns.svg"

import ListSel from "images/buttons/list_sel.svg"
import ListUns from "images/buttons/list_uns.svg"

import MoreSel from "images/buttons/more_sel.svg"
import MoreUns from "images/buttons/more_uns.svg"

import Check from "images/tables/check.svg"
import Uncheck from "images/tables/uncheck.svg"

const buttonFactory = ( onSvg, offSvg ) => (
  class ToggleButton extends Component {
    render () {
      const isDisabled = this.props.disabled
      return (
        <Svg
          className={componentStyle.toggle}
          html={( isDisabled ) ? offSvg : onSvg}
          { ...this.props }
        />
      )
    }
  }
)

export const GraphButton = buttonFactory( GraphSel, GraphUns )
export const ListButton = buttonFactory( ListSel, ListUns )
export const MoreButton = buttonFactory( MoreSel, MoreUns )
export const Checkbox = buttonFactory( Check, Uncheck )
