// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MzGBdtKhtGA1qsTsIbhs {\n  text-align: center;\n  opacity: 1 !important;\n  flex: 1;\n  align-self: center;\n}\n\n._xT4iLaUH4zsx45Q1nQQ {\n  animation: _xT4iLaUH4zsx45Q1nQQ infinite 1.5s linear;\n  height: 80px;\n}\n\n@keyframes _xT4iLaUH4zsx45Q1nQQ {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.XxJ9qbX0cqCAKHrWBCHX {\n  font-weight: 600;\n  color: #231f20;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Spinner/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,OAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,oDAAoC;EACpC,YAAY;AACd;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".spinnerContainer {\n  text-align: center;\n  opacity: 1 !important;\n  flex: 1;\n  align-self: center;\n}\n\n.spin {\n  animation: spin infinite 1.5s linear;\n  height: 80px;\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.info {\n  font-weight: 600;\n  color: #231f20;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerContainer": "MzGBdtKhtGA1qsTsIbhs",
	"spin": "_xT4iLaUH4zsx45Q1nQQ",
	"info": "XxJ9qbX0cqCAKHrWBCHX"
};
export default ___CSS_LOADER_EXPORT___;
