import _ from "lodash"
import { setupAlertsItem } from "components/Header/MoreContainer"
import { userAlertsFields } from "shared/lists"
import {
  transformAlertType,
  transformAlertDescription,
} from "shared/transforms"

export const moreContainerItems = ( pathName, assignAlertsState ) => ( [
  setupAlertsItem( pathName, assignAlertsState ),
] )

export const buildUserAlertFields = store => userAlertsFields( {
  alarmNameTransform: transformAlertType( store ),
  alarmDescriptionTransform: transformAlertDescription( store ),
} )

const channelsByAlertTypeIdReducer = ( acc, alert ) => {
  const exists = acc[ alert.alertTypeId ]

  return {
    ...acc,
    [ alert.alertTypeId ]: exists
      ? [ ...acc[ alert.alertTypeId ], alert.channelId ]
      : [ alert.channelId ],
  }
}

export const buildUserAlerts = ( userAlerts, sensors ) => {
  if ( _.isEmpty( userAlerts ) || _.isEmpty( sensors ) ) {
    return []
  }

  const subscribedUserAlerts = userAlerts.filter( alert => alert.isSubscribed )

  const alertTypesIds = userAlerts.reduce( ( acc, alert ) => {
    const { alertTypeId } = alert
    if ( !_.includes( acc, alertTypeId ) ) {
      return [
        ...acc,
        alertTypeId,
      ]
    }

    return [ ...acc ]
  }, [] )

  const subscribedChannelsByAlertTypeId = subscribedUserAlerts.reduce ( channelsByAlertTypeIdReducer, {} )
  const unsubscribedChannelsByAlertTypeId = userAlerts.reduce ( channelsByAlertTypeIdReducer, {} )

  let tableEntries = []

  sensors.forEach( sensor => {
    const sensorChannels = _.get( sensor, "channelsBySensorId.nodes", [] )

    const sensorChannelsIds = sensorChannels.map( channel => [ channel.id ] ).flatten()

    alertTypesIds.forEach( alertTypeId => {
      const alertTypeSubscribedChannels = subscribedChannelsByAlertTypeId[ alertTypeId ]
      const alertTypeUnsubscribedChannels = unsubscribedChannelsByAlertTypeId[ alertTypeId ]

      const currentSensorUnsubscribedChannelsForAlertType = sensorChannelsIds.filter(
        channelId => _.includes( alertTypeUnsubscribedChannels, channelId )
      )

      const currentSensorSubscribedChannelsForAlertType = sensorChannelsIds.filter(
        channelId => _.includes( alertTypeSubscribedChannels, channelId )
      )

      const isSubscribed = currentSensorSubscribedChannelsForAlertType.length === sensorChannelsIds.length
      const isUnsubscribed = currentSensorUnsubscribedChannelsForAlertType.length === sensorChannelsIds.length

      if ( isSubscribed ) {
        tableEntries = [
          ...tableEntries,
          { name: sensor.name, alertTypeId, isSubscribed: true, channelsIds: sensorChannelsIds },
        ]
      }
      else if ( isUnsubscribed ) {
        tableEntries = [
          ...tableEntries,
          { name: sensor.name, alertTypeId, isSubscribed: false, channelsIds: sensorChannelsIds },
        ]
      }

    } )
  } )

  return tableEntries
}

export const buildSubscribedUserAlerts = ( userAlerts, sensors ) => {
  if ( _.isEmpty( userAlerts ) || _.isEmpty( sensors ) ) {
    return []
  }

  const subscribedUserAlerts = userAlerts.filter( alert => alert.isSubscribed )

  const subscribedChannelsByAlertTypeId = subscribedUserAlerts.reduce ( channelsByAlertTypeIdReducer, {} )

  let tableEntries = []

  sensors.forEach( sensor => {
    const sensorChannels = _.get( sensor, "channelsBySensorId.nodes", [] )

    const sensorChannelsIds = sensorChannels.map( channel => [ channel.id ] ).flatten()

    _.map( _.keys( subscribedChannelsByAlertTypeId ), Number ).forEach( alertTypeId => {
      const alertTypeSubscribedChannels = subscribedChannelsByAlertTypeId[ alertTypeId ]
      const currentSensorSubscribedChannelsForAlertType = sensorChannelsIds.filter(
        channelId => _.includes( alertTypeSubscribedChannels, channelId )
      )

      if ( currentSensorSubscribedChannelsForAlertType.length === sensorChannelsIds.length ) {
        tableEntries = [
          ...tableEntries,
          { name: sensor.name, alertTypeId, isSubscribed: true },
        ]
      }
    } )
  } )

  return tableEntries
}
