import React, { Component } from "react"
import _ from "lodash"
import style from "./style.css"
import { times } from "lodash"

class Pagination extends Component {

  renderPageButton ( pageNumber, currentPage ) {
    const { change } = this.props.pagination

    const onClick = page => {
      if ( page !== currentPage ) {
        change( page )
      }
    }

    const selectedStyle = { backgroundColor: "#2f3842", color: "white" }
    const selected = pageNumber === ( currentPage || 1 ) ? selectedStyle : {}

    return (
      <div
        key={pageNumber}
        className={style.pageButtonContainer}
        style={selected}
        onClick={ () => onClick( pageNumber ) }
      >
        { pageNumber }
      </div>
    )
  }

  buildPageArray ( current, pages ) {
    const first = Math.max( current - 2, 1 )
    const last = Math.min( pages, first + 4 )

    return _.range( first, last + 1 )
  }

  render () {
    const { current, pages, back, next } = this.props.pagination
    const pageArray = pages > 1 ? this.buildPageArray( current, pages ) : []
    const isEmptyPageArray = pageArray.length <= 0

    const arrow = ( str, direction, func ) => (
      <div className={style.arrow} style={{ [ `padding${ direction }` ]: "15px" }} onClick={ () => func() }>{str}</div>
    )

    const firstArrow = () => arrow( "<<", "Right", () => this.props.pagination.change( 1 ) )
    const lastArrow = () => arrow( ">>", "Left", () => this.props.pagination.change( pages ) )
    const backArrow = () => arrow( "<", "Right", back )
    const nextArrow = () => arrow( ">", "Left", next )

    return (
      <div className={style.paginationContainer}>
        { !isEmptyPageArray ? firstArrow() : "" }
        { !isEmptyPageArray ? backArrow() : "" }
        <div className={style.pages}>
          { pageArray.map( page => this.renderPageButton( page, current ) ) }
        </div>
        { !isEmptyPageArray ? nextArrow() : "" }
        { !isEmptyPageArray ? lastArrow() : "" }
      </div>
    )
  }
}

export default Pagination
