import React, { Component } from "react"
import moment from "moment"
import { Query } from "react-apollo"
import style from "./style.css"
import DynamicForm from "components/DynamicForm"
import queryBarSchema from "./queryBarSchema.js"
import GetReadingPopup from "views/GetReadingPopup"
import _ from "lodash"
import { withApolloClient } from "apollo"
import { GET_DATA_VIEW_STRUCTURES, GET_DEVICE_CHANNELS } from "graphql/queries"
import { withStore, triggerLoadIfNeeded } from "store"

const fetchPolicy = "cache-and-network"

const subtractDate = ( time, unit ) => moment().subtract( time, unit ).startOf("day").unix() * 1000
const timeIntervalUntilNow = startDate => ( {
  startDate,
  endDate: moment().endOf("day").unix() * 1000,
} )

const timeFiltersMap = {
  1: () => timeIntervalUntilNow( subtractDate( 1, "days" ) ),
  2: () => timeIntervalUntilNow( subtractDate( 7, "days" ) ),
  3: () => timeIntervalUntilNow( subtractDate( 1, "months" ) ),
}

export const timeFiltersToIso = ( { startDate, endDate } ) => ( {
  startDate: moment( startDate ).format(),
  endDate: moment( endDate ).format(),
} )

const dateRangeValues = ( formData, field ) => {
  const { dateRangeType } = formData

  const fieldValue = _.parseInt( dateRangeType ) === 4
    ? formData[ field ]
    : timeFiltersMap[ dateRangeType ]()[ field ]

  return fieldValue
}

const fieldsToReset = {
  structure: {
    channelType: [],
    sensor: [],
  },
  dateRangeType: {
    startDate: formData => dateRangeValues( formData, "startDate" ),
    endDate: formData => dateRangeValues( formData, "endDate" ),
  },
}

class QueryBar extends Component {
  componentDidMount () {
    triggerLoadIfNeeded( { structures: [] }, this.props.store.fetch )
  }

  shouldComponentUpdate ( nextProps, nextState ) {
    return !_.isEqual( nextProps.value, this.props.value ) ||
      !_.isEqual( nextProps.popup, this.props.popup ) ||
      !_.isEqual( this.state, nextState )
  }

  buildSchemaDataObject ( value, queryData ) {
    const allStructures = queryData.allDevices.nodes
    const structures = allStructures.map( structure => ( {
      value: structure.id,
      label: structure.description || structure.deviceName,
    } ) )

    const selectedStructures = _.filter( allStructures, { id: _.parseInt( value.structure ) } )


    // Unique ChannelTypes in the selected structure
    const channelTypes = _.uniqBy(
      selectedStructures
        // Get all the channelTypes from selected structures
        .flatMap( structure => {
          return structure.sensorsByDeviceId.nodes.flatMap( sensor => {
            return sensor.channelsBySensorId.nodes.flatMap( channel => {
              return channel.channelTypeByChannelTypeId
            } )
          } )
        } )
        // Transfrom the data
        .map ( channelType => ( {
          value: channelType.id,
          label: channelType.name,
        } ) ),
      "value"
    )

    const selectedChannelTypes = value.channelType || []

    const sensors = selectedStructures
      // Grab the sensors
      .flatMap( structure => structure.sensorsByDeviceId.nodes )
      // Filter by sensors that have channels with the correct channel type
      .filter( sensor => sensor.channelsBySensorId.nodes
        .filter( channel => !!selectedChannelTypes.find(
          sct => sct.value === channel.channelTypeByChannelTypeId.id
        ) )
        .length !== 0
      )
      // Format data for use in multiselect
      .map( sensor => ( {
        value: sensor.id,
        label: sensor.name,
      } ) )


    const { dateRangeType } = value
    // Disable date fields if date range isn't manual
    const dateRangeFieldsDisabled = !dateRangeType || _.parseInt( dateRangeType ) !== 4

    const dateRangeDisabled = !( _.get( value, "sensor", [] ).length > 0 )

    return {
      structures,
      channelTypes,
      channelTypesDisabled: selectedStructures.length === 0,
      sensors,
      sensorsDisabled: selectedChannelTypes.length === 0,
      dateRangeFieldsDisabled,
      dateRangeDisabled,
    }
  }

  onQuery = ( data, formData ) => {
    const { client } = this.props
    const { structure, sensor, startDate, endDate } = formData

    const variables = {
      deviceId: _.parseInt( structure ),
      sensorIds: sensor.flatMap( sensor => sensor.value ),
    }

    const timeFilters = timeFiltersToIso( { startDate, endDate } )

    const callBack = requestData => this.props.onQuery( requestData, timeFilters )

    client.query( { query: GET_DEVICE_CHANNELS, variables } ).then( callBack.bind( this ) )
  }

  buildActions = ( data, formData ) => {
    const { structure, channelType, sensor, startDate, endDate } = formData
    const submitDisabled = _.isEmpty( channelType ) || _.isEmpty( sensor ) || !startDate || !endDate
    const getReadingDisabled = _.isEmpty( structure )

    const sharedStyle = {
      fontSize: "12px",
      fontWeight: "900",
      textTransform: "uppercase",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 10px",
      margin: "5px",
      color: "white",
      borderRadius: "10px",
      userSelect: "none",
      cursor: "pointer",
    }
    const actions = [
      {
        label: "Submit",
        title: submitDisabled ? "Must Fill Previous Fields" : "",
        style: {
          ...sharedStyle,
          backgroundColor: submitDisabled ? "#ededed" : "#7dcb27",
          cursor: submitDisabled ? "not-allowed" : "pointer",
        },
        onClick: submitDisabled ? () => null : this.onQuery.bind( this ),
      },
    ]

    return actions.map( action => (
      <div
        key={action.label}
        title={action.title}
        onClick={() => action.onClick( data, formData )}
        style={action.style}
      >
        {action.label}
      </div>
    ) )
  }


  renderPopup () {
    const popup = _.get( this, "props.popup", {} )

    if ( !popup.open ) {
      return null
    }

    return (
      <GetReadingPopup
        deviceId={popup.deviceId}
        onQuit={this.props.onPopupQuit}
      />
    )
  }

  render () {
    return (
      <div className={[ style.root, this.props.className ].join( " " )}>
        {this.renderPopup()}
        <Query query={GET_DATA_VIEW_STRUCTURES} fetchPolicy={fetchPolicy}>
          {( { loading, error, data } ) => {
            if ( loading ) return "Loading..."
            if ( error ) return `Error Occurred: ${ error }`

            const actions = (
              <div israwjsx="true" className={style.actions}>
                {this.buildActions( data, this.props.value )}
              </div>
            )

            const schemaDataObject = this.buildSchemaDataObject( this.props.value, data )
            return (
              <div>
                <DynamicForm
                  schema={queryBarSchema( schemaDataObject, actions )}
                  value={this.props.value}
                  onChange={this.props.onChange}
                  fieldsToReset={fieldsToReset}
                />
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withApolloClient( withStore( QueryBar ) )
