import React, { Component } from "react"
import style from "./style.css"
import Carousel from "nuka-carousel"
import ContainerDimensions from "react-container-dimensions"

import Arrow from "images/sidebar/arrow.svg"
import Svg from "components/Svg"

const ArrowDirection = Object.freeze( {
  Left: "Left",
  Right: "Right",
} )

export default class SimpleCarousel extends Component {
  constructor ( props ) {
    super( props )

    console.assert( Array.isArray( props.value ) )
  }

  renderArrow = direction => ( {
    nextSlide,
    previousSlide,
  } ) => (
    <div
      onClick={direction === ArrowDirection.Left ? previousSlide : nextSlide}
      className={[
        style.arrow,
        ( direction === ArrowDirection.Left ) ? style.arrowLeft : style.arrowRight,
      ].join( " " )}
    >
      <Svg html={Arrow}/>
    </div>
  )

  render () {
    const { value } = this.props

    const renderControls = {
      renderTopLeftControls: null,
      renderTopCenterControls: null,
      renderTopRightControls: null,
      renderCenterLeftControls: this.renderArrow( ArrowDirection.Left ),
      renderCenterCenterControls: null,
      renderCenterRightControls: this.renderArrow( ArrowDirection.Right ),
      renderBottomLeftControls: null,
      renderBottomCenterControls: null,
      renderBottomRightControls: null,
    }

    return (
      <ContainerDimensions>
        { ( { width } ) => (
          <Carousel
            { ...renderControls }
            cellAlign="left"
            width={`${ width }px`}
            slidesToScroll={5}
            slidesToShow={5}
          >
            {value.map( val => (
              <div
                key={val.value}
                onClick={() => this.props.toggle( val.value ) }
                className={ val.selected
                  ? [ style.carouselElement, style.selected ].join( " " )
                  : style.carouselElement
                }
              >
                {val.label}
              </div>
            ) )}
          </Carousel>
        )}
      </ContainerDimensions>
    )
  }
}
