// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Qcfm7uXto4zVTsowjqcn {\n}\n\n.qHtHzc6WrI0NHIC_5uPD {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  width: 30px;\n  padding: 20px 0px;\n  background: linear-gradient(to right, #ffffff ,#fffffffa, #ffffffed, #ffffffd4, #ffffffbd, #ffffffa8, #ffffff70, #ffffff05);\n}\n\n.pWnDM1vcAhZOdKbkFM6s {\n  justify-content: flex-start;\n  padding-right: 35px;\n}\n\n.jgAwqId4MQ9nziNit6RG {\n  transform: rotate(180deg);\n}\n\n.xOo4lvjd4MkNK8MPJMqA {\n  display: flex;\n  justify-content: center;\n  width: fit-content;\n  padding: 5px 10px;\n  margin-top: auto;\n  margin-bottom: auto;\n  font-weight: 500;\n  border-radius: 4px;\n}\n\n.ZTr5lueZVPlqFN2eN1cn {\n  background-color: #2f3842;\n  color: #ffffff;\n  font-weight: 600;\n}", "",{"version":3,"sources":["webpack://./src/components/Carousel/style.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,2HAA2H;AAC7H;;AAEA;EACE,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".root {\n}\n\n.arrow {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  width: 30px;\n  padding: 20px 0px;\n  background: linear-gradient(to right, #ffffff ,#fffffffa, #ffffffed, #ffffffd4, #ffffffbd, #ffffffa8, #ffffff70, #ffffff05);\n}\n\n.arrowLeft {\n  justify-content: flex-start;\n  padding-right: 35px;\n}\n\n.arrowRight {\n  transform: rotate(180deg);\n}\n\n.carouselElement {\n  display: flex;\n  justify-content: center;\n  width: fit-content;\n  padding: 5px 10px;\n  margin-top: auto;\n  margin-bottom: auto;\n  font-weight: 500;\n  border-radius: 4px;\n}\n\n.selected {\n  background-color: #2f3842;\n  color: #ffffff;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Qcfm7uXto4zVTsowjqcn",
	"arrow": "qHtHzc6WrI0NHIC_5uPD",
	"arrowLeft": "pWnDM1vcAhZOdKbkFM6s",
	"arrowRight": "jgAwqId4MQ9nziNit6RG",
	"carouselElement": "xOo4lvjd4MkNK8MPJMqA",
	"selected": "ZTr5lueZVPlqFN2eN1cn"
};
export default ___CSS_LOADER_EXPORT___;
