import React, { Component } from "react"
import _ from "lodash"
import { withRouter } from "utils/router"
import { withStore } from "store"
import { withApolloClient } from "apollo"
import { timeFiltersToIso } from "views/DataView/QueryBar"
import { deleteCurrentItem } from "components/Header/MoreContainer"
import Spinner from "components/Spinner"
import Header from "components/Header"
import DynamicForm from "components/DynamicForm"
import Presentator from "components/DynamicForm/Presentator"
import DateTimePicker from "components/DynamicForm/DateTimePicker"
import DataTableListInput from "components/DynamicForm/DataTableListInput"
import dataViewStyle from "views/DataView/style.css"
import sharedStyle from "shared/style.css"
import { GET_SENSORS_DATA_POINTS } from "graphql/queries"
import { DELETE_DATA_POINTS } from "graphql/mutations"
import style from "views/DataView/QueryBar/style.css"

const fetchPolicy = "network-only"

const commonContainer = {
  container: { className: style.fieldContainer },
  className: style.field,
}

class QueryBar extends Component {
  buildSchema ( value ) {
    const start = new DateTimePicker( {
      schema: {
        name: "startDate",
        defaultValue: { hour: 12, minute: 0, period: "am" },
        label: "Start Date / Time",
        ...commonContainer,
      },
    } )

    const end = new DateTimePicker( {
      schema: {
        name: "endDate",
        defaultValue: { hour: 12, minute: 0, period: "am" },
        label: "End Date / Time",
        ...commonContainer,
      },
    } )

    const { startDate, endDate } = value

    const submitDisabled = !startDate || !endDate
    const submitStyle = {
      backgroundColor: submitDisabled ? "#ededed" : "#2f3842",
      cursor: submitDisabled ? "not-allowed" : "pointer",
    }

    const submit = (
      <div
        israwjsx="true"
        title={"Search"}
        onClick={() => this.props.submit()}
        className={sharedStyle.action}
        style={submitStyle}
      >Search</div>
    )

    return {
      name: "query-bar",
      className: sharedStyle.presentator,
      children: [
        new Presentator( {
          schema: {
            direction: "row",
            className: sharedStyle.presentator,
            children: [ start, end, submit ],
          },
        } ),
      ],
    }

  }

  render () {
    const schema = this.buildSchema( this.props.value )

    const form = (
      <div style={{ width: "50%" }}>
        <DynamicForm
          schema={schema}
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </div>
    )

    return (
      <div className={sharedStyle.main}>
        { form }
      </div>
    )
  }
}

const fields = [
  { displayName: "Sensor Name", name: "sensorName", type: "string", isSearchable: true },
  { displayName: "Channel Name", name: "channelName", type: "string", isSearchable: true },
  { displayName: "Available Points", name: "points", type: "int" },
]

class DataPointsTable extends Component {
  render () {
    const tableProps = {
      addVirtualIds: true,
      schema: {
        name: "userAlerts",
        selectorField: { name: "isSelected", label: "Select" },
        pageSize: 10,
        fieldType: "list",
        fields,
      },
    }

    const table = (
      <DataTableListInput
        value={this.props.value}
        onChange={val => this.props.onChange( val )}
        { ...tableProps }
      />
    )

    return table
  }
}

class DataPoints extends Component {
  state = {
    queryBar: {},
    timeFilters: {},
    totalPoints: 0,
    entries: null,
    loading: false,
  }

  groupMeasurementsBySensor ( measurements = [] ) {
    const channelTypes = {}

    measurements.map( measurement => {
      const sensorId = measurement.channelByChannelId.sensorBySensorId.id
      const sensorName = measurement.channelByChannelId.sensorBySensorId.name

      const channelId = measurement.channelByChannelId.id

      const channelTypeId = measurement.channelByChannelId.channelTypeByChannelTypeId.id
      const channelTypeName = measurement.channelByChannelId.channelTypeByChannelTypeId.name

      const currentChannelType = channelTypes[ channelTypeId ]

      const newSensorObject = {
        name: sensorName,
        points: 1,
        channelId,
      }

      if ( !currentChannelType ) {
        const channelTypeSensors = {
          [ sensorId ]: newSensorObject,
        }

        channelTypes[ channelTypeId ] = {
          name: channelTypeName,
          id: channelTypeId,
          sensors: channelTypeSensors,
        }
      }
      else {
        if ( !currentChannelType.sensors[ sensorId ] ) {
          currentChannelType.sensors[ sensorId ] = newSensorObject
        }
        else {
          currentChannelType.sensors[ sensorId ].points += 1
        }
      }
    } )

    let totalPoints = 0
    const entries = _.keys( channelTypes ).map ( channelTypeId => {
      const channelType = channelTypes[ channelTypeId ]

      return _.reduce( _.keys( channelType.sensors ), ( agg, sensorId ) => {
        const entry = channelType.sensors[ sensorId ]

        totalPoints += entry.points

        return [
          ...agg,
          {
            sensorName: entry.name,
            channelId: entry.channelId,
            channelName: channelType.name,
            channelTypeId: channelType.id,
            points: entry.points,

          },
        ]
      }, [] )
    } ).flat()

    return { totalPoints, entries }
  }

  delete () {
    const { client, addNotification } = this.props
    const { entries, timeFilters } = this.state
    const { startDate, endDate } = timeFilters

    const channels = entries.filter( entry => entry.isSelected ).map( entry => entry.channelId )

    if ( !startDate || !endDate || !( channels.length > 0 ) ) {
      addNotification( "warning", "Missing parameters!" )
      return
    }

    const variables = {
      inputObject: {
        startTime: startDate,
        endTime: endDate,
        channels,
      },
    }

    const error = () => {
      this.setState( { loading: false }, () =>
        addNotification( "error", "Error occured trying to delete data points" )
      )
    }

    const callback = result => {
      const { bigInt } = result.data.removeDataPoints

      const filteredEntries = entries.filter(
        entry => !entry.isSelected && !_.includes( channels, entry.channelId )
      )

      this.setState( {
        loading: false,
        entries: filteredEntries,
      }, () => {
        const { addNotification } = this.props
        addNotification( "info", `${ bigInt } Data points deleted` )
      } )
    }

    this.setState( { loading: true }, () => {
      client.mutate( { mutation: DELETE_DATA_POINTS, variables } )
        .then( callback.bind( this ) )
        .catch( error.bind( this ) )
    } )
  }

  query () {
    const { client, addNotification } = this.props
    const { sensors = [] } = this.props.location.state

    const channelIds = _.reduce( sensors, ( agg, sensor ) => ( [
      ...agg,
      ...sensor.channelsBySensorId.nodes.map( channel => channel.id ),
    ] ), [] )

    const { startDate, endDate } = this.state.queryBar

    const timeFilters = timeFiltersToIso( { startDate, endDate } )

    const variables = {
      ...timeFilters,
      channelIds,
    }

    const callback = result => {
      const { allMeasurements } = result.data
      const { entries, totalPoints } = this.groupMeasurementsBySensor( allMeasurements.nodes )

      this.setState( { loading: false, entries, totalPoints } )
    }

    this.setState( { loading: true, timeFilters }, () => {
      client.query( { query: GET_SENSORS_DATA_POINTS, variables, fetchPolicy } )
        .then( callback.bind( this ) )
        .catch( () => {
          this.setState( { loading: false } )
          addNotification( "error", "Error fetching data points" )
        } )
    } )

  }

  renderInfo () {
    return (
      <div className={dataViewStyle.help}>
        <div className={dataViewStyle.helpText}>
          Please submit your search criteria to generate your results.
        </div>
      </div>
    )
  }

  render () {
    const tableOnChange = updatedEntries => {

      this.setState( { entries: updatedEntries } )
    }

    const table = this.state.entries ? (
      <div className={sharedStyle.root}>
        <div className={sharedStyle.labelField}>
          <div className={sharedStyle.main}>
            <div>{ `${ this.state.totalPoints } points found` }</div>
            <div style={{
              display: "flex",
              alignItems: "center",
            }}>
              <div style={{ paddingRight: "10px", fontWeight: "normal" }} >Delete selected points</div>
              { deleteCurrentItem( this.delete.bind( this ) ) }
            </div>
          </div>
        </div>
        <DataPointsTable
          onChange={tableOnChange.bind( this )}
          value={this.state.entries}
        />
      </div>
    ) : this.renderInfo()

    return (
      <div className={sharedStyle.view}>
        <div className={sharedStyle.wrapper}>
          <Header title={"Search Data Points"} />
          <QueryBar
            value={this.state.queryBar}
            onChange={value => this.setState( { queryBar: value } )}
            submit={this.query.bind( this )}
          />
        </div>
        <div className={[ dataViewStyle.resultsView, dataViewStyle.innerMargin ].join( " " )}>
          { this.state.loading ? <Spinner loading /> : table }
        </div>
      </div>
    )
  }
}

export default withStore( withApolloClient( withRouter( DataPoints ) ) )