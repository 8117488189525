import gql from "graphql-tag"

export const GET_ORGANIZATIONS = gql`
query organizations{
  allOrganizations{
    nodes{
      id
      name
      description
      email
      address1
      address2
      address3
      phone
      fax
      state
      city
      zipCode
      countryId
    }
  }
}`

export const GET_LOCATIONS = gql`
query locations{
  allLocations{
    nodes{
      id
      name
      description
      address1
      address2
      address3
      state
      city
      zipCode
      isActive
      countryId
      organizationId
      organizationByOrganizationId{
        name
      }
    }
  }
}`

export const GET_USERS = gql`
query users {
  allUsers {
    nodes {
      id
      isActive
      firstName
      lastName
      username
      email
      phone
      type
      organizationRole
      organizationId
      image
    }
  }
}`

export const GET_USERS_LOGINS = gql`
query userLogs{
  allUserLogs(filter: { typeId: { equalTo: 1 } }, orderBy: LOG_TIME_DESC){
    nodes{
        id
        toId: userByUserId {
            id
            firstName
          	lastName
        }
        logTime
    }
  }
}`

export const GET_DATA_VIEW_STRUCTURES = gql`
query dataViewStructures {
  allDevices(filter: {isActive: {equalTo: true}}){
    nodes{
      id
      description
      image
      imageDescription
      deviceName
      sensorsByDeviceId(filter: {isActive: {equalTo: true}}){
        nodes{
          id
          name
          sensorTypeBySensorTypeId{
            id
            name
          }
          channelsBySensorId{
            nodes{
              id
              channelTypeByChannelTypeId{
                id
                name
              }
            }
          }
        }
      }
    }
  }
}`

export const GET_COUNTRIES = gql`
query countries {
  allCountries {
    nodes{
      id
      name
    }
  }
}`

export const GET_TIMEZONES = gql`
query getTimezones {
  allTimezones{
    nodes{
      name
      utcOffset {
        seconds
        minutes
        hours
        days
        months
        years
      }
    }
  }
}`

export const GET_SENSORS_DATA_POINTS = gql`
query getMeasurements ($channelIds: [Int!]!,$startDate: Datetime!, $endDate:Datetime!) {
  allMeasurements(
    filter:{
      and: [
        {isActive: {equalTo: true}},
        {channelId:{in:$channelIds}},
        {deviceTimestamp:{greaterThanOrEqualTo:$startDate}},
        {deviceTimestamp:{lessThanOrEqualTo:$endDate}},
      ]
    }
  ){
    nodes{
      channelByChannelId{
        id
        channelTypeByChannelTypeId{
          id
          name
        }
        sensorBySensorId{
          id
          name
        }
      }
    }
  }
}`

export const GET_MEASUREMENTS = gql`
query getMeasurements ($channelIds: [Int!]!,$startDate: Datetime!, $endDate:Datetime!) {
  allMeasurements(
    orderBy:DEVICE_TIMESTAMP_DESC,
    filter:{
      and: [
        {isActive: {equalTo: true}},
        {channelId:{in:$channelIds}},
        {deviceTimestamp:{greaterThanOrEqualTo:$startDate}},
        {deviceTimestamp:{lessThanOrEqualTo:$endDate}},
      ]
    }
  ){
    nodes{
      channelId
      value
      alertTypeId
      deviceTimestamp
    }
  }
}`

export const COUNT_MEASUREMENTS = gql`
query countMeasurements ($channelIds: [Int!]!,$startDate: Datetime!, $endDate:Datetime!) {
  allMeasurements(
    filter:{
      and: [
        {isActive: {equalTo: true}},
        {channelId:{in:$channelIds}},
        {deviceTimestamp:{greaterThanOrEqualTo:$startDate}},
        {deviceTimestamp:{lessThanOrEqualTo:$endDate}},
      ]
    }
  ){
		totalCount
  }
}`

export const GET_DEVICE_CHANNELS = gql`
query devices ($deviceId: Int!, $sensorIds: [Int!]) {
  deviceById(id: $deviceId){
    id
    deviceName
    description
    image
    sensorsByDeviceId(filter:{id: {in: $sensorIds}}){
      nodes{
        id
        name
        description
        locationDescription
        channelsBySensorId{
          nodes{
            id
            description
            unitByUnitId{
              id
              name
              symbol
            }
            channelTypeByChannelTypeId{
              id
              name
            }
          }
        }
      }
    }
  }
}`

export const GET_ALERT_TYPES = gql`
query alertTypes {
  allAlertTypes{
    nodes{
      id
      name
    }
  }
}`


export const GET_USER_ALERTS = gql`
query userAlerts($userId: Int!){
  userAlertsByUserId(userId: $userId ){
    nodes {
      userId
      channelId
      alertTypeId
      isSubscribed
    }
  }
}`

export const GET_SENSORS = gql`
query sensors {
  allSensors{
    nodes{
      id
      name
      description
      channelsBySensorId{
        nodes{
          id
          description
        }
      }
    }
  }
}`

export const GET_LAST_READING_STATE = gql`
query getLastReadingState($readingId: Int!) {
  query {
    readingById(id: $readingId) {
      readingStatusesByReadingId(orderBy: TIMESTAMP_DESC, first: 1) {
        nodes {
          state
          timestamp
          id
        }
      }
    }
  }
}`

export const GET_CHANNELS = gql`
query channels {
  allChannels{
    nodes{
      id
      description
    }
  }
}`

export const GET_MODEM_TYPES = gql`
query modemTypes {
  allModemTypes{
    nodes{
      id
      name
    }
  }
}`

export const GET_CARRIERS = gql`
query carriers {
  allCommunicationsCarriers{
    nodes{
      id
      name
    }
  }
}`

export const GET_DEVICE_TYPES = gql`
query deviceTypes {
  allDeviceTypes{
    nodes{
      id
      name
    }
  }
}`

export const GET_STRUCTURES = gql`
query devices {
  allDevices{
    nodes{
      id
      environment
      deviceName
      description
      image
      macAddress
      isActive
      ipAddress
      macAddress
      phoneNumber
      imageDescription
      serialNo
      sid
      softwareVersion
      firmwareVersion
      modemTypeId
      locationId
      communicationsCarrierId
      deviceTypeId
      timezone
    }
  }
}`

export const GET_ORGANIZATION_USERS = gql`
query organizationUsers($id: Int!) {
  organizationById(id: $id){
    usersByOrganizationId{
      nodes{
        firstName
        lastName
        username
        organizationRole
      }
    }
  }
}`

export const GET_ORGANIZATION_LOCATIONS = gql`
query organizationUsers($id: Int!) {
  organizationById(id: $id){
    locationsByOrganizationId{
      nodes{
        name
        description
      }
    }
  }
}`

export const GET_STRUCTURE_SENSORS = gql`
query deviceUsers($id: Int!) {
  deviceById(id: $id){
  	sensorsByDeviceId{
      nodes{
        id
        name
        description
        isActive
        locationDescription
        calibrationDate
        sensorTypeId
        sensorTypeBySensorTypeId{
          name
        }
        channelsBySensorId{
          nodes{
            id
            description
            channelOffset
            conversionFactor
            unitId
            calibrationTableId
            channelTypeByChannelTypeId{
              name
            }
          }
        }
      }
    }
  }
}`

export const GET_UNITS = gql`
query units {
	allChannelTypes{
    nodes{
      name
      channelTypeUnitsByChannelTypeId{
        nodes{
          unitByUnitId{
            id
            name
            symbol
          }
        }
      }
    }
  }
}`

export const GET_SENSOR_TYPES = gql`
query sensorTypes{
  allSensorTypes{
    nodes{
      id
      name
    }
  }
}`

export const GET_DEVICE_INFO = gql`
query deviceInfo ($id: Int!) {
  deviceById(id: $id){
    id
    deviceName
    description
    image
    imageDescription
  }
}`
