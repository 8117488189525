import React from "react"
import DynamicFormChild from "../DynamicFormChild"
import { Select, Input } from "../Fields"
import fieldStyle from "./style.css"
import _ from "lodash"

class Field extends DynamicFormChild {
  constructor ( props, instance ) {
    super( props )

    if ( instance === undefined ) {
      return React.createElement( Field, props )
    }
  }

  render () {
    const { value, schema, required = false, errors = [] } = this.props
    const {
      name, label, fieldType,
      dataType, options, style,
      className, container,
      sortFn, autocomplete
    } = schema

    const inputComponentMap = {
      text: Input,
      password: Input,
      number: Input,
      select: Select,
    }

    const inputTypeMap = {
      string: "text",
      password: "password",
      enum: "select",
      number: "number",
    }

    const inputType = inputTypeMap[ fieldType ]
    if ( !inputType ) {
      console.error( new Error( `Could not find input type for ${ fieldType }` ) )
      return ""
    }

    let formatOut = schema.formatOut;
    if(inputType === "number" && !formatOut) {
      formatOut = parseFloat
    }

    const InputComponent = inputComponentMap[ inputType ]
    if ( !InputComponent ) {
      console.error( new Error( `Could not find input component for ${ inputType }` ) )
      return ""
    }

    const containerClassName = container ? container.className : ""
    const containerStyle = container ? container.style : {}

    const requiredLabel = (
      <div className={fieldStyle.required}>
        { label }
        <span>&nbsp;*</span>
      </div>
    )

    const inputClassName = dataType && errors.indexOf( dataType ) !== -1
      ? [ className, fieldStyle.error ].join( " " )
      : className

    const onChange = e => this.props.onChange( formatOut
      ? formatOut( e.target.value )
      : e.target.value
    )

    const sortedOptions = _.chain( options || [] )
      .orderBy( [ "label" ], [ "asc" ] )
      .thru( sortFn ? sortFn : x => x )
      .value()

    return (
      <div className={containerClassName} style={containerStyle}>
        { required ? requiredLabel : label }
        <div className={inputClassName} style={style}>
          <InputComponent
            placeholder={this.props.schema.placeholder}
            disabled={this.props.disabled}
            title={this.props.title}
            name={name}
            value={schema.defaultValue && !value ? schema.defaultValue : value || ""}
            type={inputType}
            onChange={onChange}
            options={sortedOptions}
            autoComplete={autocomplete || 'on'}
          />
        </div>
      </div>
    )
  }
}

export default Field
