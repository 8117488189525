import React, { Component } from "react"
import { withRouter } from "utils/router"
import { withStore } from "store"
import { withApolloClient } from "apollo"
import EasyView from "views/EasyView"
import { buildDataForTable } from "components/DataTable/DataBuilder"
import { Selector } from "components/DynamicForm/DataTableListInput"
import MoreContainer, { searchDataPointsItem } from "components/Header/MoreContainer"
import { fetchSensors } from "../utils"

const buildHeader = ( row, operations = [] ) => ( {
  title: `${ row.deviceName } Sensors`,
  operations,
} )

const renderActive = isActive => <Selector disabled={!isActive} />
const fields = [
  { name: "isActive", type: "boolean", isSortable: false, render: renderActive, ignoreLink: true, style: { flexGrow: 0 } },
  { name: "name", type: "string", displayName: "Sensor Name", isSearchable: true },
  { name: "sensorTypeBySensorTypeId", type: "string", displayName: "Sensor Type", isSearchable: true, transform: item => item.name },
  { name: "description", type: "string", displayName: "Sensor Description", isSearchable: true },
  { name: "locationDescription", type: "string", displayName: "Location Description", isSearchable: true },
]

class StructureSensors extends Component {
  constructor ( props ) {
    super( props )

    this.fetchSensors = fetchSensors.bind( this )
  }

  componentDidMount () {
    this.fetchSensors( true )
  }

  render () {
    const { row, sensors } = this.props.location.state

    const headerOperationsItems = [
      searchDataPointsItem( this.props.location.pathname, { sensors } ),
    ]

    const headerOperations = [
      <MoreContainer disabled items={headerOperationsItems} />,
    ]

    const editRoute = `${ this.props.location.pathname }/`

    const viewProps = {
      header: buildHeader( row, headerOperations ),
      editRoute,
      parentRow: { parent: row },
      value: {
        id: row.id,
      },
    }

    const sensorsData = buildDataForTable( fields, sensors )

    return (
      <EasyView {...viewProps} value={sensorsData} />
    )

  }
}

export default withRouter( withStore( withApolloClient( StructureSensors ) ) )
