import React, { Component } from "react"
import { ApolloClient } from "apollo-client"
import { createHttpLink } from "apollo-link-http"
import { onError } from "apollo-link-error"
import { setContext } from "apollo-link-context"
import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloConsumer } from "react-apollo"
import _ from "lodash"

const expiredTokenMessage = "jwt expired"

const BACKEND_URI = {
  production: "https://structure.lifespantechnologies.com/graphql",
  staging: "https://staging.lifespantechnologies.com/graphql",
  internalqa: "https://staging.lifespantechnologies.com/graphql",
  develop: "http://0.0.0.0:5000/graphql",
}

const httpLink = createHttpLink( {
  uri: BACKEND_URI[ process.env.REACT_APP_ENV || "develop" ],
} )

const auth = setContext( ( _, { headers } ) => {
  const token = localStorage.getItem( "token" )

  if ( !token ) {
    return { headers }
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${ token }`,
    },
  }
} )

const defaultOptions = {
  query: {
    fetchPolicy: "no-cache",
  },
  mutate: {
    fetchPolicy: "no-cache",
  },
}

const errorLink = onError( error => {
  const graphQLErrors = _.get( error, "graphQLErrors", [] )

  const isTokenExpired = graphQLErrors.some( graphQLError => graphQLError.message === expiredTokenMessage )
  if ( isTokenExpired ) {
    localStorage.removeItem( "token" )
    alert( "Your login has expired, you will now be redirected to the login screen." ) // TODO: prettify this popup
    location.reload()
  }

} )

export const client = new ApolloClient( {
  link: errorLink.concat( auth.concat( httpLink ) ),
  cache: new InMemoryCache(),
  defaultOptions
} )

const wrapInConsumer = ( Component, props ) => (
  <ApolloConsumer>
    { client => (
      <Component client={client} {...props} />
    )}
  </ApolloConsumer>
)

export const withApolloClient = ToWrap => {
  const className = `${ ToWrap.name }WithApolloClient`

  const wrappedComponent = class extends Component {
    render () {
      return wrapInConsumer( ToWrap, this.props )
    }
  }

  Object.defineProperty( wrappedComponent, "name", { value: className } )

  return wrappedComponent
}
