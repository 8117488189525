import React, { Component } from "react"
import Dropzone from "react-dropzone"
import style from "./style.css"

class FileDropzone extends Component {

  render () {
    const { placeholder, name, multiple, value, readOnly, onLoad, renderValue } = this.props

    let inputProps = {}
    if ( value.length > 0 ) {
      inputProps.src = value
    }

    const uploadZone = (
      <div>
        <div>
          <b><span style={{ fontSize: "14px", fontWeight: 600 }}>Drag your file to upload</span></b>
        </div>
        <div>
          <span style={{ fontSize: "12px", fontWeight: 300 }} >or click here:</span>
        </div>
        <div className={style.button} >
          <div className={style.action}>Upload</div>
        </div>
      </div>
    )
    const zoneText = (
      <div className={style.containerText}>
        { readOnly
          ? placeholder
          : uploadZone
        }
      </div>
    )

    const noEvents = { pointerEvents: "none" }

    const handleDrop = e => {
      const reader = new FileReader()
      reader.onload = () => onLoad( reader )

      reader.readAsBinaryString( e[ 0 ] )
    }

    return (
      <div className={style.container} style={ readOnly ? noEvents : {} }>
        <Dropzone
          className={style.dropzone}
          onDrop={handleDrop}
          name={name}
          multiple={multiple}
          accept={this.props.accept}
          inputProps={{}}
        >
          { value ?
            renderValue ? renderValue( value ) : ""
            : zoneText
          }
        </Dropzone>
      </div>
    )
  }
}

export default FileDropzone
