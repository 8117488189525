import React, { Component } from "react"
import ExcelColumn from "react-data-export/dist/ExcelPlugin/elements/ExcelColumn"
import ExcelFile from "react-data-export/dist/ExcelPlugin/components/ExcelFile"
import ExcelSheet from "react-data-export/dist/ExcelPlugin/elements/ExcelSheet"

export default class Download extends Component {
  renderColumn ( field, key ) {
    const { name, displayName, transform, render } = field

    const valueFunction = row => {
      let val = row[ name ] || ""

      if ( transform ) {
        val = transform( val )
      }

      if ( render ) {
        val = render( val )
      }

      return val
    }

    const value = transform || render ? valueFunction : name

    return <ExcelColumn key={key} label={displayName} value={value} />
  }

  renderColumns ( fields ) {
    return fields.map( this.renderColumn.bind( this ) )
  }

  render () {
    const {
      filename,
      name,
      tableData = { fields: [], rows: [] },
      element,
    } = this.props

    const { fields, rows } = tableData

    return (
      <ExcelFile filename={filename} element={element}>
        <ExcelSheet data={rows} name={name}>
          { this.renderColumns( fields ) }
        </ExcelSheet>
      </ExcelFile>
    )
  }
}
