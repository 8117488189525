import React, { Component } from "react"
import style from "./style.css"

export default class MultiValueContainer extends Component {
  render () {
    const { selectProps } = this.props
    const { value } = selectProps

    const isLast = value.some(
      ( self, index ) => self.value === this.props.data.value && index === value.length - 1
    )

    const { label } = this.props.data
    return ( <div className={style.label}>{`${ label }${ !isLast ? ", " : "" }`}</div> )
  }
}
