import { Component } from "react"
import _ from "lodash"
import { withRouter } from "utils/router"
import { withStore } from "store"
import { withApolloClient } from "apollo"
import { wrapInMutation } from "views/EditView"
import { buildErrorHandle, buildActions } from "utils/submit"
import { GET_USER_ALERTS } from "graphql/queries"
import { EDIT_USER, EDIT_USER_ALERTS } from "graphql/mutations"
import Presentator from "components/DynamicForm/Presentator"
import DataTableListInput from "components/DynamicForm/DataTableListInput"
import style from "./style.css"
import { buildUserAlertFields } from "./util"

const buildHeader = ( row, operations = [] ) => ( {
  title: `${ row.firstName } ${ row.lastName } Alerts`,
  mutation: EDIT_USER,
  tooltip: "User only receives alerts by\nemail if alarms are checked",
  displayCount: {
    count: value => value.userAlerts.filter( item => item.isSubscribed ).length,
  },
  operations,
} )

const fetchPolicy = "cache-and-network"

const buildSchema = ( store, items = [] ) => {
  const fields = buildUserAlertFields( store )

  const alerts = new DataTableListInput( {
    addVirtualIds: true,
    schema: {
      name: "userAlerts",
      selectorField: { name: "isSubscribed", label: "Select" },
      pageSize: 10,
      fieldType: "list",
      fields,
      options: items,
    },
  } )

  const mainPresentator = new Presentator( {
    schema: {
      className: style.main,
      children: [ alerts ],
    },
  } )

  const schema = {
    name: "user",
    passThrough: true,
    children: [ mainPresentator ],
  }

  return schema
}

const update = ( props, notification, options ) => mutationFunction => data => {
  const { addNotification } = props
  const { row } = props.location.state

  const userId = row.id

  const updateObject = data.userAlerts.reduce( ( acc, alert, index ) => {
    const originalItem = options[ index ]

    if ( alert.isSubscribed !== originalItem.isSubscribed ) {
      const { alertTypeId, channelsIds, isSubscribed } = alert

      const newEntries = channelsIds.map( channelId => ( {
        userId,
        alertTypeId,
        channelId,
        isSubscribed,
      } ) )

      return [
        ...acc,
        ...newEntries,
      ]
    }

    return [ ...acc ]
  }, [] )

  const variables = {
    updateObject,
  }

  mutationFunction( { variables } ).then( () => {
    addNotification( "info", notification )
    props.history.goBack()
  } )
}

class UserAssignAlerts extends Component {
  componentDidMount () {
    const { store } = this.props

    store.fetch( "sensors" )
    this.fetchUserAlerts()
  }

  fetchUserAlerts () {
    const query = GET_USER_ALERTS
    const variables = {
      userId: this.props.location.state.row.id,
    }

    const callback = result => this.setState( {
      alerts: _.get( result, "data.userAlertsByUserId.nodes", [] ),
    } )

    this.props.client.query( { query, variables } ).then( callback.bind( this ) )
  }

  render () {
    const { row, options } = this.props.location.state
    //const { goBack } = this.props.history
    const { history } = this.props.router

    const viewProps = {
      header: buildHeader( row ),
      schema: buildSchema( this.props.store, options ),
      value: {
        id: row.id,
        userAlerts: options,
      },
    }

    return wrapInMutation( {
      mutation: EDIT_USER_ALERTS,
      row,
      viewProps,
      buildSubmit: update( this.props, "User updated!", options ).bind( this ),
      handleError: buildErrorHandle( this.props ).bind( this ),
      buildActions: buildActions( false, history.goBack ),
    } )
  }
}

export default withRouter( withStore( withApolloClient( UserAssignAlerts ) ) )
