import React, { Component } from "react"

class Svg extends Component {
  render () {
    const { html, style } = this.props

    return (
      <div
        {...this.props}
        style={{
          display: "flex",
          ...style,
        }}
        dangerouslySetInnerHTML={ { __html: html } }
      />
    )
  }
}

export default Svg
