import React from "react"
import DynamicFormChild from "../DynamicFormChild"

class Label extends DynamicFormChild {
  constructor ( props, instance ) {
    super( props )

    if ( instance === undefined ) {
      return React.createElement( Label, props )
    }
  }

  render () {
    const { text, className, style } = this.props

    const containerClassName = className || ""
    const containerStyle = style || {}

    return (
      <div className={containerClassName} style={containerStyle}>
        { text }
      </div>
    )
  }
}

export default Label
