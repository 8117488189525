import style from "shared/style.css"
import Presentator from "components/DynamicForm/Presentator"
import Field from "components/DynamicForm/Field"
import { triggerLoadIfNeeded } from "store"

export default ( store, row, production, readOnly = false ) => {
  const {
    units = [],
    sensorTypes = [],
  } = store

  triggerLoadIfNeeded( { units, sensorTypes }, store.fetch, row )

  const commonStringDefinition = {
    fieldType: "string",
    className: style.field,
    container: { className: style.fieldContainer },
  }

  const name = new Field( {
    disabled: readOnly,
    schema: {
      name: "name",
      label: "Sensor Name",
      ...commonStringDefinition,
    },
  } )

  const type = new Field( {
    disabled: readOnly || production,
    schema: {
      formatOut: _.parseInt,
      name: "sensorTypeId",
      label: "Sensor Type",
      ...commonStringDefinition,
      fieldType: "enum",
      options: sensorTypes.map( sensorType => ( { value: sensorType.id, label: sensorType.name } ) ),
    },
  } )

  const description = new Field( {
    disabled: readOnly,
    schema: {
      name: "description",
      label: "Sensor Description",
      ...commonStringDefinition,
    },
  } )

  const locationDescription = new Field( {
    disabled: readOnly,
    schema: {
      name: "locationDescription",
      label: "Location Description",
      ...commonStringDefinition,
    },
  } )

  const firstRow = new Presentator( {
    schema: {
      className: style.presentator,
      children: [ name, type ],
    },
  } )

  const secondRow = new Presentator( {
    schema: {
      className: style.presentator,
      children: [ description, locationDescription ],
    },
  } )

  const mainPresentator = new Presentator( {
    passThrough: true,
    schema: {
      className: style.main,
      children: [
        firstRow,
        secondRow,
      ],
    },
  } )

  const schema = {
    name: "user",
    passThrough: true,
    children: [ mainPresentator ],
  }

  return schema
}
