import React, { Component } from "react"
//import { Link, withRouter } from "react-router-dom"
import { Link, useNavigate, useParams, useLocation } from "react-router-dom"
import Img from "components/Img"
import Svg from "components/Svg"
import style from "./style.css"
import placeholderImage from "images/sidebar/placeholder.jpg"
import defaultIcon from "images/sidebar/structures.svg"
import logoutIcon from "images/icons/logout.svg"
import { startCase } from "lodash"
import { sidebarRoutes, routeIconMap, filterRoutesByUserAccess } from "routes"

const HookedSidebar = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();

  return <Sidebar {...props} router={{location, navigate, params}}/>
}

class Sidebar extends Component {
  renderUserSection () {
    const { logout, user = {}, router } = this.props //Has the router property w location / history / match
    
    const {
      image = placeholderImage,
      firstName = "",
      lastName = "",
    } = user

    const fullName = `${ firstName } ${ lastName }`

    return (
      <div className={style.userSection}>
        <div className={style.userContainer}>
          <div className={style.userImageSectionContainer}>
            <div className={style.userImageContainer}>
              <Img className={style.userImage} src={ image } placeholder={placeholderImage} />
            </div>
            <div className={style.userLogout} onClick={ () => logout(router.navigate) }>
              <Svg html={logoutIcon} />
            </div>
          </div>
          <div className={style.userName}> { fullName } </div>
          <div className={style.spacer} />
        </div>
      </div>
    )
  }

  renderMenuItem = ( route, index ) => {
    const { location } = this.props

    const icon = routeIconMap [ route.name ] || defaultIcon
    const to = { pathname: route.path }

    const selected = route.path === window.location.pathname
    const svgSelectedClassname = style.menuIconActive
    const selectedStyle = {
      color: "white",
      borderRadius: "10px",
      backgroundColor: "#2f3842",
    }

    return (
        <div key={index} className={style.menuItemWrapper} style={ selected ? selectedStyle : {} }>
          <div className={style.menuItemIconContainer}>
            <div className={style.menuIcon}>
              <Svg className={selected ? svgSelectedClassname : ""} html={icon} />
            </div>
          </div>
          <div className={style.menuName}>
            <Link className={style.menuLink} to={to}> { startCase( route.name ) } </Link>
          </div>
        </div>
    )
  }

  renderMenu () {
    const filteredRoutes = sidebarRoutes.filter( route => route.name !== "root" )
    const userRoutes = filterRoutesByUserAccess( filteredRoutes, this.props.user )

    return (
      <div
        className={style.menu}>
        { userRoutes.map( this.renderMenuItem ) }
      </div>
    )
  }

  render () {
    return (
      <div className={style.main}>
        { this.renderUserSection() }
        { this.renderMenu() }
      </div>
    )
  }
}

export default HookedSidebar
