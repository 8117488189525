import React, { Component } from "react"
import _ from "lodash"
import Img from "components/Img"
import placeholder from "images/placeholders/device.png"
import { wrapInQuery } from "views/EasyView"
import { activeTransform } from "shared/transforms"
import { GET_STRUCTURES } from "graphql/queries"
import { createNewItem } from "components/Header/MoreContainer"

const buildHeader = ( operations = [] ) => ( {
  title: "Structures",
  operations,
} )

const upperFirst = string => _.upperFirst( _.lowerCase( string ) )

const imageRender = src => <Img src={src} placeholder={placeholder} />
const fields = [
  { name: "image", type: "image", isSortable: false, style: { flex: "0" }, render: imageRender },
  { name: "deviceName", displayName: "Structure Name", isSearchable: true, type: "string" },
  { name: "environment", displayName: "Status", isSearchable: true, type: "string", transform: upperFirst },
  { name: "isActive", type: "boolean", displayName: "State", isSearchable: true, transform: activeTransform },
  { name: "description", displayName: "Description", isSearchable: true, type: "string" },
]

const fetchPolicy = "cache-and-network"
const nodesKey = "allDevices"

const editRoute = "/structures/"

export default class Structures extends Component {
  render () {

    const headerOperations = [
      //createNewItem( this.props.location.pathname, {} ),
      createNewItem( window.location.pathname, {} ),
    ]

    const header = buildHeader( headerOperations )
    const viewProps = {
      header,
      editRoute,
    }

    return wrapInQuery( {
      query: GET_STRUCTURES,
      fetchPolicy,
      nodesKey,
      fields,
      viewProps,
    } )
  }
}
