import React, { Component } from "react"
import { Mutation } from "react-apollo"
import { SETUP_PASSWORD } from "graphql/mutations"
import Svg from "components/Svg"
import style from "../Login/style.css"
import logo from "images/login/logo.png"
import backgroundImage from "images/login/background.png"
import passwordIcon from "images/login/lock.svg"
import { withRouter } from "../../utils/router"


class SetupPassword extends Component {
    state = {
      password: "",
      confirmPassword: "",
    }

    handleKeyPress = ( event ) => {
      if ( event.key === "Enter" ) {
        this.state.submitButton && this.state.submitButton.click()
      }
    }

    renderSubmitButton () {
      const {
        password,
        confirmPassword,
      } = this.state

      const validInputs = password.length > 0 && password === confirmPassword

      const backgroundStyle = validInputs ? { backgroundColor: "#7dcb27" } : {}

      return (
        <div className={style.submitWrapper}>
          <div className={style.submitContainer} style={backgroundStyle}>
            <input
              type="submit"
              value="Setup Password"
              className={style.submitButton}
              ref={ submitButton => !this.state.submitButton && this.setState( { submitButton } )}
            />
          </div>
        </div>
      )
    }

    renderInputs () {
      return (
        <div className={style.inputBox}>
          <div className={style.inputContainer}>
            <Svg className={style.icon} html={passwordIcon} />
            <input
              name="password"
              className={style.input}
              placeholder="Password"
              type="password"
              onKeyPress={this.handleKeyPress.bind( this )}
              onChange={ e => this.setState( { password: e.target.value } ) }
            />
          </div>
          <div className={style.inputContainer}>
            <Svg className={style.icon} html={passwordIcon} />
            <input
              name="password-repeat"
              className={style.input}
              placeholder="Repeat password"
              type="password"
              onKeyPress={this.handleKeyPress.bind( this )}
              onChange={ e => this.setState( { confirmPassword: e.target.value } ) }
            />
          </div>
          { this.renderSubmitButton() }
        </div>
      )
    }

    renderLogo () {
      return (
        <div className={style.logoContainer} style={{ marginBottom: "auto" }}>
          <img className={style.logo} src={logo} />
        </div>
      )
    }

    submit ( setupPassword ) {
      return function ( e ) {
        const { token } = this.props.router.params
        const { addNotification } = this.props
        const { password } = this.state
        e.preventDefault()

        setupPassword( { variables: {
          newPassword: password,
          token,
        } } )
          .then( () => {
            addNotification( "info", "Success! Login with the new credentials" )
            this.props.history.push( "/login" )
          } )
          .catch( () => addNotification( "error", "An error occurred" ) )
      }
    }

    render () {
      return (
        <Mutation mutation={SETUP_PASSWORD}>
          {( setupPassword ) => {
            const onSubmit = this.submit( setupPassword )

            return (
              <div className={style.main}>
                <div className={style.background}>
                  <img src={backgroundImage} />
                </div>
                <div className={style.column} style={{ height: "50%" }}>
                  { this.renderLogo() }
                  <div className={style.formContainer}>
                    <form className={style.form} onSubmit={onSubmit.bind( this )}>
                      { this.renderInputs() }
                    </form>
                  </div>
                </div>
              </div>
            )
          }}
        </Mutation>
      )
    }
}

export default withRouter(SetupPassword)
