import React, { Component } from "react"
import _ from "lodash"
//import { withRouter } from "react-router-dom"
import { withRouter } from "utils/router"
import { withApolloClient } from "apollo"
import { setReadOnly } from "routes"
import { withStore } from "store"
import { wrapInMutation } from "views/EditView"
import { update, buildErrorHandle, buildActions } from "utils/submit"
import Channel from "./channel"
import CalibrationTable from "./calibrationTable"
import { EDIT_SENSOR } from "graphql/mutations"
import buildSchema from "./schema"
import style from "shared/style.css"

const buildHeader = ( title, operations = [], readOnly = false ) => ( {
  title,
  activeSwitch: !readOnly,
  mutation: EDIT_SENSOR,
  tooltip: "Inactive users won't be able to\nsign-in to the system",
  operations,
} )

const fetchPolicy = "cache-and-network"

class EditStructure extends Component {
  state = {
    readOnly: false,
  }

  componentDidMount () {
    setReadOnly( this )
  }

  render () {
    const { row } = this.props.router.location.state
    const { history } = this.props.router
    const { addNotification } = this.props
    const { readOnly = false } = this.state

    const production = _.get( this.props, "location.state.parent.environment", "" ) === "PRODUCTION"

    const viewProps = {
      header: buildHeader( row.name, [], readOnly ),
      schema: buildSchema( this.props.store, row, production, readOnly ),
      value: row,
    }

    const sensorMutation = wrapInMutation( {
      mutation: EDIT_SENSOR,
      row,
      viewProps,
      buildSubmit: update( this.props, "Sensor updated!" ).bind( this ),
      handleError: buildErrorHandle( this.props ).bind( this ),
      buildActions: readOnly ? () => [] : buildActions( false, history.goBack ),
    } )

    const headerStyle = { fontWeight: 600, padding: 10 }

    const channelsItems = _.get( row, "channelsBySensorId.nodes", [] )
    const channels = (
      <div className={style.relationshipChildren}>
        <div style={headerStyle}>Channels</div>
        { channelsItems.map(
          ( item, key ) =>
            <Channel
              key={key}
              readOnly={readOnly}
              value={item}
              addNotification={addNotification}
              production={production}
            />
        )}
      </div>
    )

    const isStrain = _.get( row, "sensorTypeBySensorTypeId.name" ) === "Strain"
    const calibrationTable = isStrain ? (
      <CalibrationTable
        addNotification={this.props.addNotification}
        calibrationDate={row.calibrationDate}
        sensorId={row.id}
      />
    ) : ""

    return (
      <div className={ style.root } style={{ height: "100%" }}>
        { sensorMutation }
        { channels }
        { calibrationTable }
      </div>
    )
  }
}

export default withApolloClient( withRouter( withStore( EditStructure ) ) )
