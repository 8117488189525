import React, { Component } from "react"
import Dropzone from "react-dropzone"
import Svg from "components/Svg"
import trashBin from "images/symbols/trash.svg"
import style from "./style.css"

class ImageDropzone extends Component {


  onDrop = acceptedFiles => {
    const { onChange } = this.props

    const file = acceptedFiles.find( f => f )
    const i = new Image()

    i.onload = () => {
      let reader = new FileReader()
      reader.readAsDataURL( file )
      reader.onload = () => {
        const updater = { target: { value: reader.result } }
        onChange( updater )
      }
    }

    i.src = file.preview
  }

  render () {

    const { name, multiple, value, readOnly } = this.props
    const accepted = {
  'image/png': ['.png'], 
  'image/jpeg': ['.jpg', '.jpeg'] 
   }

    
    //const acceptObj = accepted.map(file => ({file}))

    let inputProps = {}
    if ( value.length > 0 ) {
      inputProps.src = value
    }

    const imageStyle = {
      backgroundImage: `url('${ value }')`,
    }

    const image = (<div className={style.image} style={imageStyle}/> )
    const uploadZone = (
      <div>
        <div>
          <b><span style={{ fontSize: "14px", fontWeight: 600 }}>Drag your file to upload</span></b>
        </div>
        <div>
          <span style={{ fontSize: "12px", fontWeight: 300 }} >or click here:</span>
        </div>
        <div className={style.button} >
          <div className={style.action}>Upload</div>
        </div>
        {/*<input accept="image/jpeg, image/png" type="file" autoComplete="off" name={name} className={style.dropzone}></input>*/}
      </div>
      
    )
    const zoneText = (
      <div className={style.containerText}>
        { readOnly
          ? "No Image Available"
          : uploadZone
        }
      </div>
    )

    const dropzoneBorder = value ? { border: "none" } : {}

    const trash = value && !readOnly ? (
      <div className={style.trashContainer} onClick={() => this.props.onChange( { target: { value: null } } )} >
        <Svg className={style.trash} html={trashBin} />
      </div>
    ) : ""

    const noEvents = { pointerEvents: "none" }
    const inputStyle = "position: absolute; inset: 0px; opacity: 1e-05; pointer-events: none;"

    return (
      <div className={style.container} style={ readOnly ? noEvents : {} }>
        { trash }
        <Dropzone
          style={dropzoneBorder}
          className={style.dropzone}
          onDrop={this.onDrop.bind( this )}
          name={name}
          //noDragEventsBubbling={true}
          multiple={multiple}
          accept="image/jpeg, image/png"
          inputProps={inputProps}
        >
          {value ? image : zoneText }
        </Dropzone>
      </div>
      
    )
  }
}

export default ImageDropzone
