import React, { Component } from "react"
import NotificationSystem from "react-notification-system"
import { Routes, Route, BrowserRouter } from "react-router-dom"
import { withApolloClient } from "apollo"
import { withStore } from "./store"
import { CURRENT_USER } from "graphql/mutations"
import App from "views/App"
import Login from "views/Login"
import RecoverPassword from "views/RecoverPassword"
import SetupPassword from "views/SetupPassword"
import style from "./style.css"

class Router extends Component {
  state = { notificationSystem: null }

  addNotification = ( type, message ) => {
    this.state.notificationSystem.addNotification( {
      message: message,
      level: type,
    } )
  }

  componentDidMount () {
    this.setState({ notificationSystem: this.refs.notificationSystem }) // eslint-disable-line
  }

  render ()  {
    const { store, client } = this.props
    const { setState }  = store

    const RootComponent = this.props.store.auth ? App : Login

    const logout = navigate => {
      localStorage.removeItem( "token" )
      navigate( "/login" )
      store.setState( { auth: false } )
    }

    const login = ( navigate, clientMutationId ) => {
      navigate( "/", {} )

      const callback = ( { data } ) => {
        const user = data.currentUser.user
        setState( { user, auth: true } )
      }

      client.mutate( {
        mutation: CURRENT_USER,
        variables: { userInput: { clientMutationId } },
      } ).then( callback.bind( this ) )

    }

    const loginProps = {
      addNotification: this.addNotification.bind( this ),
      login,
      //login: this.login.bind( this ),
      //logout: this.logout.bind( this ),
    }

    const passwordProps = {
      addNotification: this.addNotification.bind(this)
    }

    const rootProps = {
      addNotification: this.addNotification.bind( this ),
      login,
      logout,
      //login: this.login.bind( this ),
      //logout: this.logout.bind( this ),
    };

    return (
      <div className={style.root}>
        <NotificationSystem ref="notificationSystem" />
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path={ "/login" }
              element={ <Login {...loginProps}></Login>}
            />
            <Route
              exact
              path={ "/recoverPassword" }
              element={ <RecoverPassword {...passwordProps} />}
            />
            <Route
              exact
              path="/setupPassword/:token"
              element={ <SetupPassword {...passwordProps} />}
            />
            <Route
              path={"*"}
              element={ <RootComponent {...rootProps} />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    )
  }
}

export default withApolloClient( withStore( Router ) )
