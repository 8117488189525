import style from "./style.css"
import Presentator from "components/DynamicForm/Presentator"
import Field from "components/DynamicForm/Field"
import { styledHelpers, orderCountryOptions } from "utils/schema"
import { triggerLoadIfNeeded } from "store"
import _ from "lodash"

const { stringField, enumField, presentator } = styledHelpers( style )

export const buildSchema = ( store, readOnly = false ) => {
  const {
    countries = [],
  } = store

  triggerLoadIfNeeded( { countries }, store.fetch )

  const nameSchema = stringField( "name", "Organization Name" )
  const name = new Field( { disabled: readOnly, required: true, schema: nameSchema } )

  const descriptionSchema = stringField( "description", "Organization Description" )
  const description = new Field( { disabled: readOnly, schema: descriptionSchema } )

  const addresses = [ 1, 2, 3 ].map( i => new Field( {
    disabled: readOnly,
    required: i === 1,
    schema: stringField( `address${ i }`, `Address ${ i }` )
  } ) )

  const phoneSchema = stringField( "phone", "Phone" )
  const phone = new Field( { disabled: readOnly, schema: phoneSchema } )

  const emailSchema = {
    ...stringField( "email", "Email" ),
    dataType: "email_address"
  }
  const email = new Field( { disabled: readOnly, required: true, schema: emailSchema } )

  const stateSchema = stringField( "state", "State" )
  const state = new Field( { disabled: readOnly, required: true, schema: stateSchema } )

  const citySchema = stringField( "city", "City" )
  const city = new Field( { disabled: readOnly, required: true, schema: citySchema } )

  const zipCodeSchema = stringField( "zipCode", "ZIP Code" )
  const zipCode = new Field( { disabled: readOnly, required: true, schema: zipCodeSchema } )

  const countryOptions = countries ? countries.map( country => ( { value: country.id, label: country.name } ) ) : []
  const countrySchema = {
    formatOut: _.parseInt,
    ...enumField( "countryId", "Country", countryOptions ),
    sortFn: orderCountryOptions,
  }
  const country = new Field( { disabled: readOnly, required: true, schema: countrySchema } )

  const firstRowSchema = presentator( "row", [ name, description ] )
  const firstRow = new Presentator( {
    schema: firstRowSchema,
  } )

  const addressesRowsSchema = presentator( "column", addresses )
  const addressesRows = new Presentator( {
    schema: addressesRowsSchema,
  } )

  const lastRowSchema = presentator( "row", [ state, city, zipCode, country ] )
  const lastRow = new Presentator( {
    schema: lastRowSchema,
  } )

  const contactsRowSchema = presentator( "row", [ phone, email ] )
  const contacts = new Presentator( {
    schema: contactsRowSchema,
  } )

  const relationFieldSchema = stringField( "relItemField", "list field" )
  const relationField = new Field( { disabled: readOnly, schema: relationFieldSchema } )

  const listPresentatorSchema = {
    name: "relation",
    direction: "column",
    type: "list",
    children: [ relationField ],
  }
  // meant to be used as sample for relationship items
  const listPresentator = new Presentator( {
    passThrough: true,
    schema: listPresentatorSchema,
  } )

  const presentatorSchema = {
    direction: "column",
    children: [
      firstRow,
      addressesRows,
      contacts,
      lastRow,
      // listPresentator,
    ],
  }
  const mainPresentator = new Presentator( {
    passThrough: true,
    schema: presentatorSchema,
  } )

  const schema = {
    name: "organization",
    children: [ mainPresentator ],
  }

  return schema
}
