import _ from "lodash"
import levenshtein from "fast-levenshtein"


const stringSearch = ( searchTerm, field, row ) => {
  const { name, transform } = field
  const distance = calculateDistance( searchTerm )

  const toStringIfNeeded = variable => !_.isString( variable ) ? _.toString( variable ) : variable
  const lowerCase = variable => variable && typeof variable.toLowerCase === "function"
    ? variable.toLowerCase()
    : toStringIfNeeded( variable )


  searchTerm = lowerCase( searchTerm )
  const value = lowerCase( transform ? transform( row[ name ] ) : row[ name ] )
  if ( !value ) return false

  const transformedValue = transform ? lowerCase( transform( value ) ) : null

  const hasIncludeFn = variable => variable && typeof variable.includes === "function"

  const includedInValue = hasIncludeFn( value ) && value.includes( searchTerm )
  const includedInTransformedValue = hasIncludeFn( transformedValue ) && transformedValue.includes( searchTerm )

  const valid = includedInValue || includedInTransformedValue

  return valid
  // return levenshtein.get( searchTerm, value ) <= distance
}

const searchForField = ( field, searchTerm, row ) => {
  const { type, search } = field

  if ( type !== "string" ) {
    return true
  }

  return search( searchTerm, field, row )
}

const shouldKeepRow = ( search, row ) => {
  const { field, term } = search
  return searchForField( field, term, row )
}

export const filterDataBySearchTerms = ( data, searchTerms ) => {
  const { rows } = data

  if ( searchTerms.length < 1 ) {
    return data
  }

  const filteredRows = rows.filter( row => {
    const keepRow = searchTerms.every( search => {
      return shouldKeepRow( search, row )
    } )

    return keepRow
  } )


  return {
    ...data,
    rows: filteredRows,
  }
}

const calculateDistance = searchTerm => {
  let distance = 5

  const searchTermLength = searchTerm.length
  if ( searchTermLength < distance ) {
    distance += searchTermLength
  }

  return distance
}

export const defaultSearchByFieldType = {
  string: stringSearch,
}
