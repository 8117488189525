import React, { Component } from "react"
import _ from "lodash"
import { wrapInMutation } from "views/EditView"
import { withRouter } from "utils/router"
import { withStore } from "store"
import { withApolloClient } from "apollo"
import { EDIT_CHANNEL } from "graphql/mutations"
import { update, buildErrorHandle, buildActions } from "utils/submit"
import Presentator from "components/DynamicForm/Presentator"
import Field from "components/DynamicForm/Field"
import { unitOptions } from "../schema"
import style from "shared/style.css"

const commonStringDefinition = {
  fieldType: "string",
  className: style.field,
  container: { className: style.fieldContainer },
}

const buildSchema = ( store, row, production, readOnly = false ) => {
  const {
    units = {},
  } = store

  const description = new Field( {
    disabled: readOnly,
    schema: {
      name: "description",
      label: "Channel Description",
      ...commonStringDefinition,
    },
  } )

  const offset = new Field( {
    disabled: readOnly,
    schema: {
      name: "channelOffset",
      label: "Offset",
      ...commonStringDefinition,
    },
  } )

  const conversionFactor = new Field( {
    disabled: readOnly,
    schema: {
      name: "conversionFactor",
      label: "Conversion Factor",
      ...commonStringDefinition,
    },
  } )

  const channelTypeName = _.get( row, "channelTypeByChannelTypeId.name", "" )
  const unit = new Field( {
    disabled: readOnly || production,
    schema: {
      formatOut: _.parseInt,
      name: "unitId",
      label: "Display Unit",
      ...commonStringDefinition,
      fieldType: "enum",
      options: unitOptions( units, channelTypeName ),
    },
  } )

  const firstRow = new Presentator( {
    direction: "row",
    schema: {
      className: style.main,
      children: [ description ],
    },
  } )

  const sensorSettings = new Presentator( {
    direction: "row",
    schema: {
      className: style.main,
      children: [ offset, conversionFactor, unit ],
    },
  } )

  const mainPresentator = new Presentator( {
    passThrough: true,
    schema: {
      className: style.main,
      direction: "column",
      children: [
        firstRow,
        sensorSettings,
      ],
    },
  } )

  const schema = {
    name: "user",
    passThrough: true,
    children: [
      mainPresentator,
    ],
  }

  return schema
}

class Channel extends Component {
  render () {
    const { value, production, readOnly = false } = this.props

    const viewProps = {
      schema: buildSchema( this.props.store, value, production, readOnly ),
      value,
    }

    const updateProps = {
      ...this.props,
      location: { state: { row: value } },
    }

    const updateCallback = () => {
      const { addNotification } = this.props
      addNotification( "info", "Channel Updated!" )
    }

    const form = wrapInMutation( {
      mutation: EDIT_CHANNEL,
      value,
      viewProps,
      buildSubmit: update( updateProps, "Sensor Channel Updated!", updateCallback.bind( this ) ).bind( this ),
      handleError: buildErrorHandle( this.props ).bind( this ),
      buildActions: readOnly ? () => [] : buildActions( false ),
    } )

    const title = _.get( value, "channelTypeByChannelTypeId.name", "Unknown" )

    return (
      <div className={style.relationshipChild}>
        <div className={style.relationshipChildTitle}> { title } </div>
        { form }
      </div>
    )
  }
}

export default withApolloClient( withRouter ( withStore ( Channel ) ) )
