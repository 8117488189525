import { Component } from "react"
import { wrapInQuery } from "views/EasyView"
import { GET_LOCATIONS } from "graphql/queries"
import { createNewItem } from "components/Header/MoreContainer"

const buildHeader = ( operations = [] ) => ( {
  title: "Locations",
  operations,
} )

const locationOrganization = {
  name: "organizationByOrganizationId",
  displayName: "Organization",
  isSearchable: true,
  type: "string",
  transform: org => org.name,
}

const fields = [
  { name: "name", displayName: "Location Name", isSearchable: true, type: "string" },
  { name: "description", displayName: "Location Description", isSearchable: true, type: "string" },
  locationOrganization,
]

const fetchPolicy = "cache-and-network"
const nodesKey = "allLocations"

const editRoute = "/locations/" // TODO

export default class Locations extends Component {
  render () {
    const headerOperations = [
     // createNewItem( this.props.location.pathname, {} ),
     createNewItem( window.location.pathname, {} )
    ]

    const header = buildHeader( headerOperations )
    const viewProps = {
      header,
      editRoute,
    }

    return wrapInQuery( {
      query: GET_LOCATIONS,
      fetchPolicy,
      nodesKey,
      fields,
      viewProps,
    } )
  }
}
