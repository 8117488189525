import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "utils/router";
import style from "./style.css"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      const parts = this.props.location.pathname.split("/")
      const finalPart = parts[parts.length - 1];
      if(_.toInteger(finalPart)) {
        this.props.history.push(this.props.location.pathname.replace(finalPart, ""))
      }

      return (
        <div className={style.error}>Something went wrong.</div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);