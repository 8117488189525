import React from "react"
import _ from "lodash"
import DynamicFormChild from "../DynamicFormChild"
import Label from "../Label"

class LabelField extends DynamicFormChild {
  constructor ( props, instance ) {
    super( props )

    if ( instance === undefined ) {
      return React.createElement( LabelField, props )
    }
  }

  textFromOptions ( value, options = [] ) {
    const selected = options.find( option => option.value === value )
    return _.get( selected, "label", "" )
  }

  render () {
    let { value } = this.props
    const { label, className, style, fromOptions = false, options = [] } = this.props.schema

    const containerClassName = className || ""
    const containerStyle = style || {}

    if ( fromOptions ) {
      value = this.textFromOptions( value, options )
    }

    return (
      <div className={containerClassName} style={containerStyle}>
        <Label text={label} schema={{}} />
        <Label text={value} schema={{}} />
      </div>
    )
  }
}

export default LabelField
