// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VQv0x64Cibf3u3sqYl63 {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  font-weight: 600;\n}\n\n.G8r4Pam2kriwWgw1TN4b {\n  display: flex;\n}\n\n.Y9qleHl8NyiUt3ashcHO {\n  display: flex;\n  cursor: pointer;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n  width: 45px;\n  height: 20px;\n}\n\n.bD9fueVjVdaKMfmInru8 {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  user-select: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DataTable/Pagination/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".paginationContainer {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  font-weight: 600;\n}\n\n.pages {\n  display: flex;\n}\n\n.pageButtonContainer {\n  display: flex;\n  cursor: pointer;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n  width: 45px;\n  height: 20px;\n}\n\n.arrow {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": "VQv0x64Cibf3u3sqYl63",
	"pages": "G8r4Pam2kriwWgw1TN4b",
	"pageButtonContainer": "Y9qleHl8NyiUt3ashcHO",
	"arrow": "bD9fueVjVdaKMfmInru8"
};
export default ___CSS_LOADER_EXPORT___;
