import React, { Component } from "react"
import _ from "lodash"
import Img from "components/Img"
import placeholder from "images/placeholders/device.png"
import Spinner from "components/Spinner"
import ImageDropzone from "components/DynamicForm/Fields/ImageDropzone"
import { GET_DEVICE_INFO } from "graphql/queries"
import { Query } from "react-apollo"
import style from "./style.css"
import sharedStyle from "shared/style.css"

const fetchPolicy = "cache-and-network"

export default class DeviceInfo extends Component {
  constructor ( props ) {
    super( props )

    console.assert( _.isNumber( props.deviceId ) )
  }

  shouldComponentUpdate ( nextProps ) {
    return !_.isEqual( this.props.deviceId, nextProps.deviceId )
  }

  render () {
    const rawImage = src => <Img className={sharedStyle.image} src={src} placeholder={placeholder}/>
    const dropzoneImage = () => <ImageDropzone readOnly value="" multiple={false} />

    return (
      <Query query={GET_DEVICE_INFO} variables={{ id: this.props.deviceId }} fetchPolicy={fetchPolicy}>
        {( { loading, error, data } ) => {
          if ( loading ) return <Spinner loading info="Loading Device Information..." />
          if ( error ) return ( <div className={style.error}>Device Information Could Not Be Loaded</div> )

          const { deviceName, image, description } = data.deviceById

          return (
            <div className={style.container}>
              <div className={style.topContainer}>
                <div className={style.title}>
                  { deviceName }
                </div>
              </div>
              <div className={style.bottomContainer}>
                <div className={style.textContainer}>
                  { description }
                </div>
                <div className={style.imageContainer} style={ image ? { height: "fit-content" } : {} } >
                  { image ? rawImage( image ) : dropzoneImage() }
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
}
