import React, { Component } from "react"
import moment from "moment"
import _ from "lodash"
import { Query } from "react-apollo"
import { GET_MEASUREMENTS, COUNT_MEASUREMENTS } from "graphql/queries"
import DeviceDataView from "../DeviceDataView"
import Spinner from "components/Spinner"
import style from "./style.css"
import alertsIcon from "images/views/setup-alerts.svg"
import Svg from "components/Svg"

const fetchPolicy = "cache-and-network"

const DATA_POINTS_LIMIT = 1500000

class DeviceDataWrapper extends Component {
  shouldComponentUpdate ( nextProps ) {
    return !_.isEqual( this.props.loading, nextProps.loading ) ||
      !_.isEqual( this.props.device, nextProps.device ) ||
      !_.isEqual( this.props.viewType, nextProps.viewType ) ||
      !_.isEqual( this.props.timeFilters, nextProps.timeFilters ) ||
      !_.isEqual( this.props.channelTypes, nextProps.channelTypes )
  }

  renderError () {
    return (
      <div className={style.error}>
        <Svg html={alertsIcon} className={style.alertIcon} />
        An unkown error occured! <br/> Please Retry
      </div>
    )
  }

  renderCountError ( count ) {
    return (
      <div className={style.error}>
        <Svg html={alertsIcon} className={style.alertIcon} />
        Too many data points requested! ({count.toLocaleString()})<br/>
        Please retry with a smaller search.
      </div>
    )
  }

  render () {
    const { channelTypes, timeFilters } = this.props

    const startDate = _.get( timeFilters, "startDate", moment().subtract( 1, "days" ).toISOString() )
    const endDate = _.get( timeFilters, "endDate", moment().toISOString() )

    const sensors = _.get( this.props.device, "sensorsByDeviceId.nodes", [] )

    const channelTypesIds = channelTypes.map( channelType => channelType.value )

    const channelIds = sensors
      .flatMap( sensor => sensor.channelsBySensorId.nodes )
      .filter( channel => _.includes( channelTypesIds, channel.channelTypeByChannelTypeId.id ) )
      .map( channel => channel.id )

    return (
      <Query
        variables={{
          channelIds,
          startDate,
          endDate,
        }}
        query={COUNT_MEASUREMENTS}
        fetchPolicy={fetchPolicy}
      >
        {( { loading, error, data } ) => {
          if ( loading ) return ( <Spinner info="Counting Data" loading={loading} /> )
          if ( error ) return this.renderError()

          const count = data.allMeasurements.totalCount
          if ( count > DATA_POINTS_LIMIT ) {
            return this.renderCountError( count )
          }

          return (
            <Query
              variables={{
                channelIds,
                startDate,
                endDate,
              }}
              query={GET_MEASUREMENTS}
              fetchPolicy={fetchPolicy}
            >
              {( { loading, error, data } ) => {
                if ( loading ) return ( <Spinner info="Fetching Data" loading={loading} /> )
                if ( error ) return this.renderError()

                return (
                  <DeviceDataView
                    { ...this.props }
                    channelIds={channelIds}
                    data={data}
                  />
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

export default DeviceDataWrapper
