// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sdLs4lKxfklbhG0LVS_8 {\n  display: flex;\n  flex-direction: column;\n  padding: 50px;\n}\n\n.gbq38jyC8CBBKb65Ujd0 {\n  padding-left: 30px;\n}\n\n.a3mRoBF7P9i4dYKqEEax {\n  display: flex;\n  color: #231f20;\n  font-size: 20px;\n  font-weight: 600;\n}\n\n.v1IxaEWzAmYFN7EN5ZHn {\n  font-size: 15px;\n}\n\n.eLwT0YCSGA3oPmepqMvL {\n  display: flex;\n  flex: 1;\n}\n\n.eLwT0YCSGA3oPmepqMvL div {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  flex: 1;\n  padding: 3px;\n}", "",{"version":3,"sources":["webpack://./src/views/Structures/sensors/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,OAAO;AACT;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,OAAO;EACP,YAAY;AACd","sourcesContent":[".calibrationContainer {\n  display: flex;\n  flex-direction: column;\n  padding: 50px;\n}\n\n.calibrationHeader {\n  padding-left: 30px;\n}\n\n.calibrationTitle {\n  display: flex;\n  color: #231f20;\n  font-size: 20px;\n  font-weight: 600;\n}\n\n.date {\n  font-size: 15px;\n}\n\n.calibrationInput {\n  display: flex;\n  flex: 1;\n}\n\n.calibrationInput div {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  flex: 1;\n  padding: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calibrationContainer": "sdLs4lKxfklbhG0LVS_8",
	"calibrationHeader": "gbq38jyC8CBBKb65Ujd0",
	"calibrationTitle": "a3mRoBF7P9i4dYKqEEax",
	"date": "v1IxaEWzAmYFN7EN5ZHn",
	"calibrationInput": "eLwT0YCSGA3oPmepqMvL"
};
export default ___CSS_LOADER_EXPORT___;
