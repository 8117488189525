import React from "react"
import style from "shared/style.css"
import Presentator from "components/DynamicForm/Presentator"
import ImageField from "components/DynamicForm/ImageField"
import LabelField from "components/DynamicForm/LabelField"
import Label from "components/DynamicForm/Label"
import { triggerLoadIfNeeded } from "store"
import _ from "lodash"

export default ( store, row ) => {
  const {
    carriers = [],
    deviceTypes = [],
    locations = [],
    modemTypes = [],
  } = store

  triggerLoadIfNeeded( { carriers, modemTypes, deviceTypes, locations }, store.fetch, row )

  const commonStringDefinition = {
    fieldType: "string",
    className: style.labelField,
  }

  const structureName = new LabelField( {
    schema: {
      name: "deviceName",
      label: "Structure Name",
      ...commonStringDefinition,
    },
  } )

  const description = new LabelField( {
    text: "Structure Description",
    schema: {
      name: "description",
      label: "Structure Description",
      ...commonStringDefinition,
    },
  } )

  const environment = new LabelField( {
    text: "Environment",
    schema: {
      name: "environment",
      label: "Environment",
      ...commonStringDefinition,
    },
  } )

  const image = new ImageField( {
    schema: {
      name: "image",
      multiple: false,
      className: [ style.imageContainer, style.labelPresentatorImage ].join( " " ),
      readOnly: true,
    },
  } )

  const imageDescription = new LabelField( {
    schema: {
      name: "imageDescription",
      label: "Image Description",
      ...commonStringDefinition,
    },
  } )

  const macAddress = new LabelField( {
    schema: {
      name: "macAddress",
      label: "Mac Address",
      ...commonStringDefinition,
    },
  } )

  const ipAddress = new LabelField( {
    schema: {
      name: "ipAddress",
      label: "IP Address",
      ...commonStringDefinition,
    },
  } )

  const phone = new LabelField( {
    schema: {
      name: "phoneNumber",
      label: "Phone",
      ...commonStringDefinition,
    },
  } )

  const firmware = new LabelField( {
    schema: {
      name: "firmwareVersion",
      label: "Firmware Version",
      ...commonStringDefinition,
    },
  } )

  const software = new LabelField( {
    schema: {
      name: "softwareVersion",
      label: "Software Version",
      ...commonStringDefinition,
    },
  } )

  const location = new LabelField( {
    text: "Location",
    schema: {
      formatOut: _.parseInt,
      name: "locationId",
      label: "Location",
      ...commonStringDefinition,
      fieldType: "enum",
      fromOptions: true,
      options: locations.map( loc => ( { value: loc.id, label: loc.name } ) ),
    },
  } )

  const leftColumnHeader = (
    <div israwjsx="true" className={style.labelPresentatorHeader}>Structure Details</div>
  )

  const rightColumnHeader = (
    <div israwjsx="true" className={style.labelPresentatorHeader}>Structure Image</div>
  )

  const leftColumn = new Presentator( {
    direction: "column",
    schema: {
      className: [ style.presentator, style.labelPresentator ].join( " " ),
      children: [
        leftColumnHeader,
        environment,
        location,
        structureName,
        ipAddress,
        macAddress,
        phone,
        description,
        firmware,
        software,
      ],
    },
  } )

  const rightColumn = new Presentator( {
    schema: {
      className: [ style.presentator, style.labelPresentator ].join( " " ),
      children: [ rightColumnHeader, image, imageDescription ],
    },
  } )

  const mainPresentator = new Presentator( {
    passThrough: true,
    schema: {
      className: style.main,
      children: [
        leftColumn,
        rightColumn,
      ],
    },
  } )

  const schema = {
    name: "user",
    passThrough: true,
    children: [ mainPresentator ],
  }

  return schema
}
