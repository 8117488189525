import _ from "lodash"

const genericTransform = ( key, fieldName, defaultValue = "Unknown" ) => store => id => {
  id = _.parseInt( id )

  const items = _.get( store, key )
  if ( !items ) return id

  const type = _.find( items, item => item.id === id )

  return _.get( type, fieldName, defaultValue )
}

export const transformChannelIdToSensorName = store => channelId => {
  const { sensors } = store
  if ( !sensors ) return ""

  const sensor = _.find( sensors, sensor => {
    const channels = sensor.channelsBySensorId.nodes.map( channel => channel.id )
    return _.includes( channels, channelId )
  } )

  return sensor ? sensor.name : "Unknown"
}

export const transformAlertType = genericTransform( "alertTypes", "name" )
export const transformAlertDescription = genericTransform( "channels", "description" )
export const activeTransform = isActive => isActive ? "Active" : "Inactive"
