// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dPU76ZI_0jduk1tMajCL {\n  display: flex;\n  flex-direction: row;\n  max-width: 100vw;\n  overflow: hidden;\n}\n\n.IR5tZQCjKd1BJaNi_wmc {\n  min-height: 100vh;\n}\n\n.asAXqfjOPSM27cvEBrsc {\n  flex: 1;\n}\n\n.LL8_kS24muwDyBPTh8Yy {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n@media print {\n  .dPU76ZI_0jduk1tMajCL,\n  .asAXqfjOPSM27cvEBrsc {\n    display: block;\n  }\n\n  .IR5tZQCjKd1BJaNi_wmc {\n    display: none;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/views/App/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE;;IAEE,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".app {\n  display: flex;\n  flex-direction: row;\n  max-width: 100vw;\n  overflow: hidden;\n}\n\n.sidebarContainer {\n  min-height: 100vh;\n}\n\n.viewContainer {\n  flex: 1;\n}\n\n.error {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n@media print {\n  .app,\n  .viewContainer {\n    display: block;\n  }\n\n  .sidebarContainer {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "dPU76ZI_0jduk1tMajCL",
	"sidebarContainer": "IR5tZQCjKd1BJaNi_wmc",
	"viewContainer": "asAXqfjOPSM27cvEBrsc",
	"error": "LL8_kS24muwDyBPTh8Yy"
};
export default ___CSS_LOADER_EXPORT___;
