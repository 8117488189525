import React from "react"
import { Component } from "react"
import { withRouter } from "utils/router"
import { withApolloClient } from "apollo"
import { withStore } from "store"
import { wrapInMutation } from "views/EditView"
import { create, buildErrorHandle, buildActions } from "utils/submit"
import { CREATE_LOCATION } from "graphql/mutations"
import buildSchema from "./schema"

const buildHeader = ( operations = [] ) => ( {
  title: "Create Location",
  tooltip: "Inactive users won't be able to\nsign-in to the system",
  operations,
} )

const fetchPolicy = "cache-and-network"

class CreateLocation extends Component {
  componentDidMount() {
    this.props.store.fetch( "organizations" )
  }
  render () {
    //const { row } = this.props.router.location.state
    const row = undefined //This was always coming up as undefined in the old version.
    
    //const { goBack } = this.props.history
    const { history } = this.props.router

    const viewProps = {
      header: buildHeader( [] ),
      schema: buildSchema( this.props.store, row ),
      value: {},
    }

    return wrapInMutation( {
      mutation: CREATE_LOCATION,
      row,
      viewProps,
      buildSubmit: create( this.props, "Location created!" ).bind( this ),
      handleError: buildErrorHandle( this.props, viewProps.schema ).bind( this ),
      buildActions: buildActions( true, history.goBack ),
    } )
  }
}

export default withApolloClient( withRouter( withStore( CreateLocation ) ) )
