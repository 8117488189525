import React from "react"
import DataTable from "components/DataTable"
import { buildDataForTable } from "components/DataTable/DataBuilder"

export const userAlertsFields = ( {
  alarmNameTransform,
  alarmDescriptionTransform,
} ) => [
  { name: "name", displayName: "Sensor Name", isSearchable: true, type: "string" },
  { name: "alertTypeId", displayName: "Alarm Name", isSearchable: true, type: "string", transform: alarmNameTransform },
]

const renderSensorState = isActive => isActive ? "Active" : "Inactive"
export const sensorsFields = [
  { name: "name", displayName: "Name", isSearchable: true, type: "string" },
  { name: "isActive", displayName: "State", isSearchable: true, type: "string", render: renderSensorState },
  { name: "description", displayName: "Description", isSearchable: true, type: "string" },
]

export const usersFields = [
  { name: "firstName", displayName: "First Name", isSearchable: true, type: "string" },
  { name: "lastName", displayName: "Last Name", isSearchable: true, type: "string" },
  { name: "username", displayName: "Username", isSearchable: true, type: "string" },
  { name: "organizationRole", displayName: "Role", isSearchable: true, type: "string" },
]

export const locationsFields = [
  { name: "name", displayName: "Location Name", isSearchable: true, type: "string" },
  { name: "description", displayName: "Location Description", isSearchable: true, type: "string" },
]

export const structuresFields = [
  { name: "name", displayName: "Structure Name", isSearchable: true, type: "string" },
  { name: "description", displayName: "Structure Description", isSearchable: true, type: "string" },
]

export const buildRenderList = ( data, fields, pagination = 10 ) => () => (
  <DataTable value={buildDataForTable( fields, data, pagination )} />
)
