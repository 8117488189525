import React from "react"
import DynamicFormChild from "../DynamicFormChild"
import ImageDropzone from "../Fields/ImageDropzone"

class ImageField extends DynamicFormChild {
  constructor ( props, instance ) {
    super( props )

    if ( instance === undefined ) {
      return React.createElement( ImageField, props )
    }
  }

  render () {
    const { value, schema } = this.props
    const { name, options, style, className, multiple, readOnly = false } = schema

    return (
      <div className={className} style={style}>
        <ImageDropzone
          name={name}
          readOnly={readOnly}
          value={value || ""}
          multiple={multiple}
          onChange={e => this.props.onChange( e.target.value )}
          options={options}
        />
      </div>
    )
  }
}

export default ImageField
