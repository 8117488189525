import React, { Component } from "react"
import Svg from "components/Svg"
import style from "./style.css"
import sortIcon from "images/symbols/order.svg"

class Sort extends Component {
  render () {
    const { order, onClick } = this.props

    const svgStyle = { display: "flex" }
    const svgFill = style.activeSort

    return (
      <div className={style.container}>
        <div className={ order === "asc" ? svgFill : "" } onClick={ () => onClick( "asc" ) } >
          <Svg className={style.up} style={svgStyle} html={sortIcon} />
        </div>
        <div className={ order === "desc" ? svgFill : "" } onClick={ () => onClick( "desc" ) } >
          <Svg className={style.down} style={svgStyle} html={sortIcon} />
        </div>
      </div>
    )
  }
}

export default Sort
