import React, { Component } from "react"
import _ from "lodash"
import { Query } from "react-apollo"
import Header from "components/Header"
import DataTable from "components/DataTable"
import Spinner from "components/Spinner"
import { buildDataForTable } from "components/DataTable/DataBuilder"
import style from "./style.css"
import { findOptionTypeByQuery, withStore } from "../../store"

const EasyView = withStore(class extends Component {
  componentDidMount() {
    const { store, query } = this.props;

    if ( store && query ) {
      const storeKey = findOptionTypeByQuery(query);

      if ( storeKey ) {
        store.setState({
          [storeKey]: _.get(this.props, "value.rows", [])
        })
      }
    }
  }

  render () {
    const { value, header, editRoute, parentRow = {} } = this.props

    return (
      <div className={style.main}>
        <div className={style.headerContainer}>
          <Header { ...header } />
        </div>
        <div className={style.tableContainer}>
          <DataTable value={value} editRoute={editRoute} parentRow={parentRow} />
        </div>
      </div>
    )
  }
})

export const wrapInQuery = ( { query, fetchPolicy, nodesKey, fields, viewProps, variables = {} } ) => (
  <Query query={query} variables={variables} fetchPolicy={fetchPolicy}>
    {( { loading, error, data } ) => {
      if ( loading ) return <Spinner loading={loading} />
      if ( error ) return `Error Occurred: ${ error }`

      const all = _.get( data, nodesKey, [] )

      const rows = all ? all.nodes : []
      const listData = buildDataForTable( fields, rows )

      return (
        <EasyView {...viewProps} query={query} value={listData} />
      )
    }}
  </Query>
)

export default EasyView
