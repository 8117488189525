import React, { Component } from "react"
import _ from "lodash"
import { withApolloClient } from "./apollo"
import { CURRENT_USER } from "graphql/mutations"
import {
  GET_TIMEZONES,
  GET_COUNTRIES,
  GET_ALERT_TYPES,
  GET_ORGANIZATIONS,
  GET_SENSORS,
  GET_CHANNELS,
  GET_CARRIERS,
  GET_DEVICE_TYPES,
  GET_LOCATIONS,
  GET_MODEM_TYPES,
  GET_UNITS,
  GET_SENSOR_TYPES,
  GET_STRUCTURES,
} from "graphql/queries"

export const StoreContext = React.createContext()

export const triggerLoadIfNeeded = ( types, fetch, data ) => _.keys( types ).map(
  type => _.isEmpty( types[ type ] ) && fetch( type, data )
)

const { Provider, Consumer } = StoreContext

const optionTypes = {
  structures: {
    autoload: true,
    query: GET_STRUCTURES,
    graphqlKey: "allDevices",
    key: "structures",
  },
  sensorTypes: {
    autoload: true,
    query: GET_SENSOR_TYPES,
    graphqlKey: "allSensorTypes",
    key: "sensorTypes",
  },
  units: {
    autoload: true,
    query: GET_UNITS,
    key: "units",
    transform: function ( queryResult ) {
      const { data } = queryResult
      const unitsByChannelType = _.chain( data )
        .get( "allChannelTypes.nodes", [] )
        .keyBy( "name" )
        .mapValues( ct => _.chain( ct )
          .get( "channelTypeUnitsByChannelTypeId.nodes" )
          .map( unit => unit.unitByUnitId )
          .value()
        )
        .value()

      this.setState( {
        units: unitsByChannelType,
      } )
    },
  },
  organizations: {
    autoload: false,
    query: GET_ORGANIZATIONS,
    graphqlKey: "allOrganizations",
    key: "organizations",
  },
  modemTypes: {
    autoload: true,
    query: GET_MODEM_TYPES,
    graphqlKey: "allModemTypes",
    key: "modemTypes",
  },
  carriers: {
    autoload: true,
    query: GET_CARRIERS,
    graphqlKey: "allCommunicationsCarriers",
    key: "carriers",
  },
  deviceTypes: {
    autoload: true,
    query: GET_DEVICE_TYPES,
    graphqlKey: "allDeviceTypes",
    key: "deviceTypes",
  },
  locations: {
    query: GET_LOCATIONS,
    graphqlKey: "allLocations",
    key: "locations",
  },
  channels: {
    autoload: true,
    query: GET_CHANNELS,
    graphqlKey: "allChannels",
    key: "channels",
  },
  sensors: {
    autoload: true,
    query: GET_SENSORS,
    graphqlKey: "allSensors",
    key: "sensors",
  },
  alertTypes: {
    autoload: true,
    query: GET_ALERT_TYPES,
    graphqlKey: "allAlertTypes",
    key: "alertTypes",
  },
  countries: {
    autoload: true,
    query: GET_COUNTRIES,
    graphqlKey: "allCountries",
    key: "countries",
  },
  timezones: {
    autoload: true,
    query: GET_TIMEZONES,
    graphqlKey: "allTimezones",
    key: "timezones",
  },
}

export const findOptionTypeByQuery = viewQuery => {
  const option = _.find( optionTypes, ({ query }) => query === viewQuery );
  return option ? option.key : null;
}

const withConsumer = ( Component, props ) => (
  <Consumer>
    { ( store ) => (
      <Component store={store} {...props} />
    )}
  </Consumer>
)

export const withStore = ToWrap => {
  const className = `${ ToWrap.name }WithStore`

  const wrappedComponent = class extends Component {
    render = () => {
      return withConsumer( ToWrap, this.props )
    }
  }

  Object.defineProperty( wrappedComponent, "name", { value: className } )

  return wrappedComponent
}

class Store extends Component {
  constructor ( props ) {
    super( props )

    const auth = localStorage.getItem( "token" )

    this.state = {
      auth,
      user: undefined,
      data: {},
      setState: this.setState.bind( this ),
      fetch: ( typeName, data ) => this.loadTypeOptions( optionTypes[ typeName ], data ),
    }
  }

  loadTypeOptions ( type = {}, data ) {
    const { client } = this.props
    const { query, graphqlKey, transform, key, getVariables } = type

    const validOptionType = query && key && ( graphqlKey || transform )
    if ( !validOptionType ) {
      console.error( "Invalid Option Type definition!" )
      return
    }

    const typeOptions = _.get( this.state, key, [] )
    if ( !typeOptions.length > 0 ) {
      const defaultCallback = values => {
        const { data } = values
        const options = _.get( data, `${ graphqlKey }.nodes` )

        if ( options ) {
          this.setState( {
            [ key ]: options,
          } )
        }
      }

      const variables = getVariables ? getVariables( data ) : {}
      const callback = ( transform || defaultCallback ).bind( this )
      client
        .query( { query, variables } )
        .then( callback )
    }
  }

  async fetchCurrentUserData () {
    const { client } = this.props

    const fetchedUserData = await client.mutate( {
      mutation: CURRENT_USER,
      variables: { userInput: { clientMutationId: "" } },
    } )

    this.setState( {
      user: fetchedUserData.data.currentUser.user,
    } )
  }

  async componentDidMount () {
    if ( this.state.auth ) {
      !this.state.user && await this.fetchCurrentUserData() // Loads current user data

      _.keys( optionTypes ).forEach ( key => {
        const optionType = optionTypes[ key ]

        if ( optionType.autoload ) {
          this.loadTypeOptions( optionType )
        }
      } )
    }
  }

  render () {
    const { children } = this.props

    return (
      <Provider value={this.state}>
        { children }
      </Provider>
    )
  }
}

export default withApolloClient( Store )
