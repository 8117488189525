import React from "react"
import _ from "lodash"
import DynamicFormChild from "./DynamicFormChild"
import style from "./style.css"
import Presentator from "./Presentator"

class DynamicForm extends DynamicFormChild {
  constructor ( props, instance ) {
    super( props )

    if ( instance === undefined ) {
      return React.createElement( DynamicForm, props )
    }
  }

  // This can probably be refactored, also not sure if it should be moved to DynamicFormChild
  // Should probably rename it from "fieldsToReset" to something else since it's more flexible than that
  componentDidUpdate ( previousProps ) {
    const { value, fieldsToReset, onChange } = this.props
    if ( !fieldsToReset ) return

    const previousValue = previousProps.value

    let resettedFields = {}
    _.keys( fieldsToReset ).forEach( key => {
      const val = _.get( value, key )
      const previousVal = _.get( previousValue, key )

      if ( val && val !== previousVal ) {
        const changedField = fieldsToReset[ key ]

        _.keys( changedField ).forEach( fieldToReset => {
          const resetVal = changedField[ fieldToReset ]

          resettedFields[ fieldToReset ] = typeof resetVal === "function" ? resetVal( value ) : resetVal
        } )
      }
    } )

    if ( _.keys( resettedFields ).length > 0 ) {
      onChange( { ...value, ...resettedFields } )
    }
  }

  render () {
    const { schema, errors } = this.props

    const MainPresentator = new Presentator( {
      onChange: this.props.onChange,
      value: this.props.value,
      passThrough: true,
      schema,
      errors,
    } )

    return (
      <div className={style.main}>
        <div className={style.form}>
          { MainPresentator }
        </div>
      </div>
    )
  }
}

export default DynamicForm
