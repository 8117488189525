import React, { Component } from "react"
import Papa from "papaparse"
import _ from "lodash"
import moment from "moment"
import { wrapInMutation } from "views/EditView"
import { withApolloClient } from "apollo"
import { withStore } from "store"
import { UPDATE_CALIBRATION_ENTRIES } from "graphql/mutations"
import Presentator from "components/DynamicForm/Presentator"
import FileField from "components/DynamicForm/FileField"
import customStyle from "./style.css"
import style from "shared/style.css"



const buildSchema = ( store, row, onLoad ) => {
  const fieldAccept = ".csv"

  const entries = new FileField( {
    onLoad,
    schema: {
      name: "calibrationTableEntries",
      fieldType: "file",
      container: { className: style.fieldContainer },
      accept: fieldAccept,
    },
  } )

  const mainPresentator = new Presentator( {
    passThrough: false,
    schema: {
      className: style.main,
      direction: "column",
      children: [
        entries,
      ],
    },
  } )

  const schema = {
    name: "user",
    passThrough: true,
    children: [
      mainPresentator,
    ],
  }

  return schema
}

const onLoad = callback => reader => {
  Papa.parse( reader.result, {
    complete: callback,
  } )
}

class CalibrationTable extends Component {
  state = { calibrationDate: null }

  parseCsv ( { data } ) {
    const parsedData = data.map( row => ( {
      channelType: _.upperCase( row[ 0 ] ),
      incrementMicron: parseFloat( row[ 1 ] ) || null,
      ratio: parseFloat( row[ 2 ] ) || null,
    } ) )

    this.update( parsedData )
  }

  update ( value ) {
    const { client, sensorId, addNotification } = this.props

    const updateObject = {
      workingSensorId: sensorId,
      csvEntries: value.filter(
        row => !_.isNull( row.incrementMicron ) && !_.isNull( row.ratio )
      ),
    }


    client.mutate( {
      mutation: UPDATE_CALIBRATION_ENTRIES,
      variables: { updateObject },
    } )
      .then( () => {
        this.setState( { calibrationDate: moment() } )
        addNotification( "info", "Calibration table updated" )
      } )
      .catch( () => addNotification( "error", "An unexpected error occurred" ) )

  }

  render () {
    const { value } = this.props

    const viewProps = {
      schema: buildSchema( this.props.store, value, onLoad( this.parseCsv.bind( this ) ) ),
      value,
    }

    const form = wrapInMutation( {
      mutation: UPDATE_CALIBRATION_ENTRIES,
      viewProps,
      buildSubmit: () => null,
      handleError: () => null,
      buildActions: () => [],
    } )

    const title = (
      <div className={customStyle.calibrationTitle}>
        Sensor Calibration
      </div>
    )

    const stateDate = _.get( this.state, "calibrationDate", null )
    const propDate = _.get( this.props, "calibrationDate", null )

    const calibrationDate = stateDate || propDate

    const calibrationDateLabel = (
      <div className={customStyle.date}>
        Last Update: { calibrationDate ? moment( calibrationDate ).format( "MMMM Do YYYY, h:mm:ss a" ) : "--" }
      </div>
    )

    return (
      <div className={customStyle.calibrationContainer}>
        <div className={customStyle.calibrationHeader}>
          { title }
          { calibrationDateLabel }
        </div>
        <div className={customStyle.calibrationInput}>
          { form }
        </div>
      </div>
    )
  }
}

export default withStore ( withApolloClient( CalibrationTable ) )
