import { Component } from "react"
import { wrapInQuery } from "views/EasyView"
import { GET_ORGANIZATIONS } from "graphql/queries"
import { createNewItem } from "components/Header/MoreContainer"

const buildHeader = ( operations = [] ) => ( {
  title: "Organizations",
  operations,
} )

const fields = [
  { name: "name", displayName: "Organization Name", isSearchable: true, type: "string" },
  { name: "description", displayName: "Organization Description", isSearchable: true, type: "string" },
]

const fetchPolicy = "cache-and-network"
const nodesKey = "allOrganizations"

const editRoute = "/organizations/"

export default class Organizations extends Component {
  render () {
    const headerOperations = [
      //createNewItem( this.props.location.pathname, {} ),
      createNewItem( window.location.pathname, {} )
    ]

    const header = buildHeader( headerOperations )
    const viewProps = {
      header,
      editRoute,
    }

    return wrapInQuery( {
      query: GET_ORGANIZATIONS,
      fetchPolicy,
      nodesKey,
      fields,
      viewProps,
    } )
  }
}
