import React, { Component } from "react"
import { Route, Routes } from "react-router-dom"
import { withStore } from "store"
import { appRoutes } from "routes"
import Sidebar from "components/Sidebar"
import style from "./style.css"
import { filterRoutesByUserAccess } from "../../routes"
import ErrorBoundary from "./error"

class App extends Component {
  renderRoute ( route, index ) {
    const { path, element, exact } = route

    const CComponent = element

    return (
      <Route
        exact={exact}
        key={index}
        path={path}
        element={<CComponent addNotification={this.props.addNotification} />}
      />
    )
  }

  renderRouteSwitch () {
    const userRoutes = filterRoutesByUserAccess( appRoutes, this.props.store.user )

    return (
      <div className={style.viewContainer}>
        <ErrorBoundary>
          <Routes>
            { userRoutes.map( this.renderRoute.bind( this ) ) }
          </Routes>
        </ErrorBoundary>
      </div>
    )
  }

  renderSidebar () {
    const { logout } = this.props
    return (
      <div className={style.sidebarContainer}>
        <Sidebar logout={logout} user={this.props.store.user}/>
      </div>
    )
  }

  render () {
    return (
      <div className={style.app}>
        { this.renderSidebar() }
        { this.renderRouteSwitch() }
      </div>
    )
  }
}

export default withStore( App )
