import React, { Component } from "react"
import defaultPlaceholder from "images/placeholders/device.png"
import _ from "lodash"

export default class Img extends Component {
  render () {
    const { placeholder } = this.props

    const fallback = errorEvent => errorEvent.target.src = placeholder || defaultPlaceholder
    const imgProps = {
      ..._.omit( this.props, [ "placeholder" ] ),
      src: this.props.src || "", // "null" src doesn't seem to trigger an error
    }

    return <img { ...imgProps } onError={fallback} />
  }
}
