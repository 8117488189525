import React, { Component } from "react"

const renderSelectOption = option => (
  <option key={option.value} disabled={option.disabled} value={option.value}>{option.label}</option>
)

export class Select extends Component {
  render () {
    const { options } = this.props

    const optionsWithPlaceholder = [
      { value: "", disabled: true, label: this.props.placeholder || "Click to select" },
      ...options,
    ]

    const style = this.props.disabled
      ? { fontWeight: "normal" }
      : !this.props.value
        ? { color: "#808080" }
        : {}

    return (
      <select { ...this.props} style={ style } >
        { optionsWithPlaceholder.map( renderSelectOption )}
      </select>
    )

  }
}

export class Input extends Component {
  render () {
    return (
      <input {...this.props} />
    )
  }
}
