// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IrlNcLuiGkQ1QFBOu9H3 {\n  padding-right: 2.5px;\n}", "",{"version":3,"sources":["webpack://./src/components/DynamicForm/MultiSelect/MultiValueContainer/style.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB","sourcesContent":[".label {\n  padding-right: 2.5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "IrlNcLuiGkQ1QFBOu9H3"
};
export default ___CSS_LOADER_EXPORT___;
