import React, { Component } from "react"
import { withApolloClient } from "apollo"
import Popup from "components/Popup"
import _ from "lodash"
import style from "./style.css"
import { REQUEST_DEVICE_READING } from "graphql/mutations"
import { GET_LAST_READING_STATE } from "graphql/queries"
import Spacer from "components/Spacer"
import Spinner from "components/Spinner"
import Svg from "components/Svg"
import Failure from "images/getReading/failure.svg"
import Success from "images/getReading/success.svg"

const GET_READING_INTERVAL = 5000

/* Amount of time to wait for a single state transition before giving up */
const SERVER_TIMEOUT = 2 * 60 * 1000

/* Amount of time to wait before closing the popup after a FINISH state
 * In the current implementation this is checked with a GET_READING_INTERVAL
 * granularity */
const FINISH_TIMEOUT = 4 * 1000

const READING_STATUS_TRANSLATION = {
  "QUEUED": "A reading has been queued",
  "CONNECTING": "Connecting to device",
  "CONNECTED": "Connected to device",
  "WAITING": "Waiting for device to send data",
  "SUCCESS": "Device successfuly read!",
  "TIMEOUT": "Connection Timeout.\nThe deviced failed to respond in time.",
  "SERVER_TIMEOUT": "Server Timeout.\nThis is not a device failure.",
  "FAILED": "The reading failed.",
  "SERVER_FAILED": "Failed to contact the server.\nThis is not a device failure.",
}

const WAITING_STATES = [ "QUEUED", "CONNECTING", "CONNECTED", "WAITING" ]
const SUCCESS_STATES = [ "SUCCESS" ]
const FAILURE_STATES = [ "TIMEOUT", "SERVER_TIMEOUT", "FAILED", "SERVER_FAILED" ]
const FINISH_STATES = [ ...SUCCESS_STATES, ...FAILURE_STATES ]

class GetReadingPopup extends Component {
  constructor ( props ) {
    super( props )
    console.assert( _.isNumber( props.deviceId ) )
    this.state = {}
  }

  timerRun = () => {
    const { timerLastTransition, status } = this.state
    const { client } = this.props
    const readingId = _.get( this.state, "reading.id" )

    const serverTimeoutExpired = ( new Date() - timerLastTransition ) > SERVER_TIMEOUT
    const finishTimeoutExpired = ( new Date() - timerLastTransition ) > FINISH_TIMEOUT

    // This handles the case where the server hasn't updated the status for a while
    if ( serverTimeoutExpired ) {
      this.setState( {
        error: "Server Timeout",
        status: "SERVER_TIMEOUT",
        timerLastTransition: new Date(),
      } )
      return
    }

    // This handles the case where we have been in a finish state for a predetermined
    // amount of time and we should close the view
    if ( finishTimeoutExpired && FINISH_STATES.includes( status ) ) {
      this.props.onQuit()
      return
    }

    client.query( {
      query: GET_LAST_READING_STATE,
      variables: {
        readingId,
      },
      fetchPolicy: "no-cache",
    } ).then( ( { data } ) => {
      const newState = _.get( data, "query.readingById.readingStatusesByReadingId.nodes[0].state" )
      this.setState( {
        status: newState,
        timerLastTransition: ( newState !== status ) ? new Date() : timerLastTransition,
      } )
    } ).catch( error => {
      console.error( "Server response failure: ", error )
      this.setState( {
        status: "SERVER_FAILED",
        timerLastTransition: new Date(),
      } )
    } )
  }

  componentDidMount () {
    const { client, deviceId } = this.props

    client.mutate( {
      mutation: REQUEST_DEVICE_READING,
      variables: {
        deviceId,
      },
    } ).then( ( { data } ) => {
      const reading = _.get( data, "requestDeviceReading.reading", undefined )

      this.setState( {
        reading,
        error: !reading ? "Error contacting the server" : undefined,
        timerHandle: setInterval( this.timerRun.bind( this ), GET_READING_INTERVAL ),
      } )
    } ).catch( error => this.setState( {
      error,
    } ) )
  }

  componentWillUnmount () {
    const { timerHandle } = this.state
    if ( timerHandle ) {
      clearInterval( timerHandle )
    }
  }

  renderMessage () {
    const { status, reading } = this.state

    const bigMessage = READING_STATUS_TRANSLATION[ status ]

    if ( !reading || _.isUndefined( status ) ) {
      return "Requesting reading..."
    }

    if ( bigMessage ) {
      return bigMessage
    }

    return "Unkown error. This is not a failure to contact the device"
  }

  // Renders an image depending on the state
  renderImage () {
    const { reading, status } = this.state

    const isWaiting = WAITING_STATES.includes( status )
    const isSuccess = SUCCESS_STATES.includes( status )

    if ( !reading || _.isUndefined( status ) || isWaiting ) {
      return (
        <div className={style.spinnerWrapper}>
          <Spinner loading/>
        </div>
      )
    } else if ( isSuccess ) {
      return <Svg html={Success}/>
    } else {
      return <Svg html={Failure}/>
    }
  }

  renderContent = () => (
    <div
      onClick={ event => event.stopPropagation()}
      className={style.contentRoot}
    >
      <div className={style.bigMessage}>
          Get Reading
      </div>
      <Spacer size={4}/>
      {this.renderImage()}
      <Spacer size={2}/>
      <div className={style.readStatus}>
        {this.renderMessage()}
      </div>
    </div>
  )

  render = () => {
    return (
      <Popup
        shouldRender
        onOutsideClick={this.props.onQuit}
      >
        {this.renderContent()}
      </Popup>
    )
  }
}

export default withApolloClient( GetReadingPopup )
