import React, { Component } from "react"
import _ from "lodash"
import { Checkbox } from "components/ToggleButtons"
import DynamicFormChild from "../DynamicFormChild"
import DataTable from "components/DataTable"
import { buildDataForTable } from "components/DataTable/DataBuilder"

import style from "./style.css"

export class Selector extends Component {
  render () {
    const { disabled } = this.props

    return (
      <div className={style.selector}>
        <Checkbox disabled={disabled} />
      </div>
    )
  }
}

class DataTableListInput extends DynamicFormChild {
  constructor ( props, instance ) {
    super( props )

    if ( instance === undefined ) {
      return React.createElement( DataTableListInput, props )
    }
  }

  state = {
    allSelected: false,
  }

  toggleAll ( value ) {
    const { onChange } = this.props

    return ( field, rows ) => {
      const { name } = field

      const idsToToggle = rows.map( item => item._id )

      const toggledVal = value.map( element => (
        _.includes( idsToToggle, element._id )
          ? { ...element, [ name ]: !this.isCurrentDataSelected( name, rows ) }
          : element
      ) )

      onChange( toggledVal )
    }
  }

  buildSelectorField ( selectorField = {}, value ) {
    const { onChange } = this.props
    const { name, label } = selectorField

    const headerClick = this.toggleAll( value ).bind( this )
    const headerRender = ( _, currentData ) =>
      <Selector disabled={ !this.isCurrentDataSelected( name, currentData )} />

    const selectorHeader = {
      render: headerRender,
      onClick: headerClick,
    }

    const render = isActive => <Selector disabled={!isActive} />
    const selectorClick = ( key, val ) => {
      let newVal = [ ...value ]

      const item = newVal[ key ]
      newVal[ key ] = {
        ...item,
        [ name ]: !val,
      }

      onChange( newVal )
    }

    return {
      name,
      label,
      style: { flexGrow: 0 },
      type: "boolean",
      onClick: selectorClick.bind( this ),
      isSearchable: false,
      header: selectorHeader,
      render: render,
    }
  }

  addVirtualIds ( value = [] ) {
    return value.map( ( item, index ) => ( {
      ...item,
      _id: index,
    } ) )
  }

  isCurrentDataSelected ( selectorFieldName, value ) {
    return value.length === value.filter( item => item[ selectorFieldName ] ).length
  }

  render () {
    const { value } = this.props
    const { fields, selectorField, pageSize = 10 } = this.props.schema

    const val = this.props.addVirtualIds ? this.addVirtualIds( value ) : value
    const selector = this.buildSelectorField( selectorField, val )

    const tableData = buildDataForTable( [ selector, ...fields ], val, pageSize )

    return (
      <div className={style.main}>
        <DataTable value={tableData} />
      </div>
    )
  }
}

export default DataTableListInput
